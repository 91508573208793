import React, { useState } from "react";
import styled from "styled-components";
import AssaysDataForm from "../AssaysDataForm";
import { useFormikContext } from "formik";
import { commonPropsForInputsWithoutValue } from "../helpers";
import { FormComponents, CoverSpace } from "./AddEquipmentStyle";
import { BadgeIconTop } from "./AddEditEquipmentContainer";
import DATA_MODEL_TABLE from "../../../utils/constants/dataModelTable";
import { OwcButton, OwcIcon, OwcInput, OwcTypography } from "@one/react";
import DigitalLabStep from "./DigitalLabStep";

const testStyle = {
  color: "var(--one-color-background-brand-1)",
  position: "absolute",
  right: "30px"
};

const InstallDocumentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  & > .selectBoxLarge {
    margin: 0 20px 20px 0px;
    width: 321px;
    max-width: 321px;
  }
`;
const InstallContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 520px;
  background: var(--one-color-cobas-white);
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.5);
  z-index: 10;
  border-radius: 4px;
`;

const AddCancelButton = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 1rem;
  margin-right: 1rem;
`;

const AnalyzerConfiguration = ({ isUpdateBadge, isEditMode }) => {
  const [showInstalledTestPopUp, setShowInstalledTestPopUp] = useState(false);
  const formik = useFormikContext();
  const classes = {};
  const handleClosePopup = () => {
    if (showInstalledTestPopUp) {
      setShowInstalledTestPopUp(false);
    }
  };

  const handleShowPopup = (value) => {
    if (value === "installedTest") {
      setShowInstalledTestPopUp(!showInstalledTestPopUp);
    }
  };

  const getTestValue = (selected) => {
    if (selected !== null) {
      if (selected?.length === 0) {
        return "";
      } else if (selected?.length > 0) {
        return `${selected[0]?.name}, ${selected[0]?.version}`;
      }
    } else {
      return "";
    }
  };

  return (
    <>
      <CoverSpace>
        <OwcTypography variant="caption">SAP Mapped Fields</OwcTypography>
        <BadgeIconTop></BadgeIconTop>
      </CoverSpace>
      {!isEditMode && (
        <OwcTypography
          variant="subtitle2"
          style={{ padding: "0px 20px 20px 0px" }}
        >
          Configuration and availability
        </OwcTypography>
      )}
      <OwcTypography
        variant="subtitle2"
        style={{ padding: "0px 20px 20px 0px" }}
      >
        Configuration
      </OwcTypography>
      <FormComponents>
        <div className="owcInputBox">
          <OwcInput
            data-testid={`text-field-softwareVersion`}
            style={{ width: 313.5, height: 48 }}
            label="Software version"
            name="softwareVersion"
            onInputChange={formik.handleChange}
            value={formik.values?.softwareVersion}
            onBlur={formik.handleBlur}
            onFocus={handleClosePopup}
            {...commonPropsForInputsWithoutValue(
              classes,
              formik,
              "softwareVersion",
              "softwareVersion",
              "text-field"
            )}
            variant="filled"
            color="primary"
          >
            {formik.values[DATA_MODEL_TABLE.softwareVersion.key] !== "" &&
              formik.touched[DATA_MODEL_TABLE.softwareVersion.key] === true && (
                <OwcIcon
                  name="circle_clear_filled"
                  slot="suffix"
                  type="legacy"
                  onClick={() =>
                    formik.setFieldValue(
                      [DATA_MODEL_TABLE.softwareVersion.key],
                      "",
                      true
                    )
                  }
                />
              )}
          </OwcInput>
        </div>
        <div className="owcInputBox">
          <OwcInput
            data-testid={`text-field-configurationbaseLine`}
            style={{ width: 313.5, height: 48 }}
            label={DATA_MODEL_TABLE.configurationBaseline.value}
            name="configurationBaseline"
            onInputChange={formik.handleChange}
            value={formik.values?.configurationBaseline}
            onBlur={formik.handleBlur}
            onFocus={handleClosePopup}
            {...commonPropsForInputsWithoutValue(
              classes,
              formik,
              "configurationBaseline",
              "configurationBaseline",
              "text-field"
            )}
            variant="filled"
          >
            {formik.values[DATA_MODEL_TABLE.configurationBaseline.key] !== "" &&
              formik.touched[DATA_MODEL_TABLE.configurationBaseline.key] ===
                true && (
                <OwcIcon
                  name="circle_clear_filled"
                  slot="suffix"
                  type="legacy"
                  onClick={() =>
                    formik.setFieldValue(
                      [DATA_MODEL_TABLE.configurationBaseline.key],
                      "",
                      true
                    )
                  }
                />
              )}
          </OwcInput>
        </div>
        <div className="owcInputBox">
          <InstallDocumentContainer className="container">
            <OwcInput
              style={{ width: 313.5, height: 48 }}
              data-testid={`text-field-installed-test`}
              label={DATA_MODEL_TABLE.installedTests.value}
              name="installedTests"
              size="medium"
              autoComplete="off"
              onClick={() => {
                handleShowPopup("installedTest");
              }}
              value={getTestValue(formik.values?.installedTests)}
            >
              <div slot="suffix">
                {formik.values?.installedTests !== null &&
                formik.values?.installedTests?.length > 1 ? (
                  <div style={testStyle}>
                    {" "}
                    +{formik.values?.installedTests?.length - 1}
                  </div>
                ) : (
                  ""
                )}
                <OwcIcon
                  name="circle_add"
                  type="outlined"
                  onClick={() => {
                    handleShowPopup("installedTest");
                  }}
                  data-testid={`show-popup-installedTest`}
                />
              </div>
            </OwcInput>

            <InstallContainer
              style={{ display: showInstalledTestPopUp ? "block" : "none" }}
            >
              {showInstalledTestPopUp ? <AssaysDataForm formik={formik} /> : ""}
              <AddCancelButton>
                <OwcButton
                  variant="primary"
                  onClick={handleClosePopup}
                  data-testid={`close-popup-close-installedTest`}
                >
                  Close
                </OwcButton>
              </AddCancelButton>
            </InstallContainer>
          </InstallDocumentContainer>
        </div>
      </FormComponents>
      <DigitalLabStep />
    </>
  );
};

export default AnalyzerConfiguration;
