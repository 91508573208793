import { get, omit } from "underscore";
import { MY_LAB, equipmentStatus } from "../../constants";
import { convertToCameCase } from "../../features/instruments/equipment-list/EquipmentList";

const checkShouldKeepFetching = (drillData, items, limit, customizedItems) => {
  if (customizedItems) {
    return items.length <= customizedItems;
  }
  return drillData ? drillData : items.length < limit;
};

const createOrFilter = (arrayFilterKeys, arrayFilters) => {
  return arrayFilterKeys
    ?.reduce(
      (acc, key) =>
        arrayFilters[key].length
          ? [
              ...acc,
              arrayFilters[key]?.reduce(
                (acc_i, val) =>
                  typeof val === "string"
                    ? [...acc_i, getGQLFilterObj({ [key]: val })]
                    : acc_i,
                []
              )
            ]
          : acc,
      []
    )
    .filter((arr) => arr.length);
};

export const createGQLFilterObj = (obj) => {
  if (typeof obj !== "object" || obj === null || Array.isArray(obj)) {
    return null;
  }
  const objKeys = Object.keys(obj);
  if (!objKeys.length) {
    return null;
  }
  const arrayFilters = objKeys.reduce(
    (acc, curr) =>
      Array.isArray(obj[curr]) ? { ...acc, [curr]: obj[curr] } : acc,
    {}
  );
  const arrayFilterKeys = Object.keys(arrayFilters);
  if (arrayFilterKeys.length) {
    const OrFilter = createOrFilter(arrayFilterKeys, arrayFilters);
    if (!OrFilter.length) {
      return getGQLFilterObj(omit(obj, arrayFilterKeys));
    }
    const simpleFilter = getGQLFilterObj(omit(obj, arrayFilterKeys));
    if (!simpleFilter) {
      return {
        and: [...OrFilter?.map((arr) => ({ or: arr }))]
      };
    }
    return {
      and: [...OrFilter?.map((arr) => ({ or: arr })), simpleFilter]
    };
  }
  return getGQLFilterObj(obj);
};

export const getGQLFilterObj = (obj) => {
  if (typeof obj !== "object" || obj === null || Array.isArray(obj)) {
    return null;
  }
  const result = Object.keys(obj).reduce((acc, cur) => {
    if (obj?.[cur] === null || obj?.[cur] === undefined) {
      return acc;
    }
    if (
      typeof obj?.[cur] === "string" &&
      (obj?.[cur]?.trim() === "" || obj?.[cur]?.trim() === "all")
    ) {
      return acc;
    }
    return { ...acc, [cur]: { eq: obj[cur] } };
  }, {});
  return Object.keys(result).length === 0 ? null : result;
};

const fetchOne = async ({
  client,
  query,
  fetchPolicy,
  variables,
  nextToken,
  dataPath,
  items,
  drillData,
  limit,
  customizedItems
}) => {
  const result = await client.query({
    query,
    fetchPolicy,
    variables: {
      ...variables,
      nextToken
    }
  });
  const page = get(result, [...dataPath, "items"]) ?? [];
  items = Array.isArray(page) ? [...items, ...page] : items;
  const _nextToken = get(result, [...dataPath, "nextToken"]);
  nextToken = typeof _nextToken === "string" && _nextToken ? _nextToken : null;
  const shouldKeepFetching = checkShouldKeepFetching(
    drillData,
    items,
    limit,
    customizedItems
  );
  return { nextToken, items, shouldKeepFetching };
};

export const getAllData = async ({
  client,
  query,
  fetchPolicy = "no-cache",
  variables = {},
  dataPath,
  drillData = false,
  customizedItems = false // Loop the query for a customized item
}) => {
  const emptyResponse = {
    items: [],
    nextToken: null,
    error: null
  };
  if (!client || !query || !variables) {
    return emptyResponse;
  }
  if (!Array.isArray(dataPath)) {
    return emptyResponse;
  }
  if (dataPath.length === 0) {
    return emptyResponse;
  }
  try {
    let items = [];
    let nextToken = variables?.nextToken ?? null;
    let shouldKeepFetching = true;
    const { limit = 10 } = variables;
    do {
      ({ nextToken, items, shouldKeepFetching } = await fetchOne({
        client,
        query,
        fetchPolicy,
        variables,
        nextToken,
        dataPath,
        items,
        shouldKeepFetching,
        drillData,
        limit,
        customizedItems
      }));
    } while (nextToken !== null && shouldKeepFetching);
    return {
      items,
      nextToken,
      error: null
    };
  } catch (err) {
    return { ...emptyResponse, error: err };
  }
};

export const getData = async ({
  client,
  query,
  fetchPolicy = "no-cache",
  variables = {},
  dataPath
}) => {
  const result = await client.query({
    query: query,
    fetchPolicy,
    variables: {
      ...variables
    }
  });
  const resultObj = get(result, [...dataPath]) ?? null;
  return resultObj;
};

export const getContainerHeightPercentage = (currentEnv) => {
  const containerHeight =
    ((window.innerHeight - (currentEnv ? 110 : 70)) / window.innerHeight) * 100;
  return containerHeight;
};

export const getFilterModel = (filterModel, filterName = "") => {
  let filterQuery = ``;
  for (const key in filterModel) {
    let query = ``;
    if (filterModel[key]?.values) {
      filterModel[key]?.values?.forEach((element) => {
        if (key !== "status") {
          query += ` ${key}:'${element}'`;
        }
      });
    } else if (filterModel[key]?.filterType === "date") {
      query += ` ${key}:['${filterModel[key]?.dateFrom}','${filterModel[key]?.dateTo}']`;
    } else if (filterModel[key]?.filterType === "text") {
      query += ` ${key}:'${filterModel[key]?.filter}'`;
    }
    if (key !== "status") {
      filterQuery += ` (or${query})`;
    }
  }

  if (filterName === MY_LAB) {
    filterQuery += ` (or status:'${equipmentStatus?.active?.value}' status:'${equipmentStatus?.pending?.value}')`;
  }

  if (filterQuery?.length === 0) {
    return null;
  }

  return filterQuery?.split("or")?.length > 2
    ? `(and${filterQuery})`
    : filterQuery?.trim();
};

export const exactSearchString = (values) => {
  return values?.map((value) => {
    return `"${value}"`;
  });
};

export const replaceDateUtil = (value, operator) => {
  let date = `${value?.replace(/ /g, "T")}Z`;
  date = date?.replace("00:00:00", "00:00:01");
  if (operator === "lessThan") {
    date = date?.replace("00:00:01", "00:00:00");
  } else if (operator === "greaterThan") {
    date = date?.replace("00:00:01", "00:00:02");
  }
  return date;
};

export const getNumberSearchQuery = (fields, searchValue, operator) => {
  let searchNumberString = [];
  fields?.forEach((field) => {
    searchNumberString.push(`(${field}:${searchValue})`);
  });

  return searchNumberString?.join(` ${operator} `);
};

export const getFilterModelQuery = ({
  filterModel,
  type,
  filterName = "",
  masterDatas = {}
}) => {
  let filterTextQuerys = [];

  for (const key in filterModel) {
    let query = ``;
    let queries = [];
    // let conditions = [];
    if (type === "set") {
      const removeIndex = filterModel[key]?.values.findIndex((item) => !item);
      let blank = null;
      if (
        masterDatas[convertToCameCase(key)]?.length ===
        filterModel[key]?.values?.length
      ) {
        query = `(${key}:*`;

        if (removeIndex !== -1) {
          query = `${query} OR (*:* OR -${key}:*)`;
        }
        query = `${query})`;
      } else {
        if (removeIndex !== -1) {
          blank = filterModel[key]?.values?.splice(removeIndex, 1);
        }
        if (filterModel[key]?.values?.length !== 0) {
          let updatedArray = [];
          updatedArray = exactSearchString(filterModel[key]?.values)?.map(
            (value) => `${key}:${value}`
          );
          query = `(${updatedArray?.join(" OR ")})`;
        }

        if (blank) {
          query = query
            ? `(${query} OR (*:* OR -${key}:*))`
            : `(*:* OR -${key}:*)`;
        }
      }
    } else if (filterModel[key]?.operator) {
      let testConditions = [];
      let conditions = [];
      if (filterModel[key]?.conditions) {
        conditions = [...filterModel[key]?.conditions];
      } else {
        conditions = [
          filterModel[key]?.condition1,
          filterModel[key]?.condition2
        ];
      }
      testConditions = [...conditions];
      testConditions?.forEach((condition) => {
        if (condition?.type === "equals") {
          queries?.push(`${key}:${condition?.filter}`);
        } else if (condition?.type === "contains") {
          queries?.push(`${key}:*${condition?.filter}*`);
        } else if (condition?.type === "notContains") {
          queries?.push(`-${key}:*${condition?.filter}*`);
        } else if (condition?.type === "notEqual") {
          queries?.push(`-${key}:${condition?.filter}`);
        } else if (
          condition?.type === "lessThan" ||
          condition?.type === "lessThanOrEqual"
        ) {
          queries?.push(`${key}:[* TO ${condition?.filter}}`);
        } else if (
          condition?.type === "greaterThan" ||
          condition?.type === "greaterThanOrEqual"
        ) {
          queries?.push(`${key}:{${condition?.filter} TO *]`);
        } else if (condition?.type === "notBlank") {
          queries?.push(
            type === "text" ? `(*:* OR ${key}:*)` : `${key}:[* TO *]`
          );
        } else {
          queries?.push(
            type === "text" ? `(*:* OR -${key}:*)` : `-${key}:[* TO *]`
          );
        }
      });
      query = `(${queries?.join(` ${filterModel[key]?.operator} `)})`;
    } else {
      if (filterModel[key]?.type === "equals") {
        query =
          type === "date"
            ? `${key}:"${replaceDateUtil(
                filterModel[key]?.dateFrom,
                filterModel[key]?.type
              )}"`
            : `${key}:${filterModel[key]?.filter}`;
      } else if (filterModel[key]?.type === "contains") {
        query = `${key}:*${filterModel[key]?.filter}*`;
      } else if (filterModel[key]?.type === "notContains") {
        query = `-${key}:*${filterModel[key]?.filter}*`;
      } else if (filterModel[key]?.type === "startsWith") {
        query = `${key}:${filterModel[key]?.filter}*`;
      } else if (filterModel[key]?.type === "endsWith") {
        query = `${key}:*${filterModel[key]?.filter}`;
      } else if (filterModel[key]?.type === "notEqual") {
        query =
          type === "date"
            ? `-${key}:"${replaceDateUtil(
                filterModel[key]?.dateFrom,
                filterModel[key]?.type
              )}"`
            : `-${key}:${filterModel[key]?.filter}`;
      } else if (
        filterModel[key]?.type === "lessThan" ||
        filterModel[key]?.type === "lessThanOrEqual"
      ) {
        query =
          type === "date"
            ? `${key}:[* TO ${replaceDateUtil(
                filterModel[key]?.dateFrom,
                filterModel[key]?.type
              )}]`
            : `${key}:[* TO ${filterModel[key]?.filter}}`;
      } else if (
        filterModel[key]?.type === "greaterThan" ||
        filterModel[key]?.type === "greaterThanOrEqual"
      ) {
        query =
          type === "date"
            ? `${key}:[${replaceDateUtil(
                filterModel[key]?.dateFrom,
                filterModel[key]?.type
              )} TO *]`
            : `${key}:{${filterModel[key]?.filter} TO *]`;
      } else if (filterModel[key]?.type === "inRange") {
        query =
          type === "date"
            ? `${key}:[${replaceDateUtil(
                filterModel[key]?.dateFrom,
                filterModel[key]?.type
              )} TO ${replaceDateUtil(
                filterModel[key]?.dateTo,
                filterModel[key]?.type
              )}]`
            : `${key}:[${filterModel[key]?.filter} TO ${filterModel[key]?.filterTo}]`;
      } else if (filterModel[key]?.type === "notBlank") {
        query = type === "text" ? `(*:* OR ${key}:*)` : `${key}:[* TO *]`;
      } else {
        query = type === "text" ? `(*:* OR -${key}:*)` : `-${key}:[* TO *]`;
      }
    }
    filterTextQuerys?.push(query);
  }

  let finalStringSearchQuery =
    filterTextQuerys?.length > 1
      ? filterTextQuerys?.join(" AND ")
      : filterTextQuerys[0] || "";

  if (filterName === MY_LAB) {
    finalStringSearchQuery += ` AND (status:'${equipmentStatus?.active?.value}' OR status:'${equipmentStatus?.pending?.value}')`;
  }

  return finalStringSearchQuery;
};
