import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import BasicDataAdditionalInfoForm from "./BasicDataAdditionalInfoForm";
import BasicDataReqFieldsForm from "./BasicDataReqFieldsForm";
import TabsForModalEditInstrument from "./TabsForModalEditInstrument";
import AssaysDataForm from "./AssaysDataForm";
import DocumentsDataForm from "./DocumentsDataForm";
import { useFormik } from "formik";
import * as yup from "yup";
import { set } from "lodash";
import { DEFAULT_SITE_NAME, DEFAULT_SITE_TIMEZONE } from "../../constants";
import { comparatorInstrument } from "./redux/reducer";
import { OwcButton, OwcModalDialog } from "@one/react";
import { Backdrop, CircularProgress } from "@mui/material";

// const DialogTitle = withStyles(styles)((props) => {
//   const { children, classes, onClose, ...other } = props;
//   return (
//     <MuiDialogTitle disableTypography className={classes.root} {...other}>
//       <ModalTitle>{children}</ModalTitle>
//       {onClose ? (
//         <IconButton
//           aria-label="close"
//           className={classes.closeButton}
//           onClick={onClose}
//         >
//           <CloseIcon />
//         </IconButton>
//       ) : null}
//     </MuiDialogTitle>
//   );
// });

const DialogForm = styled.form`
  max-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const emptyImportInstruments = {
  siteName: DEFAULT_SITE_NAME,
  siteTimezone: DEFAULT_SITE_TIMEZONE,
  belongingToGroup: "",
  room: {
    key: "",
    value: ""
  },
  floor: {
    key: "",
    value: ""
  },
  buildingLocation: {
    key: "",
    value: ""
  },
  instrumentGTIN: "",
  qualificationStatus: "",
  instrumentRUDI: "",
  equipmentModel: "",
  isBookable: false,
  isVisualized: false,
  materialNumber: "",
  responsiblePerson: "",
  softwareVersion: "",
  serialNumber: "",
  configurationBaseline: "",
  dateOfLastMaintanance: null,
  dateOfNextMaintanance: null,
  qualificationDocuments: {
    isSynchronized: false,
    value: []
  },
  installedTests: [],
  responsibleProxy: "",
  equipmentId: null,
  manufacturer: "",
  systemOwner: "",
  comment: "",
  sop: {
    key: "",
    value: ""
  },
  csv: "",
  electronicRecord: "",
  electronicSignatures: "",
  dateOfNextPeriodicReview: "",
  maintenanceIntervalInDays: "",
  maintenancePlan: "",
  gxpRelevant: "",
  location: "",
  equipmentCategory: "",
  cluster: "",
  equipmentAdministrator: "",
  equipmentNickName: "",
  functionalLocation: "",
  costCenter: "",
  inventoryNumber: null
};

export const emptyInstruments = {
  siteName: DEFAULT_SITE_NAME,
  siteTimezone: DEFAULT_SITE_TIMEZONE,
  belongingToGroup: "",
  room: {
    key: "",
    value: ""
  },
  floor: {
    key: "",
    value: ""
  },
  instrumentGTIN: "",
  buildingLocation: {
    key: "",
    value: ""
  },
  qualificationStatus: "",
  instrumentRUDI: "",
  equipmentModel: "",
  isBookable: false,
  isVisualized: false,
  materialNumber: "",
  responsiblePerson: "",
  softwareVersion: "",
  serialNumber: "",
  configurationBaseline: "",
  dateOfLastMaintanance: null,
  dateOfNextMaintanance: null,
  qualificationDocuments: {
    isSynchronized: false,
    value: []
  },
  installedTests: [],
  responsibleProxy: "",
  equipmentId: null,
  manufacturer: "",
  systemOwner: "",
  comment: "",
  sop: {
    key: "",
    value: ""
  },
  csv: "",
  electronicRecord: "",
  electronicSignatures: "",
  dateOfNextPeriodicReview: "",
  maintenancePlan: "",
  gxpRelevant: "",
  location: "",
  equipmentCategory: "",
  cluster: "",
  equipmentAdministrator: "",
  equipmentNickName: "",
  functionalLocation: "",
  costCenter: "",
  inventoryNumber: null
};

const getCustomNullableBool = () =>
  yup
    .bool()
    .nullable()
    .default(false)
    .transform((value) => value ?? false);

const getCustomNullable = (schema, changeTo = "") =>
  schema.nullable().transform((value) => value ?? changeTo);

const validationSchema = yup.object({
  siteName: getCustomNullable(
    yup.string("Select site").required("Site is required")
  ),
  siteTimezone: getCustomNullable(
    yup.string("Select timezone").required("Timezone is required")
  ),
  serialNumber: getCustomNullable(
    yup
      .string("Enter serial number")
      .test(
        "unique",
        "Serial and material numers are not unique",
        function (val, context) {
          const materialNumber = this.parent.materialNumber;
          const { instruments = [], isEdit } = context.options.context;
          if (isEdit) return true;
          return !instruments.some((existingInstrument) =>
            comparatorInstrument(existingInstrument, {
              materialNumber,
              serialNumber: val
            })
          );
        }
      )
      .required("Serial number is required")
  ),
  materialNumber: getCustomNullable(
    yup
      .string("Enter material number")
      .test(
        "unique",
        "Material and serial numers are not unique",
        function (val, context) {
          const serialNumber = this.parent.serialNumber;
          const { instruments = [], isEdit } = context.options.context;
          if (isEdit) return true;
          return !instruments.some((existingInstrument) =>
            comparatorInstrument(existingInstrument, {
              serialNumber,
              materialNumber: val
            })
          );
        }
      )
      .required("Material number is required")
  ),
  instrumentRUDI: getCustomNullable(yup.string("Enter RUDI number")),
  buildingLocation: yup
    .object({
      value: getCustomNullable(yup.string("Enter building location")),
      isSynchronized: getCustomNullableBool()
    })
    .nullable(),
  instrumentGTIN: getCustomNullable(
    yup.string("Enter equipment GTIN").typeError("Enter equipment GTIN")
  ),
  equipmentModel: yup
    .object({
      value: getCustomNullable(
        yup
          .string("Enter equipment type")
          .required("Equipment type is required")
      ),
      isSynchronized: getCustomNullableBool()
    })
    .typeError("Enter equipment type"),
  isBookable: getCustomNullableBool(),
  isVisualized: getCustomNullableBool(),
  room: yup
    .object({
      key: yup.string("Enter room is required"),
      value: yup.string("Enter room is required")
    })
    .nullable(),
  floor: yup
    .object({
      key: yup.string("Enter floor is required"),
      value: yup.string("Enter floor is required")
    })
    .nullable(),
  cluster: getCustomNullable(yup.string("Enter cluster")),
  equipmentAdministrator: getCustomNullable(
    yup.string("Enter equipment administrator")
  ),
  equipmentNickName: getCustomNullable(yup.string("Enter equipment nick name")),
  functionalLocation: getCustomNullable(
    yup.string("Enter functional location")
  ),
  costCenter: getCustomNullable(yup.string("Enter cost center")),
  responsiblePerson: getCustomNullable(
    yup
      .string("Enter responsible person")
      .required("Responsible person is required")
  ),
  softwareVersion: getCustomNullable(yup.string("Enter software version")),

  configurationBaseline: getCustomNullable(
    yup.string("Enter configuration baseline")
  ),
  qualificationStatus: getCustomNullable(
    yup.string("Enter equipment qualification status")
  ),
  belongingToGroup: getCustomNullable(yup.string("Enter belonging to group")),
  equipmentId: getCustomNullable(
    yup.string("Enter equipment ID").required("Equipment ID is required")
  ),
  manufacturer: getCustomNullable(
    yup.string("Enter manufacturer").required("Manufacturer required")
  ),
  responsibleProxy: getCustomNullable(yup.string("Enter responsible proxy")),
  dateOfLastMaintanance: yup.date().typeError("Invalid date").nullable(),
  dateOfNextMaintanance: yup.date().typeError("Invalid date").nullable(),
  installedTests: getCustomNullable(yup.array(), []),
  qualificationDocuments: yup
    .object({
      value: getCustomNullable(yup.array(), []),
      isSynchronized: getCustomNullableBool()
    })
    .nullable()
});

const DialogActionsPart = ({ onCancel, instrument }) => {
  return (
    <div
      slot="actions"
      data-testid="instrument-repositorium-modal-action-buttons"
      style={{ display: "flex", marginLeft: "auto" }}
    >
      <OwcButton
        data-testid="instrument-repositorium-modal-action-button-cancel"
        variant="secondary"
        onClick={onCancel}
        style={{ marginRight: 32 }}
      >
        Cancel
      </OwcButton>
      <OwcButton
        data-testid="instrument-repositorium-modal-action-button-confirm"
        autoFocus
        type="submit"
        variant="primary"
      >
        {instrument?.materialNumber && instrument?.serialNumber
          ? "Confirm changes"
          : "Add equipment"}
      </OwcButton>
    </div>
  );
};

const validateValues =
  ({ instrument, instruments }) =>
  (values) => {
    try {
      validationSchema.validateSync(values, {
        abortEarly: false,
        context: {
          instruments,
          isEdit: instrument?.serialNumber && instrument?.materialNumber
        }
      });
    } catch (error) {
      if (error.name !== "ValidationError") {
        throw error;
      }

      return error.inner.reduce((errors, currentError) => {
        errors = set(errors, currentError.path, currentError.message);
        return errors;
      }, {});
    }

    return {};
  };

const InstrumentsModal = ({
  open,
  cancel,
  save,
  title,
  instrument = emptyInstruments,
  instruments
}) => {
  const formik = useFormik({
    initialValues: { ...emptyInstruments },
    validate: validateValues({ instruments, instrument }),
    onSubmit: (values) => {
      const { dateOfLastMaintanance, dateOfNextMaintanance, ...newValues } =
        validationSchema.cast(values);
      save({
        ...values,
        ...newValues
      });
    }
  });
  const [choosenTab, setChoosenTab] = React.useState("basicData");
  useEffect(() => {
    const instrumentOrEmpty = instrument || emptyInstruments;

    formik.resetForm({
      values: { ...instrumentOrEmpty }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instrument]);
  const onCancel = useCallback(() => {
    setChoosenTab("basicData");
    cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <OwcModalDialog
      visible={open}
      data-testid="modal-for-instrument-editing"
      className="owcmodalZIndex"
      onVisibleChange={(event) => {
        if (!event?.detail) {
          cancel();
        }
      }}
    >
      <Backdrop open={formik.isSubmitting}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogForm
        data-testid="instrument-repositorium-modal-form"
        noValidate
        onSubmit={(e) => {
          formik.handleSubmit(e);
          setChoosenTab("basicData");
        }}
      >
        <div
          slot="header"
          data-testid="instrument-repositorium-modal-title"
          onClose={cancel}
        >
          {title}
        </div>
        <TabsForModalEditInstrument
          choosenTab={choosenTab}
          setChoosenTab={setChoosenTab}
        />
        <div dividers data-testid="instrument-repositorium-modal-content">
          {choosenTab === "basicData" && (
            <>
              <BasicDataReqFieldsForm
                formik={formik}
                isEdit={instrument?.materialNumber && instrument?.serialNumber}
              />
              <BasicDataAdditionalInfoForm formik={formik} />
            </>
          )}
          {choosenTab === "assays" && <AssaysDataForm formik={formik} />}
          {choosenTab === "documents" && <DocumentsDataForm formik={formik} />}
        </div>
        <DialogActionsPart instrument={instrument} onCancel={onCancel} />
      </DialogForm>
    </OwcModalDialog>
  );
};

export default InstrumentsModal;
