import "./EquipmentListStyles.css";

import {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
  useContext
} from "react";
import DLabGrid from "../../../components/shared/DLabGrid/DLabGrid";
import { CloudSearchConfig } from "../../../components/shared/CloudSearchConfig";
import { filter, sortBy } from "lodash";
import {
  getFilterModelQuery,
  getNumberSearchQuery
} from "../../../utils/helpers/fetching";
import {
  listOfFieldsNotSortable,
  listOfCheckBoxFilters,
  entryType,
  listOfFieldsSortable,
  allTableColumn,
  DEFAULT_FILTER,
  filterKeyMapping,
  TOOL_PANELS,
  listOfBooleanRequired,
  listOfFieldsIgnoredForSearch,
  MY_LAB,
  listOfDateRequired,
  listOfNumberFilter,
  dropDownSelectionSource,
  listOfFilterBlankRequired,
  systemStatusOptions,
  DEFAULT_COLUMNS,
  equipmentStatus
} from "../../../constants";
import EquipmentAction from "./EquipmentAction";
import DLabFilterForm, {
  getIndex
} from "../../../components/shared/DLabFilterForm";
import { useSelector } from "react-redux";
import DATA_MODEL_TABLE from "../../../utils/constants/dataModelTable";
import { CommonContextWrapper } from "../../../common-context/context";
import styled from "styled-components";
import { changeDateFormat } from "../../../utils/helpers/text";
import { OwcTypography } from "@one/react";
import { useParams, useHistory } from "react-router-dom";
import Tags from "./Tags";
import { checkNullInArrayOfStr } from "../cluster/clusterListService";
export const convertToSnakeCase = (value) => {
  return value
    .replace(/([A-Z])/g, "_$1")
    .trim()
    ?.toLowerCase();
};

export const convertToCameCase = (str) => {
  if (!str?.includes("_")) {
    return str;
  }
  let arr = str.split("_");

  return arr
    .map((value, index) => {
      return index === 0
        ? value
        : value.charAt(0).toUpperCase() + value.slice(1);
    })
    ?.join("");
};

export const onSaveConfiguration = async ({
  currentState,
  chipName = "",
  type = "update",
  isAgGridObj = false,
  pannel = "",
  isSaveConfigUpdated = false,
  updateRedux = true,
  userID = "",
  onCreateSelectColumn = null,
  onUpdateSelectColumn = null,
  onCreatefilter = null,
  onUpdateFilter = null
}) => {
  let result = null;
  if (pannel === TOOL_PANELS?.filters) {
    let obj = {};
    if (isAgGridObj) {
      for (const key in DEFAULT_FILTER.filterDefinition) {
        if (
          currentState[convertToSnakeCase(filterKeyMapping[key] || key)] ===
          undefined
        ) {
          obj[key] = DEFAULT_FILTER.filterDefinition[key];
        } else if (
          currentState[convertToSnakeCase(filterKeyMapping[key] || key)]
            ?.filterType === "set"
        ) {
          obj[key] =
            currentState[
              convertToSnakeCase(filterKeyMapping[key] || key)
            ]?.values;
        } else {
          obj[key] =
            currentState[convertToSnakeCase(filterKeyMapping[key] || key)];
        }
      }
    } else {
      obj = {
        ...currentState
      };
    }

    const lastSavedFilter = {
      filterDefinition: obj,
      filterName: chipName
    };
    if (type === "create") {
      lastSavedFilter.userID = userID;
      lastSavedFilter.chipName = chipName;
      result = await onCreatefilter(lastSavedFilter);
    } else {
      result = await onUpdateFilter(
        lastSavedFilter,
        isSaveConfigUpdated,
        updateRedux
      );
    }
    return { savedObj: lastSavedFilter, result };
  } else if (pannel === TOOL_PANELS?.columns) {
    const savedColumns = [...currentState];
    const updatedSavedColumns = savedColumns.map((column, index) => {
      const dataModelKey = convertToCameCase(column?.colId);
      const obj = {
        key: dataModelKey,
        val: DATA_MODEL_TABLE[dataModelKey]?.value || "ignore",
        order: index,
        show: DATA_MODEL_TABLE[dataModelKey]?.value?.length
          ? !column?.hide
          : "ignore",
        sortStatus: listOfFieldsSortable.includes(
          DATA_MODEL_TABLE[dataModelKey]?.key
        )
      };
      return obj;
    });
    const showColumns = filter(updatedSavedColumns, { show: true });
    const hideColumns = filter(updatedSavedColumns, { show: false });
    let savecolumnObj = {
      displayName: chipName,
      displayDefinition: {
        showColumns,
        hideColumns
      }
    };
    if (type === "create") {
      savecolumnObj.userID = userID;
      savecolumnObj.chipName = chipName;
      result = await onCreateSelectColumn(savecolumnObj);
    } else {
      result = await onUpdateSelectColumn(
        JSON.stringify(savecolumnObj),
        isSaveConfigUpdated,
        updateRedux
      );
    }
    return { savedObj: savecolumnObj, result };
  }
};

const EquipmentList = ({
  columnData,
  searchText,
  client,
  siteList,
  masterDataFilters,
  editEquipment,
  loadAuditTrailList,
  deleteEquipment,
  actionStatus,
  groups,
  limit,
  currentSideBarPanel,
  setCurrentSideBarPanel,
  addInstrumentClick,
  createCluster,
  floatingFilter,
  onUpdateSelectColumn,
  onCreateSelectColumn,
  onUpdateFilter,
  onCreatefilter,
  onChipDelete,
  showSideBar,
  setShowSideBar,
  sideBarPanels,
  setSideBarPanels,
  initialLoadIndex,
  setInitialLoadIndex,
  onFilterSourceSelection,
  source,
  dataDelRes,
  floatingFilterChanged,
  applyGridFiltersForExport,
  setDataModel,
  linkedInstanceMapping,
  disableResetAll,
  updateResetValue,
  ...props
}) => {
  const gridRef = useRef();
  const {
    userFilters,
    myLabFilter,
    lastFavFilter,
    lastDisplayColumns,
    userDisplays,
    systemDisplays,
    id: userID
  } = useSelector((state) => state?.user);
  const [gridApi, setGridApi] = useState(null);
  const [totalRows, setTotalRows] = useState("0");
  const [rowPerPage, setRowPerPage] = useState(limit);
  const [eqFloatingFilter, setEqfloatingFilter] = useState(floatingFilter);
  const { InventoryId } = useParams();
  const [inventoryId, setInventoryId] = useState(
    InventoryId ? InventoryId : null
  );
  const navigation = useHistory();
  const { saveLastPageSize, onCounterChange } =
    useContext(CommonContextWrapper);
  const agGridOptions = {
    hideAction: !(actionStatus?.showAddCluster && actionStatus?.showAddEquip),
    actions: [
      {
        key: "add_equipment",
        value: "Add equipment",
        onClick: () => {
          addInstrumentClick(true, false, null);
        }
      },
      {
        key: "add_cluster",
        value: "Add cluster",
        onClick: () => {
          createCluster();
        }
      }
    ],
    defaultLabel: "Add",
    prefixLabel: ""
  };
  useEffect(() => {
    onApplyFilter(lastFavFilter?.filterDefinition);
    const displayColumn =
      typeof lastDisplayColumns === "string"
        ? JSON.parse(lastDisplayColumns)
        : lastDisplayColumns;
    onApplyColumns(displayColumn?.displayDefinition?.showColumns);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableResetAll]);
  useEffect(() => {
    if (gridApi) {
      onGridReady(gridApi);
      if (limit !== rowPerPage) {
        saveLastPageSize(rowPerPage, "ir_grid");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchText,
    rowPerPage,
    lastDisplayColumns,
    lastFavFilter,
    dataDelRes,
    inventoryId
  ]);
  useEffect(() => {
    if (gridApi && inventoryId) {
      setInventoryId(null);
      navigation.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, lastFavFilter, inventoryId]);
  useEffect(() => {
    if (
      source?.length > 0 &&
      source[0].includes(dropDownSelectionSource?.nonAgGridFilter)
    ) {
      let panels = gridRef && gridRef?.current?.api?.getSideBar();
      if (panels && panels?.length > 1) {
        panels = panels?.toolPanels?.map((panel) => panel?.id);
        setShowSideBar(() => !panels?.includes(TOOL_PANELS?.filters));
      }
      onApplyFilter(lastFavFilter?.filterDefinition);
    } else if (
      source?.length > 0 &&
      source[0].includes(dropDownSelectionSource?.nonAgGridColumn)
    ) {
      let panels = gridRef && gridRef?.current?.api?.getSideBar();
      if (panels && panels?.length > 1) {
        panels = panels?.toolPanels?.map((panel) => panel?.id);
        setShowSideBar(() => !panels?.includes(TOOL_PANELS?.columns));
      }
      const displayColumn =
        typeof lastDisplayColumns === "string"
          ? JSON.parse(lastDisplayColumns)
          : lastDisplayColumns;
      onApplyColumns(displayColumn?.displayDefinition?.showColumns);
    }
    // eslint-disable-next-line
  }, [source]);

  useEffect(() => {
    if (gridRef && gridRef?.current?.api) {
      const panels = gridRef && gridRef?.current?.api?.getSideBar();
      setSideBarPanels(
        panels
          ? panels?.toolPanels?.map((panel) => panel?.id)
          : [TOOL_PANELS?.filters, TOOL_PANELS?.saveConfiguration]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSideBarPanel]);

  useEffect(() => {
    if (updateResetValue) {
      onApplyColumns(DEFAULT_COLUMNS?.displayDefinition?.showColumns);
      onApplyFilter(DEFAULT_FILTER?.filterDefinition);
      onUpdateFilter(DEFAULT_FILTER);
      onUpdateSelectColumn(JSON.stringify(DEFAULT_COLUMNS));
    }
    // eslint-disable-next-line
  }, [updateResetValue]);

  const dataSource = {
    async getRows(params) {
      const { startRow, filterModel, sortModel, groupKeys } = params.request;

      let obj = {};
      let textFilterObject = {};
      let numberFilterObject = {};
      let dateFilterObject = {};
      Object.keys(filterModel).forEach((key) => {
        let filterObj = filterModel[key];
        if (
          filterObj?.filterType === "set" &&
          Object.keys(masterDataFilters).includes(convertToCameCase(key))
        ) {
          if (
            checkNullInArrayOfStr(filterObj?.values) ||
            filterObj?.values?.length === 0 ||
            checkNullInArrayOfStr(lastFavFilter?.filterDefinition[key])
          ) {
            if (!checkNullInArrayOfStr(filterObj?.values)) {
              filterObj?.values?.push(null);
            }
          }
          obj[key] = filterObj;
        } else if (filterObj?.filterType === "date") {
          dateFilterObject[key] = filterObj;
        } else if (filterObj?.filterType === "number") {
          numberFilterObject[key] = filterObj;
        } else if (filterObj?.filterType === "text") {
          textFilterObject[key] = filterObj;
        } else {
          obj[key] = filterObj;
        }
      });

      let listFilterObject = { ...obj };
      let colId, sort;
      if (sortModel.length > 0) {
        colId = sortModel[0].colId;
        sort = sortModel[0].sort;
      }
      let filterStr = "";
      let queryOptions = [];
      let filterQuery = null;
      let counter = 0;
      let expandCluster = false;

      if (groupKeys.length > 0) {
        filterStr = `and status:'${
          equipmentStatus?.active?.value
        }' cluster_id:'${groupKeys[params.parentNode.level]}'`;
        expandCluster = true;
      } else {
        if (searchText) {
          filterStr += `*${searchText}*`;
          const displayColumn =
            typeof lastDisplayColumns === "string"
              ? JSON.parse(lastDisplayColumns)
              : lastDisplayColumns;
          displayColumn?.displayDefinition?.showColumns?.forEach(
            (showColumn) => {
              if (!listOfFieldsIgnoredForSearch?.includes(showColumn?.key)) {
                queryOptions.push(convertToSnakeCase(showColumn?.key));
              }
            }
          );
        }
      }

      if (
        lastFavFilter?.filterName === MY_LAB &&
        lastFavFilter?.filterDefinition?.inventoryIds &&
        lastFavFilter?.filterDefinition?.inventoryIds?.length > 0 &&
        !expandCluster
      ) {
        counter = 1;
        listFilterObject = {
          ...listFilterObject,
          _id: { values: lastFavFilter?.filterDefinition?.inventoryIds }
        };
      }

      if (inventoryId && groupKeys.length <= 0) {
        listFilterObject = {
          ...listFilterObject,
          _id: { values: [inventoryId] }
        };
      }

      if (filterStr && Object?.keys(listFilterObject)?.length === 0) {
        filterQuery = `(and (or status:'${equipmentStatus?.active?.value}'))`;
      } else if (
        !filterStr &&
        Object?.keys(listFilterObject)?.length === 0 &&
        Object?.keys(dateFilterObject)?.length === 0 &&
        Object?.keys(textFilterObject)?.length === 0 &&
        Object?.keys(numberFilterObject)?.length === 0
      ) {
        filterQuery = `(and (or status:'${equipmentStatus?.active?.value}') (or entry_type:'${entryType.cluster}' entry_type:'${entryType.standaloneEquipment}') (not entry_type:'${entryType.clusterSubequipment}'))`;
      } else if (!filterStr && Object?.keys(listFilterObject)?.length === 0) {
        filterQuery = `(and (or status:'${equipmentStatus?.active?.value}'))`;
      }

      let searchTxtQuery = (filterStr && `(${filterStr})`) || "";
      let searchNumberFields = [];
      let searchTextFields = [];
      queryOptions?.forEach((field) => {
        if (listOfNumberFilter?.includes(convertToCameCase(field))) {
          searchNumberFields.push(field);
        } else {
          searchTextFields.push(field);
        }
      });

      if (searchNumberFields?.length > 0 && searchText) {
        searchTxtQuery = `(${searchTxtQuery} OR ${getNumberSearchQuery(
          searchNumberFields,
          searchText,
          "OR"
        )})`;
      }

      searchTxtQuery =
        searchTxtQuery &&
        (Object?.keys(listFilterObject)?.length !== 0 ||
          Object?.keys(textFilterObject)?.length > 0 ||
          Object?.keys(numberFilterObject)?.length > 0 ||
          Object?.keys(dateFilterObject)?.length > 0)
          ? searchTxtQuery + ` AND `
          : searchTxtQuery;

      if (Object?.keys(listFilterObject)?.length !== 0) {
        searchTxtQuery =
          searchTxtQuery +
          ` ` +
          getFilterModelQuery({
            filterModel: listFilterObject,
            type: "set",
            fullSearchQuery: searchTxtQuery,
            filterName: lastFavFilter?.filterName,
            masterDatas: masterDataFilters
          });
      }
      if (Object?.keys(textFilterObject)?.length > 0) {
        searchTxtQuery =
          searchTxtQuery +
          ` ` +
          getFilterModelQuery({
            filterModel: textFilterObject,
            type: "text",
            fullSearchQuery: searchTxtQuery
          });
      }
      if (Object?.keys(numberFilterObject)?.length > 0) {
        searchTxtQuery =
          searchTxtQuery +
          ` ` +
          getFilterModelQuery({
            filterModel: numberFilterObject,
            type: "number",
            fullSearchQuery: searchTxtQuery
          });
      }
      if (Object?.keys(dateFilterObject)?.length > 0) {
        searchTxtQuery =
          searchTxtQuery +
          ` ` +
          getFilterModelQuery({
            filterModel: dateFilterObject,
            type: "date",
            fullSearchQuery: searchTxtQuery
          });
      }

      let optionList = {
        grid_id: "equipmentList",
        options: {
          negatefield: "-cluster_id:*",
          start: startRow,
          size: rowPerPage,
          sort: sort || "asc",
          sortBy: expandCluster
            ? "position_in_cluster"
            : colId || "serial_number",
          searchValue: searchTxtQuery,
          filterQuery,
          queryOptions:
            filterStr &&
            searchTextFields.length > 0 &&
            `{ "fields": ['${searchTextFields.join("', '")}'] }`
        }
      };

      applyGridFiltersForExport(optionList.options);

      let result = await CloudSearchConfig(optionList.options);
      params.success({
        rowData: result?.data,
        rowCount: result?.count
      });

      let nodes = params?.api?.getRenderedNodes();
      if (nodes && nodes.length === 0 && groupKeys.length === 0) {
        gridRef.current.api.showNoRowsOverlay();
      } else if (gridRef && gridRef?.current?.api) {
        gridRef.current.api.hideOverlay();
      }
      if (!expandCluster) {
        setTotalRows(result?.count);
        onCounterChange(Object.keys(filterModel)?.length + counter);
      }
    }
  };

  const linkedInstanceRender = (params) => {
    return <>{params?.data?.linked_instance[0]}</>;
  };

  const autoGroupColumnDef = useMemo(() => {
    return {
      headerName: "",
      field: "id",
      minWidth: 80,
      cellStyle: (params) => {
        return params?.node?.level > 0 &&
          (params?.data?.entry_type[0] === entryType?.cluster ||
            params?.data?.entry_type[0] === entryType?.clusterSubequipment)
          ? {
              paddingLeft: 20,
              border: "none",
              backgroundColor: "#F5F5F2",
              backgroundImage: "conic-gradient(#FFFFFF 180deg, #F5F5F2 180deg)"
            }
          : {};
      },
      cellRendererParams: {
        innerRenderer: (params) => {
          return "";
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onGridReady = (params) => {
    setGridApi(params);
    params.api.setServerSideDatasource(dataSource);
    if (initialLoadIndex === 0) {
      const displayColumn =
        typeof lastDisplayColumns === "string"
          ? JSON.parse(lastDisplayColumns)
          : lastDisplayColumns;
      onApplyColumns(displayColumn?.displayDefinition?.showColumns);
      onApplyFilter(lastFavFilter?.filterDefinition);
      setInitialLoadIndex((prevState) => prevState + 1);
      setEqfloatingFilter(false);
      floatingFilterChanged(false);
    }
  };
  /* const onFirstDataRendered = (params) => {
    setEqfloatingFilter(false);
    floatingFilterChanged(false);
  }; */
  const getContextMenuItems = useCallback((params) => {
    var result = ["copy", "copyWithHeaders", "copyWithGroupHeaders"];
    return result;
  }, []);

  const onRowGroupOpened = useCallback((event) => {
    if (event?.node?.expanded) {
      gridRef.current.api.refreshServerSide({
        route: event?.node?.key,
        purge: false
      });
    }
  }, []);

  const focusOut = useCallback((params) => {
    return params.api.deselectAll();
  }, []);

  const actionRender = (params) => {
    return (
      <>
        <EquipmentAction
          editEquipment={editEquipment}
          loadAuditTrailList={loadAuditTrailList}
          deleteEquipment={deleteEquipment}
          actionStatus={actionStatus}
          groups={groups}
          params={params}
        />
      </>
    );
  };

  const BooleanTag = styled.div`
    padding-right: 25px;
    padding-left: 25px;
  `;

  const DateTag = styled.div`
    padding-right: 25px;
    padding-left: 25px;
  `;

  const booleanRender = (params) => {
    if (params?.column?.colId === "is_bookable" && params?.data?.is_bookable) {
      return <BooleanTag>{params?.data?.is_bookable[0]}</BooleanTag>;
    }
    if (
      params?.column?.colId === "is_visualized" &&
      params?.data?.is_visualized
    ) {
      return <BooleanTag>{params?.data?.is_visualized[0]}</BooleanTag>;
    }

    return <BooleanTag>{"false"}</BooleanTag>;
  };

  const dateRender = (params) => {
    if (
      params?.column?.colId ===
        convertToSnakeCase(DATA_MODEL_TABLE?.dateOfNextMaintanance?.key) &&
      params?.data[
        convertToSnakeCase(DATA_MODEL_TABLE?.dateOfNextMaintanance?.key)
      ]
    ) {
      return (
        <DateTag>
          {changeDateFormat(
            params?.data[
              convertToSnakeCase(DATA_MODEL_TABLE?.dateOfNextMaintanance?.key)
            ][0],
            "DD-MMM-YYYY"
          )}
        </DateTag>
      );
    }
    if (
      params?.column?.colId ===
        convertToSnakeCase(DATA_MODEL_TABLE?.dateOfLastMaintanance?.key) &&
      params?.data[
        convertToSnakeCase(DATA_MODEL_TABLE?.dateOfLastMaintanance?.key)
      ]
    ) {
      return (
        <DateTag>
          {changeDateFormat(
            params?.data[
              convertToSnakeCase(DATA_MODEL_TABLE?.dateOfLastMaintanance?.key)
            ][0],
            "DD-MMM-YYYY"
          )}
        </DateTag>
      );
    }
    if (
      params?.column?.colId ===
        convertToSnakeCase(DATA_MODEL_TABLE?.dateOfNextPeriodicReview?.key) &&
      params?.data[
        convertToSnakeCase(DATA_MODEL_TABLE?.dateOfNextPeriodicReview?.key)
      ]
    ) {
      return (
        <DateTag>
          {changeDateFormat(
            params?.data[
              convertToSnakeCase(
                DATA_MODEL_TABLE?.dateOfNextPeriodicReview?.key
              )
            ][0],
            "DD-MMM-YYYY"
          )}
        </DateTag>
      );
    }
    return <DateTag>-</DateTag>;
  };

  const renderChip = (params) => {
    return (
      <Tags chips={params?.data?.tags || []} rowId={params?.data?.id[0]}></Tags>
    );
  };
  const systemStatusRenderer = (params) => {
    const systemStatusValueArray =
      params?.data[convertToSnakeCase(DATA_MODEL_TABLE?.systemStatus?.key)];

    if (systemStatusValueArray !== undefined) {
      return systemStatusOptions.includes(systemStatusValueArray[0])
        ? systemStatusValueArray[0]
        : "-";
    }

    return "-";
  };
  const dLabColumnDef = useMemo(() => {
    let columnFields = [];

    columnData?.forEach((value) => {
      // if (!listOfFieldsIgnoredInTable?.includes(value.key)) {
      let defaultObj = {
        field: convertToSnakeCase(value.key),
        headerName: value.val,
        valueFormatter: (params) =>
          params.value === "null" || !params.value ? "-" : params.value
      };

      if (listOfBooleanRequired?.includes(value.key)) {
        defaultObj = {
          ...defaultObj,
          cellClass: "action-render",
          cellRenderer: booleanRender
        };
      }

      if (value.key === DATA_MODEL_TABLE?.linkedInstance?.key) {
        defaultObj = {
          ...defaultObj,
          cellRenderer: linkedInstanceRender
        };
      }

      if (value.key === DATA_MODEL_TABLE?.tags?.key) {
        defaultObj = {
          ...defaultObj,
          cellRenderer: renderChip
        };
      }
      if (value.key === DATA_MODEL_TABLE?.systemStatus?.key) {
        defaultObj = {
          ...defaultObj,
          cellRenderer: systemStatusRenderer
        };
      }

      if (listOfDateRequired?.includes(value.key)) {
        defaultObj = {
          ...defaultObj,
          cellClass: "action-render",
          cellRenderer: dateRender
        };
      }

      if (listOfDateRequired?.includes(value.key)) {
        defaultObj = {
          ...defaultObj,
          filter: "agDateColumnFilter",
          filterParams: {
            filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
            inRangeFloatingFilterDateFormat: "DD-MMM-YYYY",
            suppressAndOrCondition: true,
            defaultJoinOperator: "OR",
            buttons: ["reset", "apply"]
          }
        };
      } else if (listOfCheckBoxFilters?.includes(value.key)) {
        defaultObj = {
          ...defaultObj,
          filter: "agSetColumnFilter",
          filterParams: {
            buttons: ["reset"],
            defaultToNothingSelected: true,
            values: (params) => {
              const values = masterDataFilters[value.key] || [];
              params.success(values);
            }
          }
        };
      } else if (listOfBooleanRequired?.includes(value.key)) {
        defaultObj = {
          ...defaultObj,
          filter: "agSetColumnFilter",
          filterParams: {
            defaultToNothingSelected: true,
            showTooltips: true,
            buttons: ["reset"],
            values: (params) => {
              const values = ["true", "false"];
              params.success(values);
            }
          }
        };
      } else if (listOfNumberFilter?.includes(value.key)) {
        defaultObj = {
          ...defaultObj,
          filter: "agNumberColumnFilter",
          filterParams: {
            filterOptions: [
              "equals",
              "lessThan",
              "greaterThan",
              "inRange",
              "blank"
            ],
            defaultJoinOperator: "OR",
            buttons: ["reset", "apply"]
          }
        };
      } else {
        defaultObj = {
          ...defaultObj,
          field: convertToSnakeCase(value.key),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: listOfFilterBlankRequired?.includes(value.key)
              ? ["contains", "blank"]
              : ["contains"],
            buttons: ["reset", "apply"],
            defaultToNothingSelected: true,
            defaultJoinOperator: "OR",
            values: (params) => {
              const values = masterDataFilters[value.key] || [];
              params.success(values);
            }
          }
        };
      }

      defaultObj = {
        ...defaultObj,
        sortable: !listOfFieldsNotSortable?.includes(value.key)
      };
      columnFields.push(defaultObj);
    });

    columnFields = [
      ...columnFields,
      {
        field: "actions",
        headerName: "Actions",
        maxWidth: 50,
        filter: false,
        pinned: "right",
        lockVisible: true,
        lockPosition: "right",
        cellClass: "action-render",
        cellRenderer: actionRender
      }
    ];

    return columnFields;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnData, masterDataFilters]);

  const onSave = useCallback(
    async ({
      currentState,
      chipName = "",
      type = "update",
      isAgGridObj = false,
      pannel = "",
      isSaveConfigUpdated = false,
      updateRedux = true
    }) => {
      return await onSaveConfiguration({
        currentState,
        chipName,
        type,
        isAgGridObj,
        pannel,
        isSaveConfigUpdated,
        updateRedux,
        userID,
        onCreateSelectColumn,
        onUpdateSelectColumn,
        onCreatefilter,
        onUpdateFilter
      });
    },
    [
      onUpdateSelectColumn,
      userID,
      onCreateSelectColumn,
      onUpdateFilter,
      onCreatefilter
    ]
  );

  const onApplyColumns = (savedShowcolumns) => {
    if (gridRef && gridRef?.current?.columnApi) {
      let columnState = gridRef.current.columnApi.getColumnState();
      const showColumns = sortBy(savedShowcolumns, ["order"], ["asc"]);
      showColumns.forEach((column, orderIndex) => {
        const index = getIndex(
          columnState,
          "colId",
          convertToSnakeCase(column.key)
        );
        const splicedObjArray = columnState.splice(index, 1);
        const splicedObj = splicedObjArray[0];
        splicedObj.hide = false;
        columnState.splice(orderIndex + 1, 0, splicedObj);
      });
      columnState?.map((column, index) => {
        if (
          index > showColumns?.length &&
          column?.colId !== "status" &&
          column?.colId !== "actions"
        ) {
          column.hide = true;
        }

        return column;
      });
      // apply order
      gridRef.current.columnApi.applyColumnState({
        state: columnState,
        applyOrder: true
      });
    }
  };

  const onApplyFilter = useCallback(
    (savedFilterModel) => {
      if (gridRef && gridRef?.current?.api) {
        let obj = {};
        for (const key in savedFilterModel) {
          if (
            !(key === "dateFrom" || key === "dateTo" || key === "inventoryIds")
          ) {
            if (savedFilterModel[key]) {
              if (Array.isArray(savedFilterModel[key] || key)) {
                obj[convertToSnakeCase(filterKeyMapping[key] || key)] = {
                  values: savedFilterModel[key],
                  filterType: "set"
                };
              } else {
                obj[convertToSnakeCase(filterKeyMapping[key] || key)] =
                  savedFilterModel[key];
              }
            }
          }
        }
        setCurrentSideBarPanel(gridRef.current.api.getOpenedToolPanel());
        gridRef.current.api.setFilterModel(obj);
      }
    },
    [setCurrentSideBarPanel]
  );
  const icons = useMemo(() => {
    return {
      "column-icon": `<div class="column"><svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 18.5H8.5V18L8.5 6V5.5H8L4 5.5H3.5V6L3.5 18V18.5H4H8ZM14 18.5H14.5V18L14.5 6V5.5H14L10 5.5H9.5V6L9.5 18V18.5H10H14ZM20 18.5H20.5V18V6V5.5H20H16H15.5V6L15.5 18V18.5H16H20ZM4 4.5L20 4.5C20.3978 4.5 20.7794 4.65804 21.0607 4.93934C21.342 5.22064 21.5 5.60218 21.5 6V18C21.5 18.3978 21.342 18.7794 21.0607 19.0607C20.7794 19.342 20.3978 19.5 20 19.5H4C3.60218 19.5 3.22064 19.342 2.93934 19.0607C2.65804 18.7794 2.5 18.3978 2.5 18L2.5 6C2.5 5.60218 2.65804 5.22064 2.93934 4.93934C3.22064 4.65804 3.60218 4.5 4 4.5Z"
      stroke="#706B69"
    />
  </svg></div>`,
      "setting-icon": '<owc-icon name="settings" type="legacy" />'
    };
  }, []);
  const sideBarDef = useMemo(() => {
    let pannels = [
      {
        minWidth: 200,
        width: 276,
        id: "save_configuration",
        labelDefault: "Saved configurations",
        labelKey: "loading",
        iconKey: "setting-icon",
        toolPanel: () => {
          let savedTags = [];
          let savedTag = {};
          let panels = [];
          if (showSideBar && currentSideBarPanel === TOOL_PANELS?.filters) {
            const savedfilters =
              myLabFilter?.filterDefinition &&
              myLabFilter?.filterDefinition?.inventoryIds?.length > 0
                ? [myLabFilter, ...userFilters]
                : [...userFilters];
            savedTags = savedfilters.map((userFilter) => {
              userFilter.chipName = userFilter?.filterName;
              return userFilter;
            });
            savedTag = {
              ...lastFavFilter,
              chipName: lastFavFilter?.filterName
            };
          } else if (
            showSideBar &&
            currentSideBarPanel === TOOL_PANELS?.columns
          ) {
            savedTags = [...systemDisplays, ...userDisplays].map(
              (savedColumn) => {
                savedColumn.chipName = savedColumn?.displayName;
                return savedColumn;
              }
            );
            const displayColumn =
              typeof lastDisplayColumns === "string"
                ? JSON.parse(lastDisplayColumns)
                : lastDisplayColumns;
            savedTag = {
              ...displayColumn,
              chipName: displayColumn?.displayName
            };
          }
          if (showSideBar) {
            const sideBars = gridRef && gridRef?.current?.api?.getSideBar();
            panels = sideBars
              ? sideBars?.toolPanels?.map((panel) => panel?.id)
              : [TOOL_PANELS?.filters, TOOL_PANELS?.saveConfiguration];
          }
          return (
            <DLabFilterForm
              savedChips={savedTags}
              savedChip={savedTag}
              currentSideBarPanel={currentSideBarPanel}
              gridRef={gridRef}
              onSave={onSave}
              onChipDelete={onChipDelete}
              onApplyColumns={onApplyColumns}
              onApplyFilter={onApplyFilter}
              defaultShowColumns={filter(allTableColumn(), {
                show: true
              })}
              defaultFilterObj={DEFAULT_FILTER}
              sideBarPanels={[...panels]}
              onFilterSourceSelection={onFilterSourceSelection}
            />
          );
        }
      }
    ];

    const columnsDef = {
      minWidth: 200,
      width: 276,
      id: "columns",
      labelDefault: "Columns",
      labelKey: "columns",
      iconKey: "column-icon",
      toolPanel: "agColumnsToolPanel",
      toolPanelParams: {
        suppressRowGroups: true,
        suppressValues: true,
        suppressPivots: true,
        suppressPivotMode: true,
        suppressColumnSelectAll: true
      }
    };

    const filtersDef = {
      minWidth: 200,
      width: 276,
      id: "filters",
      labelDefault: "Filters",
      labelKey: "filters",
      iconKey: "filter",
      toolPanel: "agFiltersToolPanel"
    };
    if (currentSideBarPanel === TOOL_PANELS?.columns) {
      pannels = [columnsDef, ...pannels];
    } else if (currentSideBarPanel === TOOL_PANELS?.filters) {
      pannels = [filtersDef, ...pannels];
    }
    return {
      toolPanels: pannels,
      defaultToolPanel: gridRef?.current?.api?.isSideBarVisible()
        ? gridRef?.current?.api?.getOpenedToolPanel() || "filters"
        : currentSideBarPanel,
      hiddenByDefault: !showSideBar
      /* Future Use*/
      // defaultToolPanel: currentSideBarPanel,
      // hiddenByDefault: !currentSideBarPanel ? true : !showSideBar
    };
    // eslint-disable-next-line
  }, [
    /* Future Use*/
    // currentSideBarPanel,
    // savedChips,
    // savedChip,
    // onSave,
    // onChipDelete,
    showSideBar
    // sideBarPanels,
    // onApplyFilter,
    // onFilterSourceSelection
  ]);

  const isServerSideGroup = useCallback((dataItem) => {
    // indicate if node is a group

    if (dataItem.entry_type?.includes(entryType?.cluster)) {
      return dataItem.id;
    }

    return "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getServerSideGroupKey = useCallback((dataItem) => {
    // specify which group key to use

    if (dataItem.entry_type?.includes(entryType?.cluster)) {
      return dataItem?.id;
    }

    return "";
  }, []);
  const onColumnMoved = (params) => {
    if (gridRef && gridRef?.current?.columnApi) {
      const currentState = gridRef?.current?.columnApi.getColumnState();
      const tempCurrentState = [...currentState];
      const tempColumnArray = [];
      let counter = 1;
      tempCurrentState.map((item) => {
        const tempKey = convertToCameCase(item.colId);
        const elem = allTableColumn().find((x) => x.key === tempKey);
        if (elem !== undefined) {
          elem.order = counter;
          tempColumnArray.push(elem);
          counter++;
        }
        return item;
      });
      setDataModel(tempColumnArray);
      if (params?.source && params?.source === "toolPanelUi") {
        gridRef.current.columnApi.applyColumnState({
          state: currentState,
          applyOrder: true
        });
        onSave({
          currentState,
          pannel: TOOL_PANELS?.columns,
          updateRedux: true
        });
      }
    }
  };

  const onColumnEverythingChanged = (params) => {
    if (gridRef && gridRef?.current?.columnApi) {
      const currentState = gridRef?.current?.columnApi.getColumnState();
      if (params?.source && params?.source === "toolPanelUi") {
        gridRef.current.columnApi.applyColumnState({
          state: currentState,
          applyOrder: true
        });
        onSave({ currentState, pannel: TOOL_PANELS?.columns });
      }
    }
  };

  const onFilterChanged = (params) => {
    if (Object.keys(params).includes("afterFloatingFilter")) {
      const onfilChanged = async () => {
        await onSave({
          currentState: params?.api?.getFilterModel(),
          chipName: DEFAULT_FILTER?.filterName,
          type: "update",
          isAgGridObj: true,
          pannel: TOOL_PANELS?.filters,
          updateRedux: true
        });
        // setCurrentSideBarPanel(params.api.getOpenedToolPanel());
        // onApplyFilter(obj?.savedObj?.filterDefinition);
      };
      onfilChanged();
    }
  };

  let attr = {
    rowModelType: "serverSide",
    rowQuickSearch: false,
    treeData: true,
    suppressPaginationPanel: true,
    paginationPageSize: rowPerPage,
    columnDefs: dLabColumnDef,
    pagination: true,
    animateRows: true,
    rowSelection: "multiple",
    rowExport: false,
    masterDetail: true,
    isGroupOpenByDefault: false,
    defaultToolPanel: "filters",
    hiddenByDefault: true,
    cacheBlockSize: rowPerPage,
    serverSideInfiniteScroll: false,
    serverSideSortOnServer: true,
    sideBar: sideBarDef,
    onColumnMoved,
    onColumnEverythingChanged,
    onFilterChanged,
    isServerSideGroup,
    getServerSideGroupKey,
    autoGroupColumnDef,
    onGridReady,
    setRowPerPage,
    rowPerPage,
    totalRows,
    entryType
  };
  useEffect(() => {
    setEqfloatingFilter(floatingFilter);
  }, [floatingFilter]);
  const defaultColDef = useMemo(() => {
    let defaultDef = {
      floatingFilter: eqFloatingFilter,
      flex: 1,
      minWidth: 300,
      resizable: true,
      suppressMenu: true,
      cellStyle: (params) => {
        const secondColumn =
          params.columnApi.getAllDisplayedColumns()[1].colDef.headerName;
        const headerName = params.colDef.headerName;
        const isSecondColumn = secondColumn === headerName ? true : false;
        if (isSecondColumn && params?.node?.level) {
          return {
            paddingLeft: `${10 + params?.node?.level * 20}px`
          };
        }
      }
    };
    return {
      ...defaultDef
    };
  }, [eqFloatingFilter]);
  const CustomNoRowsOverlay = (props) => {
    return (
      <div className="myLabNoDataOverLay">
        <OwcTypography variant="subtitle1">
          There are no items available based on your selected filters criteria
        </OwcTypography>
      </div>
    );
  };
  const noRowsOverlayComponent = useMemo(() => {
    return CustomNoRowsOverlay;
  }, []);
  const getRowStyle = (params) => {
    if (
      params?.data?.status &&
      params?.data?.status[0] !== equipmentStatus?.active?.key
    ) {
      return { opacity: 0.5 };
    }
    if (params?.node?.level > 0) {
      if (params?.data?.entry_type?.includes(entryType?.cluster)) {
        return { borderBottom: "1px solid #F5F5F2" };
      }
    }
    if (
      params?.node?.level === 0 &&
      params?.data?.entry_type?.includes(entryType?.cluster)
    ) {
      return { borderBottom: "1px solid #706B69" };
    }
    if (
      params?.node?.level > 0 &&
      params?.data?.entry_type?.includes(entryType?.clusterSubequipment)
    ) {
      return { borderBottom: "1px solid #F5F5F2" };
    }
  };
  return (
    <>
      <DLabGrid
        {...attr}
        {...props}
        overlayNoRowsTemplate={
          '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">No equipment for the search criteria</span>'
        }
        icons={icons}
        getRowStyle={getRowStyle}
        defaultColDef={defaultColDef}
        options={agGridOptions}
        noRowsOverlayComponent={noRowsOverlayComponent}
        gridRef={gridRef}
        enableRangeSelection={true}
        getContextMenuItems={getContextMenuItems}
        focusout={focusOut}
        onRowGroupOpened={onRowGroupOpened}
      />
    </>
  );
};

export default EquipmentList;
