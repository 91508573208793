import Actions from "./Actions";

export const ExportTableMeta = {
  fields: {
    fileName: {
      text: "File name",
      headProps: {
        style: {
          flex: "0 1 50%"
        }
      },
      cellProps: {
        style: {
          flex: "0 1 50%"
        }
      }
    },
    createdDate: {
      text: "Date and Time",
      sortable: true,
      headProps: {
        style: {
          flex: "0 1 30%"
        }
      },
      cellProps: {
        style: {
          flex: "0 1 30%"
        }
      }
    },
    action: {
      component: Actions,
      headProps: {
        style: {
          flex: "0 1 20%"
        }
      },
      cellProps: {
        style: {
          flex: "0 1 20%"
        }
      }
    }
  }
};
export const data = [
  {
    id: 1,
    fileName: "Roche File",
    dateTime: "29-June-2022, 05:20 PM"
  },
  {
    id: 2,
    fileName: "Roche File 2",
    dateTime: "29-June-2022, 05:20 PM"
  }
];

export default ExportTableMeta;
