import React, { useCallback, useEffect, useMemo } from "react";
import { useRef } from "react";
import { useState } from "react";
import EQUIP_DATA_MODEL_TABLE, {
  changeLinkedInstanceStatusEnum,
  entryType,
  OTHER_REASON,
  DEFAULT_FILTER,
  emptyLinkedInstanceEdit,
  filterKeyMapping,
  linkedInstanceExtraFilterFields,
  MY_LAB,
  subEquipment,
  listOfNumberFilter,
  equipmentStatuses,
  equipmentStatus
} from "../../constants";
import DlabGrid from "../../components/shared/DLabGrid/DLabGrid";
import { CloudSearchConfig } from "../../components/shared/CloudSearchConfig";
import {
  convertToCameCase,
  convertToSnakeCase
} from "../instruments/equipment-list/EquipmentList";
import LinkedInstanceAction from "./LinkedInstanceAction";
import { onlyUnique } from "../../utils/helpers/text";
import useDialog from "../../utils/hooks/useDialog";
import ReasonDropdown from "../instruments/ReasonDropdown";
import GenericSubscription from "../../components/shared/GenericSubscription";
import { ON_UPDATE_CHANGE_LINKED_INSTANCE } from "../../gql/landingapi/subscription";
import { LIST_BULK_LINKED_INSTANCE } from "../../gql/landingapi/queries";
import { compose } from "redux";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { CREATE_BULK_LINKED_INSTANCE } from "../../gql/landingapi/mutations";
import LinkedInstanceFilterBar from "./LinkedInstanceFilterBar";
import { getAllMasterData } from "../instruments/LoadInstrumentsInfo";
import DATA_MODEL_TABLE from "../../utils/constants/dataModelTable";
import {
  getSortedUniqueList,
  getUniqValuesWithCaseInsensitive
} from "../importFile/utils/helpers";
import LinkedInstanceDialog from "./LinkedInstanceDialog";
import { Formik } from "formik";
import {
  GET_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY,
  LIST_IR_EDITREASON_MASTER
} from "../../gql/landingapi/queries";
import { linkedInstanceValidationSchema } from "./ValidationSchema";
import omitDeep from "omit-deep-lodash";
import {
  getAllData,
  getFilterModelQuery,
  getNumberSearchQuery
} from "../../utils/helpers/fetching";
import { useSelector } from "react-redux";
import Notify from "../notifications/Notify";
import ConfirmDialog from "../../components/shared/ConfirmDialog";
import { Box, LinearProgress } from "@mui/material";
import { OwcTypography } from "@one/react";
import { checkNullInArrayOfStr } from "../instruments/cluster/clusterListService";

const LinkedInstanceContainer = ({ client, email, userId }) => {
  const { openDialog, ...dialogProps } = useDialog();
  const [selectedReason, setSelectedReason] = useState("");
  const [addComment, setAddComment] = useState("");
  const [saveReason, setSaveReason] = useState(false);
  const [targetLinkedInstance, setTargetLinkedInstance] = useState("");
  const [startSubscription, setStartSubscription] = useState(false);
  const [selectedEquipment, setSelectedEquipment] = useState([]);
  const [progress, setProgress] = useState(0);
  const [progressStatus, setProgressStatus] = useState(0);
  const [failureCount, setFailureCount] = useState(0);
  const [progressCount, setProgressCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const agGridOptions = {
    actions: [
      {
        key: "change_to_clp",
        value: "to CLP",
        onClick: () => {
          setTargetLinkedInstance("CLP");
          setStartSubscription(true);
          openDialog();
        }
      },
      {
        key: "change_to_molecular",
        value: "to Molecular",
        onClick: () => {
          setTargetLinkedInstance("Molecular");
          setStartSubscription(true);
          openDialog();
        }
      }
    ],
    defaultLabel: "Change linked instance",
    prefixLabel: "Change "
  };

  const [masterDataFilters, setMasterDataFilters] = useState(null);
  const [refreshFlag, setRefreshFlag] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState(null);
  const [reasonList, setReasonList] = useState([]);
  const [equipmentDetail, setEquipmentDetail] = useState(
    emptyLinkedInstanceEdit
  );
  const [floatingFilter, setFloatingFilter] = useState(true);
  const [showAdvancedFilter, setShowAdvancedfilter] = useState(false);
  const [showSideBar, setShowSideBar] = useState(false);
  const [preDefinedFilter, setPredefinedFilter] = useState(DEFAULT_FILTER);
  const linkedInstanceList = useSelector(
    (state) => state.instruments?.linkedInstanceList
  );
  const sites = useSelector((state) => state.user?.sites);
  const gridRef = useRef();
  let masterDatas = [];

  //Ag-Grid function
  const [gridApi, setGridApi] = useState(null);
  const [totalRows, setTotalRows] = useState("0");
  const [counterBadge, setCounterBadge] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(10);

  const loadFilterData = async () => {
    const masterData = await getAllMasterData({ client });
    const masterDataFilter = {};
    masterDataFilter[DATA_MODEL_TABLE?.equipmentCategory?.key] = [
      ...getUniqValuesWithCaseInsensitive(
        masterData?.listIREquipmentCategoryConfigurations?.items
      ),
      ...getUniqValuesWithCaseInsensitive(
        masterData?.listIRClusterCategoryMappings?.items
      )
    ];
    masterDataFilter[DATA_MODEL_TABLE?.belongingToGroup?.key] =
      getUniqValuesWithCaseInsensitive(
        getSortedUniqueList(masterData?.listIRGroupMasters?.items, "value")
      );
    masterDataFilter[DATA_MODEL_TABLE?.manufacturer?.key] =
      getUniqValuesWithCaseInsensitive(
        masterData?.listIRManufacturerMasters?.items
      );
    masterDataFilter[DATA_MODEL_TABLE?.responsiblePerson?.key] =
      getUniqValuesWithCaseInsensitive(
        getSortedUniqueList(
          masterData?.listIRFilterLookups?.items,
          "filterValue"
        )
      );
    masterDataFilter[DATA_MODEL_TABLE?.equipmentAdministrator?.key] =
      getUniqValuesWithCaseInsensitive(
        getSortedUniqueList(
          masterData?.listIREquipmentAdministrators?.items,
          "filterValue"
        )
      );
    masterDataFilter[DATA_MODEL_TABLE?.linkedInstance?.key] =
      getUniqValuesWithCaseInsensitive(
        linkedInstanceList?.map(
          (linkedInstance) => linkedInstance?.linkedInstanceDisplay
        )
      );

    masterDataFilter[DATA_MODEL_TABLE?.siteName?.key] = sites?.map(
      (site) => site?.siteName
    );

    masterDataFilter[DATA_MODEL_TABLE?.status?.key] = equipmentStatuses;

    setMasterDataFilters(() => {
      return { ...masterDataFilter };
    });

    masterDatas = { ...masterDataFilter };
  };

  useEffect(() => {
    loadFilterData();
    getReasonList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (gridApi) {
      onGridReady(gridApi);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowPerPage, searchQuery, refreshFlag, preDefinedFilter]);
  const onFirstDataRendered = useCallback(() => {
    setFloatingFilter((prevState) => !prevState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actionRender = (params) => {
    const inititateEditAction = async (id) => {
      const response = await getEquipmentDetail(id);
      if (response) {
        const result = omitDeep(response, "__typename");
        let tempData = {};
        Object.keys(equipmentDetail).forEach((x) => {
          if (result[x] !== "" && result[x]) {
            tempData[x] =
              x === DATA_MODEL_TABLE.linkedInstance.key
                ? result["linkedInstanceRef"]
                : result[x] && result[x] !== "null" && result[x] !== undefined
                ? result[x]
                : emptyLinkedInstanceEdit[x];
          }
        });
        setEquipmentDetail({ ...tempData });
        setOpenModal(true);
      }
    };
    return (
      <LinkedInstanceAction
        params={params}
        inititateEditAction={inititateEditAction}
      />
    );
  };

  const linkedInstanceRender = (params) => {
    return <>{params?.data?.linked_instance[0]}</>;
  };

  const defaultColDef = useMemo(() => {
    let defaultDef = {
      floatingFilter: floatingFilter,
      flex: 1,
      minWidth: 150,
      suppressMenu: true
    };
    return {
      ...defaultDef
    };
  }, [floatingFilter]);
  const dLabColumnDef = () => {
    let columnFields = [];
    let columnData = EQUIP_DATA_MODEL_TABLE;

    for (const key in columnData) {
      let defaultObj = {
        field: convertToSnakeCase(columnData[key].key),
        headerName: columnData[key].value,
        sortable: true,
        suppressMenu: true,
        valueFormatter: (params) =>
          params.value === "null" || !params.value ? "-" : params.value
      };
      if (key === DATA_MODEL_TABLE?.linkedInstance?.key) {
        defaultObj = {
          ...defaultObj,
          cellRenderer: linkedInstanceRender
        };
      }
      if (
        key !== DATA_MODEL_TABLE?.belongingToGroup?.key &&
        key !== DATA_MODEL_TABLE?.linkedInstance?.key
      ) {
        defaultObj = {
          ...defaultObj,
          filter: "agTextColumnFilter",
          filterParams: {
            defaultJoinOperator: "OR",
            maxNumConditions: 1,
            buttons: ["reset", "apply"],
            filterOptions: ["contains", "blank"]
          }
        };
      }
      if (
        key === DATA_MODEL_TABLE?.belongingToGroup?.key ||
        key === DATA_MODEL_TABLE?.linkedInstance?.key
      ) {
        defaultObj = {
          ...defaultObj,
          filter: "agSetColumnFilter",
          filterParams: {
            buttons: ["reset"],
            defaultToNothingSelected: true,
            values: (params) => {
              const values = masterDataFilters[key] ?? [];
              params.success(values);
            }
          }
        };
      }
      columnFields.push(defaultObj);
    }
    const extraFields = [];
    linkedInstanceExtraFilterFields?.forEach((key) => {
      let extraFieldObj = {
        hide: true,
        lockVisible: true,
        field: convertToSnakeCase(key)
      };
      if (key === DATA_MODEL_TABLE?.dateOfNextMaintanance?.key) {
        extraFieldObj = {
          ...extraFieldObj,
          headerName: DATA_MODEL_TABLE[key]?.value,
          filter: "agDateColumnFilter",
          filterParams: {
            defaultJoinOperator: "OR",
            maxNumConditions: 1,
            inRangeFloatingFilterDateFormat: "DD-MMM-YYYY",
            suppressAndOrCondition: true,
            filterOptions: ["inRange"]
          }
        };
      } else {
        extraFieldObj = {
          ...extraFieldObj,
          headerName: DATA_MODEL_TABLE[key]?.value,
          filter: "agSetColumnFilter",
          filterParams: {
            defaultToNothingSelected: true,
            showTooltips: true,
            values: (params) => {
              const values = masterDataFilters[key];
              params.success(values);
            }
          }
        };
      }
      extraFields?.push(extraFieldObj);
    });

    columnFields = [
      {
        field: "id",
        headerName: "",
        maxWidth: 50,
        filter: false,
        pinned: "left",
        checkboxSelection: true
      },
      ...columnFields,
      {
        field: "action",
        headerName: "Actions",
        maxWidth: 50,
        filter: false,
        pinned: "right",
        lockVisible: true,
        cellClass: "action-render",
        cellRenderer: actionRender
      },
      ...extraFields
    ];

    return columnFields;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const dataSource = {
    async getRows(params) {
      const { startRow, filterModel, sortModel } = params.request;
      let obj = {};
      let textFilterObject = {};
      let numberFilterObject = {};
      let dateFilterObject = {};
      Object.keys(filterModel).forEach((key) => {
        let filterObj = filterModel[key];
        if (
          filterObj?.filterType === "set" &&
          Object.keys(masterDatas).includes(convertToCameCase(key))
        ) {
          if (
            checkNullInArrayOfStr(filterObj?.values) ||
            filterObj?.values?.length === 0
          ) {
            if (!checkNullInArrayOfStr(filterObj?.values)) {
              filterObj?.values?.push(null);
            }
          }
          obj[key] = filterObj;
        } else if (filterObj?.filterType === "date") {
          dateFilterObject[key] = filterObj;
        } else if (filterObj?.filterType === "number") {
          numberFilterObject[key] = filterObj;
        } else if (filterObj?.filterType === "text") {
          textFilterObject[key] = filterObj;
        } else {
          obj[key] = filterObj;
        }
      });

      let listFilterObject = { ...obj };
      let colId, sort;
      if (sortModel.length > 0) {
        colId = sortModel[0].colId;
        sort = sortModel[0].sort;
      }
      let filterStr = "";
      let queryOptions = [];
      let filterQuery = null;
      let counter = 0;
      if (searchQuery) {
        filterStr += `*${searchQuery}*`;
        queryOptions = [
          convertToSnakeCase(DATA_MODEL_TABLE?.belongingToGroup?.key),
          convertToSnakeCase(DATA_MODEL_TABLE?.equipmentId?.key),
          convertToSnakeCase(DATA_MODEL_TABLE?.materialNumber?.key),
          convertToSnakeCase(DATA_MODEL_TABLE?.serialNumber?.key),
          convertToSnakeCase(DATA_MODEL_TABLE?.linkedInstance?.key)
        ];
      }

      if (
        preDefinedFilter?.filterName === MY_LAB &&
        preDefinedFilter?.filterDefinition?.inventoryIds &&
        preDefinedFilter?.filterDefinition?.inventoryIds?.length > 0
      ) {
        counter = 1;
        listFilterObject = {
          ...listFilterObject,
          _id: { values: preDefinedFilter?.filterDefinition?.inventoryIds }
        };
      }

      if (
        Object?.keys(listFilterObject)?.length === 0 &&
        Object?.keys(dateFilterObject)?.length === 0 &&
        Object?.keys(textFilterObject)?.length === 0 &&
        Object?.keys(numberFilterObject)?.length === 0
      ) {
        filterQuery = `(and (or status:'${equipmentStatus?.active?.value}') (or entry_type:'${subEquipment}' entry_type:'${entryType.standaloneEquipment}'))`;
      } else if (Object?.keys(listFilterObject)?.length === 0) {
        filterQuery = `(and (or status:'${equipmentStatus?.active?.value}'))`;
      }

      let searchTxtQuery = (filterStr && `(${filterStr})`) || "";
      let searchNumberFields = [];
      let searchTextFields = [];
      queryOptions?.forEach((field) => {
        if (listOfNumberFilter?.includes(convertToCameCase(field))) {
          searchNumberFields.push(field);
        } else {
          searchTextFields.push(field);
        }
      });

      if (searchNumberFields?.length > 0 && searchQuery) {
        searchTxtQuery = `(${searchTxtQuery} OR ${getNumberSearchQuery(
          searchNumberFields,
          searchQuery,
          "OR"
        )})`;
      }

      searchTxtQuery =
        searchTxtQuery &&
        (Object?.keys(listFilterObject)?.length !== 0 ||
          Object?.keys(textFilterObject)?.length > 0 ||
          Object?.keys(numberFilterObject)?.length > 0 ||
          Object?.keys(dateFilterObject)?.length > 0)
          ? searchTxtQuery + ` AND `
          : searchTxtQuery;

      if (Object?.keys(listFilterObject)?.length !== 0) {
        searchTxtQuery =
          searchTxtQuery +
          ` ` +
          getFilterModelQuery({
            filterModel: listFilterObject,
            type: "set",
            fullSearchQuery: searchTxtQuery,
            filterName: preDefinedFilter?.filterName,
            masterDatas: masterDataFilters
          });
      }
      if (Object?.keys(textFilterObject)?.length > 0) {
        searchTxtQuery =
          searchTxtQuery +
          ` ` +
          getFilterModelQuery({
            filterModel: textFilterObject,
            type: "text",
            fullSearchQuery: searchTxtQuery
          });
      }
      if (Object?.keys(numberFilterObject)?.length > 0) {
        searchTxtQuery =
          searchTxtQuery +
          ` ` +
          getFilterModelQuery({
            filterModel: numberFilterObject,
            type: "number",
            fullSearchQuery: searchTxtQuery
          });
      }
      if (Object?.keys(dateFilterObject)?.length > 0) {
        searchTxtQuery =
          searchTxtQuery +
          ` ` +
          getFilterModelQuery({
            filterModel: dateFilterObject,
            type: "date",
            fullSearchQuery: searchTxtQuery
          });
      }

      if (!filterQuery && searchTxtQuery) {
        searchTxtQuery += ` AND (entry_type:'${subEquipment}' OR entry_type:'${entryType?.standaloneEquipment}')`;
      }

      let optionList = {
        grid_id: "equipmentList",
        options: {
          start: startRow,
          size: rowPerPage,
          sort: sort || "asc",
          sortBy: colId || "serial_number",
          searchValue: searchTxtQuery,
          filterQuery,
          queryOptions:
            filterStr &&
            searchTextFields.length > 0 &&
            `{ "fields": ['${searchTextFields.join("', '")}'] }`
        }
      };

      let result = await CloudSearchConfig(optionList.options);
      params.success({
        rowData: result?.data,
        rowCount: result?.count
      });

      let nodes = params?.api?.getRenderedNodes();
      if (nodes && nodes.length === 0) {
        gridRef.current.api.showNoRowsOverlay();
      } else if (gridRef && gridRef?.current?.api) {
        gridRef.current.api.hideOverlay();
      }
      setTotalRows(result?.count);
      setCounterBadge(Object.keys(filterModel)?.length + counter);

      params?.api?.forEachNode((node) => {
        return node.setSelected(false);
      });
    }
  };
  const onGridReady = (params) => {
    setGridApi(params);
    params?.api?.setServerSideDatasource(dataSource);
  };
  const sideBarDef = useMemo(() => {
    const filtersDef = {
      minWidth: 200,
      width: 276,
      id: "filters",
      labelDefault: "Filters",
      labelKey: "filters",
      iconKey: "filter",
      toolPanel: "agFiltersToolPanel"
    };

    return {
      toolPanels: [filtersDef],
      defaultToolPanel: "filters",
      hiddenByDefault: !showSideBar
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSideBar]);

  const onRowsPerPageChanged = (value) => {
    setRowPerPage(value);
  };

  let attr = {
    height: 480,
    rowModelType: "serverSide",
    rowQuickSearch: true,
    suppressPaginationPanel: true,
    paginationPageSize: rowPerPage,
    columnDefs: dLabColumnDef(),
    pagination: true,
    animateRows: true,
    rowSelection: "multiple",
    rowMultiSelectWithClick: true,
    rowExport: false,
    masterDetail: false,
    suppressContextMenu: true,
    isGroupOpenByDefault: false,
    defaultToolPanel: "filters",
    hiddenByDefault: true,
    cacheBlockSize: rowPerPage,
    serverSideInfiniteScroll: false,
    serverSideSortOnServer: true,
    suppressRowClickSelection: true,
    onGridReady,
    onRowsPerPageChanged,
    rowPerPage,
    totalRows,
    entryType,
    sideBar: sideBarDef
  };

  const onRowSelected = useCallback(
    (params) => {
      const id = params?.data?.id[0];
      if (
        params?.source === "checkboxSelected" ||
        params?.source === "rowClicked"
      ) {
        if (!selectedEquipment.includes(id)) {
          //selected scenario
          setSelectedEquipment((prevState) => {
            return onlyUnique([...prevState, id]);
          });
        } else {
          // un-selected
          let uniqueList = onlyUnique(selectedEquipment);
          const index = uniqueList.indexOf(id);
          if (index > -1) {
            let temp = uniqueList;
            temp.splice(index, 1);
            setSelectedEquipment(() => {
              return temp;
            });
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedEquipment]
  );

  const onUpdateCallback = (subscriptionData) => {
    const result = subscriptionData?.data?.onUpdateIRBulkLinkedInstanceChange;
    if (result) {
      setAddComment("");
      setProgressStatus(result?.status);
      setProgressCount(result?.progressCount);
      setFailureCount(result?.failureCount);
      setTotalCount(result?.totalCount);
      setProgress((result?.progressCount / result?.totalCount) * 100);
    }
  };

  const onSave = async () => {
    try {
      const inputObj = {
        editReason: selectedReason,
        emailId: email,
        inventoryIds: selectedEquipment,
        targetLinkedInstance: targetLinkedInstance,
        editComment: addComment,
        createdById: userId,
        status: changeLinkedInstanceStatusEnum?.SUBMITTED
      };
      const result = await client.mutate({
        mutation: CREATE_BULK_LINKED_INSTANCE,
        fetchPolicy: "no-cache",
        variables: {
          ...inputObj
        }
      });
      if (result?.data?.createIRBulkLinkedInstanceChange) {
        setSaveReason(true);
        setProgressStatus(changeLinkedInstanceStatusEnum?.SUBMITTED);
        setAddComment("");
      }
    } catch (error) {
      Notify({
        type: "warning",
        icon: "caution",
        appName: "",
        text: `Failed to save details!`
      });
      setAddComment("");
    }
  };

  const getEquipmentDetail = async (id) => {
    try {
      const result = await client.query({
        query: GET_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY,
        fetchPolicy: "no-cache",
        variables: {
          id: id
        }
      });
      if (result) {
        return result?.data?.getDigitalLabInstrumentRepositoryEntry;
      }
    } catch (err) {
      console.log("fetching error", err);
    }
  };
  const getReasonList = async () => {
    try {
      const editReason = await getAllData({
        client,
        query: LIST_IR_EDITREASON_MASTER,
        dataPath: ["data", "listIRConfigurableMasters"],
        variables: { limit: 1000 },
        drillData: true
      });
      const tempList = editReason?.items.map((x) => {
        return { key: x.configText, value: x.configText };
      });

      setReasonList([...tempList]);
    } catch (err) {
      console.log("fetching error", err);
    }
  };

  const onShowSideBarCh = () => {
    setShowSideBar((prevState) => {
      return !prevState;
    });
  };

  const onAdvanceFilterChange = () => {
    setShowAdvancedfilter((prevState) => {
      return !prevState;
    });
  };

  const onFloatingFilterChange = () => {
    setFloatingFilter((prevState) => {
      return !prevState;
    });
  };

  const getPredefinedValue = (filterItem) => {
    setPredefinedFilter(() => filterItem);
    onApplyFilter(filterItem?.filterDefinition);
  };

  const onApplyFilter = useCallback((savedFilterModel) => {
    if (gridRef && gridRef?.current?.api) {
      let obj = {};
      for (const key in savedFilterModel) {
        if (
          !(key === "dateFrom" || key === "dateTo" || key === "inventoryIds")
        ) {
          if (savedFilterModel[key]) {
            if (Array.isArray(savedFilterModel[key])) {
              obj[convertToSnakeCase(filterKeyMapping[key])] = {
                values: savedFilterModel[key],
                filterType: "set"
              };
            } else {
              obj[convertToSnakeCase(filterKeyMapping[key])] =
                savedFilterModel[key];
            }
          }
        }
      }
      gridRef.current.api.setFilterModel(obj);
    }
  }, []);

  const onModelUpdated = () => {
    let nodes = gridApi?.api?.getRenderedNodes();
    if (gridApi && gridApi?.api) {
      if (nodes && nodes?.length > 0 && selectedEquipment?.length > 0) {
        gridApi?.api?.forEachNode((node) => {
          return node.setSelected(
            node?.data && selectedEquipment.includes(node?.data.id[0])
          );
        });
      } else if (nodes && nodes?.length > 0) {
        gridApi?.api?.forEachNode((node) => {
          return node?.setSelected(false);
        });
      }
    }
  };

  const onViewportChanged = () => {
    let nodes = gridApi?.api?.getRenderedNodes();
    if (gridApi && gridApi?.api) {
      if (nodes && nodes?.length > 0 && selectedEquipment?.length > 0) {
        gridApi?.api?.forEachNode((node) => {
          return node?.setSelected(
            node?.data && selectedEquipment?.includes(node?.data?.id[0])
          );
        });
      } else if (nodes && nodes?.length > 0) {
        gridApi?.api?.forEachNode((node) => {
          return node?.setSelected(false);
        });
      }
    }
  };

  return (
    <>
      {masterDataFilters && (
        <div style={{ width: "100%" }}>
          <LinkedInstanceFilterBar
            setSearchQuery={setSearchQuery}
            onShowSideBarCh={onShowSideBarCh}
            onAdvanceFilterChange={onAdvanceFilterChange}
            onFloatingFilterChange={onFloatingFilterChange}
            floatingFilter={floatingFilter}
            showAdvancedFilter={showAdvancedFilter}
            showSideBar={showSideBar}
            counter={counterBadge}
            getPredefinedValue={getPredefinedValue}
            preDefinedFilter={preDefinedFilter}
          />
          <DlabGrid
            {...attr}
            gridRef={gridRef}
            defaultColDef={defaultColDef}
            onFirstDataRendered={onFirstDataRendered}
            onRowSelected={onRowSelected}
            options={agGridOptions}
            optionsDisabled={selectedEquipment?.length === 0}
            onViewportChanged={onViewportChanged}
            onModelUpdated={onModelUpdated}
          />
          {startSubscription && (
            <GenericSubscription
              query={LIST_BULK_LINKED_INSTANCE}
              subscription={ON_UPDATE_CHANGE_LINKED_INSTANCE}
              onUpdateCallback={onUpdateCallback}
              variables={{ limit: 1 }}
            />
          )}
          <ConfirmDialog
            {...dialogProps}
            approveText="Save"
            approveColor="primary"
            approveVariant="contained"
            cancelText="Cancel"
            cancelVariant="outlined"
            cancelColor="primary"
            approveDisable={
              selectedReason === ""
                ? true
                : selectedReason && selectedReason?.trim() === OTHER_REASON
                ? addComment === ""
                : false
            }
            onApprove={onSave}
            onCancel={() => {
              setAddComment("");
            }}
            title={
              <OwcTypography variant="subtitle2">
                Reason for change linked instance
              </OwcTypography>
            }
            content={
              <>
                <ReasonDropdown
                  selectedReason={selectedReason}
                  setSelectedReason={setSelectedReason}
                  addComment={addComment}
                  setAddComment={setAddComment}
                />
              </>
            }
          />
          <ConfirmDialog
            {...dialogProps}
            open={saveReason}
            approveText="OK"
            approveColor="primary"
            approveVariant="contained"
            approveDisable={
              progressStatus === changeLinkedInstanceStatusEnum?.INPROGRESS ||
              progressStatus === changeLinkedInstanceStatusEnum?.SUBMITTED
                ? true
                : false
            }
            onApprove={() => {
              setSaveReason(false);
              setRefreshFlag((prevState) => !prevState);
              setAddComment("");
              setProgress(0);
              setProgressStatus(0);
              setFailureCount(0);
              setProgressCount(0);
              setTotalCount(0);
              setSelectedEquipment([]);
            }}
            title={
              <OwcTypography variant="subtitle2">
                Linked instance change
              </OwcTypography>
            }
            content={
              progressStatus === changeLinkedInstanceStatusEnum?.SUBMITTED ? (
                <>
                  <OwcTypography style={{ textAlign: "center" }}>
                    Changed {progressCount} of {totalCount} elements
                  </OwcTypography>
                  <Box sx={{ width: "100%" }} py={[4, 0, 5]}>
                    <LinearProgress variant="determinate" value={progress} />
                  </Box>
                </>
              ) : progressStatus ===
                changeLinkedInstanceStatusEnum?.INPROGRESS ? (
                <>
                  <OwcTypography style={{ textAlign: "center" }}>
                    Changed {progressCount} of {totalCount} elements
                  </OwcTypography>
                  <Box sx={{ width: "100%" }} py={[4, 0, 5]}>
                    <LinearProgress variant="determinate" value={progress} />
                  </Box>
                </>
              ) : (
                progressStatus === changeLinkedInstanceStatusEnum?.COMPLETED &&
                (failureCount > 0 ? (
                  <>
                    <OwcTypography style={{ textAlign: "center" }}>
                      Change completed for{" "}
                      {progressCount - failureCount === 0
                        ? failureCount
                        : progressCount - failureCount}{" "}
                      of {totalCount} elements
                    </OwcTypography>
                    <OwcTypography style={{ textAlign: "center" }}>
                      A file with problematic elements has been uploaded to your
                      inbox
                    </OwcTypography>
                    <Box
                      sx={{
                        width: "100%",
                        color: "var(--one-color-cobas-red-400)"
                      }}
                      py={[4, 0, 5]}
                    >
                      <LinearProgress variant="determinate" color="inherit" />
                    </Box>
                  </>
                ) : (
                  <>
                    <OwcTypography style={{ textAlign: "center" }}>
                      Change completed for {progressCount} of {totalCount}{" "}
                      elements
                    </OwcTypography>
                    <Box sx={{ width: "100%" }} py={[4, 0, 5]}>
                      <LinearProgress variant="determinate" />
                    </Box>
                  </>
                ))
              )
            }
          />
          <Formik
            validationSchema={linkedInstanceValidationSchema}
            initialValues={equipmentDetail}
            isInitialValid={true}
            validateOnMount={false}
          >
            {openModal && (
              <LinkedInstanceDialog
                open={openModal}
                setOpen={setOpenModal}
                equipmentDetail={equipmentDetail}
                reasonList={reasonList}
                setRefreshFlag={setRefreshFlag}
              />
            )}
          </Formik>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (store) => ({
  id: store?.user?.id,
  email: store?.user?.email,
  userId: store?.user?.user
});

export default compose(
  connect(mapStateToProps, {}),
  withApollo
)(LinkedInstanceContainer);
