import { OwcButton } from "@one/react";
import React from "react";
import { ConfirmDialog } from "../../components/shared";
import useDialog from "../../utils/hooks/useDialog";

const CancelButton = ({ cancelStep, isEditMode }) => {
  const { openDialog, ...dialogProps } = useDialog();

  return (
    <>
      <OwcButton
        variant="secondary"
        onClick={() => openDialog()}
        data-testid="add-instrument-previous-step-button"
        style={{ textTransform: "none", marginRight: "20px" }}
      >
        Cancel
      </OwcButton>

      <ConfirmDialog
        {...dialogProps}
        approveText="Yes"
        approveColor="primary"
        approveVariant="contained"
        cancelText="No"
        cancelVariant="outlined"
        cancelColor="primary"
        onApprove={() => cancelStep()}
        title="Cancel equipment"
        content="Do you want to cancel it?"
        disableBackdropClick={true}
      />
    </>
  );
};

export default CancelButton;
