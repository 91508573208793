import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useMemo,
  useCallback
} from "react";
import ReactDOM from "react-dom";
import { withApollo } from "react-apollo";
import { getContainerHeight, getEnv } from "../../utils/helpers/text/index";
import { connect } from "react-redux";
import {
  allTableColumn,
  MORE_DETAILS,
  MODIFY_INSTRUMENT,
  MODIFY_CLUSTER,
  entryType,
  GXPemptyInstrumentsOptions,
  SHOW_INSTRUMENT,
  BULK_EDIT_ACTIVE,
  SEARCH_FILTER_QUERY,
  NORMAL_BULK_EDIT,
  TOOL_PANELS,
  DEFAULT_FILTER,
  OTHER_REASON,
  DEFAULT_COLUMNS,
  systemStatusOptions,
  subEquipment,
  filterKeyMapping,
  listOfFieldsSortable,
  listOfFieldsIgnoredForSearch,
  listOfNumberFilter,
  dimentionUnits,
  agGridAutoGroupfieldName,
  listOfFieldAcceptOnlyInteger,
  LIMIT_EDIT_FILTER,
  GROUP_LITERAL,
  literalFields,
  equipmentStatuses,
  equipmentStatus
} from "../../constants";
import { compose } from "redux";
import {
  loadInstruments as loadInstrumentsAction,
  updateSearchFilter as updateSearchFilterAction
} from "./redux/actions";
import { OwcButton } from "@one/react";
import { updateItem } from "../../utils/store/crud";
import {
  CREATE_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY,
  DEACTIVATE_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY,
  UPDATE_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY,
  ACTIVATE_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY,
  DECOMMISSION_CLUSTER,
  UPDATE_DIGITAL_LAB_INSTRUMENT_USER_PROFILE_PERSONAL_FIELDS,
  CREATE_IR_DISPLAY_COLUMN,
  DELETE_IR_DISPLAY_COLUMN,
  DELETE_DIGITAL_LAB_INSTRUMENT_PAGE_USER_FILTER,
  CREATE_DIGITAL_LAB_INSTRUMENT_PAGE_USER_FILTER,
  UPDATE_IR_DISPLAY_COLUMN,
  UPDATE_DIGITAL_LAB_INSTRUMENT_PAGE_USER_FILTER
} from "../../gql/landingapi/mutations";
import { onlyUnique } from "../../utils/helpers/text";
import { find, filter, sortBy, uniq } from "lodash";
import { keyIds } from "./redux/reducer";
import { getInstrumentWithDefaultValues } from "../importFile/utils/actions";
import { getAllData } from "../../utils/helpers/fetching";
import {
  LIST_INSTRUMENT_REPOSITORY_AUDITS,
  GET_EXPORTING_EQUIPMENT,
  GET_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY,
  GET_BULK_EXPORTING_EQUIPMENT,
  LIST_DIGITAL_LAB_INSTRUMENT_REPOSITORY_USERS
} from "../../gql/landingapi/queries";
import { useSelector } from "react-redux";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import {
  updateClusterDetail as updateClusterDetailAction,
  updateEditClusterDetail as updateEditClusterDetailAction
} from "./cluster/redux/actions";
import {
  getAllMasterData,
  getBuilding,
  getFloor,
  getRoom
} from "./LoadInstrumentsInfo";
import {
  updateLastDisplayColumn as updateLastDisplayColumnAction,
  updateUserDisplayColumns as updateUserDisplayColumnsAction,
  updateLastFavFilter as updateLastFavFilterAction,
  updateUserFilters as updateUserFiltersAction,
  updateLastPageSize as updateLastPageSizeAction
} from "../user/redux/actions";
import InstrumentsModal from "./InstrumentsModal";
import useDialog from "../../utils/hooks/useDialog";
import ConfirmDialog from "../../components/shared/ConfirmDialog";
import {
  OwcIcon,
  OwcList,
  OwcListItem,
  OwcMenu,
  OwcRadio,
  OwcTooltip,
  OwcBadge
} from "@one/react";
import styled from "styled-components";
import Notify from "../notifications/Notify";
import omitDeep from "omit-deep-lodash";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AuditTrialDialog from "./audit-trial/AuditTrialDialog";
import ShowEquipmentInfo from "./ShowEquipmentInfo";
import ReasonDropdown from "./ReasonDropdown";
import { DLabEquipmentList } from "@digitallab/grid-common-components";
import DATA_MODEL_TABLE from "../../utils/constants/dataModelTable";
import {
  getSortedUniqueList,
  getUniqValuesWithCaseInsensitive,
  sortedList
} from "../importFile/utils/helpers";
import { OwcIconButton } from "@one/react";
import { OwcTypography } from "@one/react/dist";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip
} from "@mui/material";
import { TogglerContext } from "../../contexts";
import {
  convertStrToObjColumns,
  convertToCameCase,
  convertToSnakeCase,
  getMegaClusterIds,
  onCreateSelectColumn,
  onCreatefilter,
  onDeleteSaveConfigurations,
  onUpdateFilter,
  onUpdateSelectColumn,
  saveLastPageSize
} from "./cluster/clusterListService";
import { getColumnObj } from "./cluster/steps/addEquipmentService";
import { CloudSearchConfig } from "../../components/shared/CloudSearchConfig";
import EquipmentAction from "./equipment-list/EquipmentAction";
import { CoverMenuItem } from "./addEditEquipment/AddEquipmentStyle";
import { ArrowDropDown } from "@mui/icons-material";
import { useParams, useHistory } from "react-router-dom";

export const DescriptionForm = styled.div`
  & > .button-box-equipment {
    padding: 6px 22px;
    font-size: 16px;
  }
`;
const currentEnv = getEnv();
export const CountHighlight = styled.div`
  font-size: 12px;
  font-style: normal;
  line-height: 23px;
  letter-spacing: 0px;
  background-color: ${(props) =>
    props.theme.notification.success.backgroundIconColor};
  color: ${(props) => props.theme.notification.success.backgroundColor};
  border-radius: 50%;
  width: 22px;
  display: flex;
  justify-content: center;
  margin-left: 10px;
`;

export const EquipAddedStyle = styled.div`
  display: flex;
  align-items: center;
`;

const ActionStyled = styled.div`
  display: flex;
  justify-content: flex-end;
`;
export const ActionsCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const TopBarButtons = styled.div`
  display: flex;
  margin-left: auto;
`;

export const loadFilterData = async ({
  client,
  siteList,
  linkedInstanceList
}) => {
  const masterData = await getAllMasterData({ client });
  const masterDataFilter = {};
  masterDataFilter[DATA_MODEL_TABLE?.equipmentCategory?.key] = [
    ...getUniqValuesWithCaseInsensitive(
      masterData?.listIREquipmentCategoryConfigurations?.items
    ),
    ...getUniqValuesWithCaseInsensitive(
      masterData?.listIRClusterCategoryMappings?.items
    )
  ];
  masterDataFilter[DATA_MODEL_TABLE?.belongingToGroup?.key] =
    getUniqValuesWithCaseInsensitive(
      getSortedUniqueList(masterData?.listIRGroupMasters?.items, "value")
    );
  masterDataFilter[DATA_MODEL_TABLE?.manufacturer?.key] =
    getUniqValuesWithCaseInsensitive(
      masterData?.listIRManufacturerMasters?.items
    );
  masterDataFilter[DATA_MODEL_TABLE?.responsiblePerson?.key] =
    getUniqValuesWithCaseInsensitive(
      getSortedUniqueList(masterData?.listIRFilterLookups?.items, "filterValue")
    );
  masterDataFilter[DATA_MODEL_TABLE?.equipmentAdministrator?.key] =
    getUniqValuesWithCaseInsensitive(
      getSortedUniqueList(
        masterData?.listIREquipmentAdministrators?.items,
        "filterValue"
      )
    );
  masterDataFilter[DATA_MODEL_TABLE?.siteName?.key] = getSortedUniqueList(
    siteList,
    "siteName"
  );
  masterDataFilter[DATA_MODEL_TABLE?.tags?.key] = getSortedUniqueList(
    masterData?.listIRTags?.items,
    "filterValue"
  );
  masterDataFilter[DATA_MODEL_TABLE?.linkedInstance?.key] =
    getUniqValuesWithCaseInsensitive(
      linkedInstanceList?.map(
        (linkedInstance) => linkedInstance?.linkedInstanceDisplay
      )
    );

  masterDataFilter[DATA_MODEL_TABLE?.equipmentModel?.key] =
    getUniqValuesWithCaseInsensitive([
      ...masterData?.listIRequipmentModelConfigurations?.items,
      ...masterData?.listIRclusterModelConfigurations?.items
    ]);

  masterDataFilter[DATA_MODEL_TABLE?.variant?.key] =
    getUniqValuesWithCaseInsensitive(masterData?.listIRVariant?.items);

  masterDataFilter[DATA_MODEL_TABLE?.csv?.key] = sortedList(
    GXPemptyInstrumentsOptions[DATA_MODEL_TABLE?.csv?.key]?.map(
      (obj) => obj?.value
    )
  );
  masterDataFilter[DATA_MODEL_TABLE?.electronicRecord?.key] = sortedList(
    GXPemptyInstrumentsOptions[DATA_MODEL_TABLE?.electronicRecord?.key]?.map(
      (obj) => obj?.value
    )
  );
  masterDataFilter[DATA_MODEL_TABLE?.electronicSignatures?.key] = sortedList(
    GXPemptyInstrumentsOptions[
      DATA_MODEL_TABLE?.electronicSignatures?.key
    ]?.map((obj) => obj?.value)
  );
  masterDataFilter[DATA_MODEL_TABLE?.qualificationStatus?.key] = sortedList(
    GXPemptyInstrumentsOptions[DATA_MODEL_TABLE?.qualificationStatus?.key]?.map(
      (obj) => obj?.value
    )
  );
  masterDataFilter[DATA_MODEL_TABLE?.controlledEquipmentStatus?.key] =
    sortedList(
      GXPemptyInstrumentsOptions[
        DATA_MODEL_TABLE?.controlledEquipmentStatus?.key
      ]?.map((obj) => obj?.value)
    );
  masterDataFilter[DATA_MODEL_TABLE?.gxpRelevant?.key] = sortedList(
    GXPemptyInstrumentsOptions[DATA_MODEL_TABLE?.gxpRelevant?.key]?.map(
      (obj) => obj?.value
    )
  );

  masterDataFilter[DATA_MODEL_TABLE?.sop?.key] = getSortedUniqueList(
    masterData?.listIRSOPMasters?.items,
    "value"
  );

  masterDataFilter[DATA_MODEL_TABLE?.systemStatus?.key] = systemStatusOptions;

  masterDataFilter[DATA_MODEL_TABLE?.status?.key] = equipmentStatuses;

  let linkedInstanceMappingObj = {};
  linkedInstanceList?.forEach((item) => {
    linkedInstanceMappingObj[item?.linkedInstance] =
      item?.linkedInstanceDisplay;
  });

  return { masterDataFilter, linkedInstanceMappingObj };
};

const getEquipment = async (id, client) => {
  try {
    const result = await client.query({
      query: GET_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY,
      fetchPolicy: "no-cache",
      variables: {
        id: id
      }
    });
    if (result) {
      return result?.data?.getDigitalLabInstrumentRepositoryEntry;
    }
  } catch (err) {
    console.log("cluster check error", err);
  }
};

export const Actions = ({
  item,
  setInstrumentToDelete,
  openDialog,
  actionStatus,
  triggerSetCollapse,
  expanderColumns,
  customDeleteHandler,
  options,
  editEquipment,
  loadAuditTrailList,
  groups
}) => {
  const [open, setOpen] = useState(item.isExpanded);
  const [hasPermission, setHasPermission] = useState(
    actionStatus.hasRole === "Admin"
  );

  useEffect(() => {
    if (actionStatus.hasRole === "User") {
      groups.forEach((x) => {
        if (item.belongingToGroup.startsWith(x.groupName)) {
          setHasPermission(true);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   *  This method is used to fetch audit trail logs
   */

  const openSetInstrument = async (item) => {
    editEquipment(item);
  };
  if (expanderColumns.length <= 0) {
    triggerSetCollapse(false);
  }

  <Tooltip
    data-testid="instrument-delete-button-tooltip"
    id="instrument-delete-button-tooltip"
    title={
      actionStatus.showDelete
        ? item?.entryType === entryType?.cluster
          ? "Decommission cluster"
          : "Delete equipment"
        : ""
    }
    placement="left"
    arrow
  >
    <IconButton
      data-testid="instrument-table-delete-button"
      id="instrument-table-delete-button"
      onClick={() => {
        setInstrumentToDelete(item);
        openDialog();
      }}
      disabled={!(actionStatus.showDelete && hasPermission)}
    >
      <OwcIcon name="delete" type="legacy" id="instrument-table-delete-icon" />
    </IconButton>
  </Tooltip>;

  const customDeleteButton = options?.actions?.showDelete && (
    <Tooltip
      data-testid="instrument-custom-delete-button-tooltip"
      id="instrument-custom-delete-button-tooltip"
      title="Delete equipment"
      placement="left"
      arrow
    >
      <IconButton
        data-testid="instrument-custom-table-delete-button"
        id="instrument-custom-table-delete-button"
        onClick={() => customDeleteHandler({ item })}
      >
        <OwcIcon
          name="delete"
          type="legacy"
          id="instrument-table-delete-icon"
        />
      </IconButton>
    </Tooltip>
  );

  const defaultDeleteButton =
    options?.actions?.showDelete &&
    item?.entryType !== entryType?.clusterSubequipment &&
    (item?.status === equipmentStatus?.active?.key ? (
      <Tooltip
        data-testid="instrument-delete-button-tooltip"
        id="instrument-delete-button-tooltip"
        title={
          actionStatus.showDelete
            ? item?.entryType === entryType?.cluster
              ? "Decommission cluster"
              : "Delete equipment"
            : ""
        }
        placement="left"
        arrow
      >
        <IconButton
          data-testid="instrument-table-delete-button"
          id="instrument-table-delete-button"
          onClick={() => {
            setInstrumentToDelete(item);
            openDialog();
          }}
          disabled={!(actionStatus.showDelete && hasPermission)}
        >
          <OwcIcon
            name="delete"
            type="legacy"
            id="instrument-table-delete-icon"
          />
        </IconButton>
      </Tooltip>
    ) : (
      <Tooltip
        data-testid="instrument-restore-button-tooltip"
        id="instrument-restore-button-tooltip"
        title="Restore equipment"
        placement="left"
        arrow
      >
        <IconButton
          data-testid="instrument-table-restore-button"
          id="instrument-table-restore-button"
          onClick={() => {
            setInstrumentToDelete(item);
            openDialog();
          }}
          disabled={
            !(actionStatus.showRestore && hasPermission) ||
            item?.entryType === entryType?.cluster
          }
        >
          <OwcIcon
            name="action_redo"
            type="legacy"
            id="instrument-table-restore-icon"
          />
        </IconButton>
      </Tooltip>
    ));

  const detailedView =
    expanderColumns.length > 0 ? (
      <Tooltip
        data-testid="instrument-edit-button-tooltip"
        title={MORE_DETAILS}
        placement="left"
        arrow
      >
        <IconButton
          aria-label="expand row"
          onClick={() => {
            setOpen(!open);
            triggerSetCollapse(!open);
          }}
        >
          {open ? (
            <KeyboardArrowUpIcon size="small" />
          ) : (
            <KeyboardArrowDownIcon size="small" />
          )}
        </IconButton>
      </Tooltip>
    ) : (
      ""
    );

  const deleteButton = customDeleteHandler
    ? customDeleteButton
    : defaultDeleteButton;

  return (
    <ActionStyled>
      {options?.actions?.showDetailedView && detailedView}

      {options?.actions?.showEdit && (
        <Tooltip
          data-testid="instrument-edit-button-tooltip"
          id="instrument-edit-button-tooltip"
          title={
            actionStatus.showEdit
              ? item?.entryType === entryType?.cluster
                ? "Edit cluster"
                : "Edit equipment"
              : ""
          }
          placement="left"
          arrow
        >
          <IconButton
            data-testid="instrument-table-edit-button"
            id="instrument-table-edit-button"
            onClick={() => openSetInstrument(item)}
            disabled={
              !(
                actionStatus.showEdit &&
                hasPermission &&
                item?.status === equipmentStatus?.active?.key
              )
            }
          >
            <OwcIcon
              name="edit"
              type="legacy"
              id="instrument-table-edit-icon"
            />
          </IconButton>
        </Tooltip>
      )}

      {options?.actions?.showAudit && (
        <Tooltip
          data-testid="instrument-audit-button-tooltip"
          id="instrument-audit-button-tooltip"
          title="Audit trail"
          placement="left"
          arrow
        >
          <IconButton
            data-testid="instrument-table-history-button"
            id="instrument-table-history-button"
            onClick={() => {
              loadAuditTrailList(item);
            }}
          >
            <OwcIcon
              name="history"
              type="legacy"
              id="instrument-table-history-icon"
            />
          </IconButton>
        </Tooltip>
      )}
      {deleteButton}
    </ActionStyled>
  );
};

const saveInstrumentAction =
  ({ instrument, client, loadInstruments, instruments, setInstrument }) =>
  async (instru) => {
    if (instrument?.materialNumber && instrument?.serialNumber) {
      await client.mutate({
        mutation: UPDATE_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY,
        variables: getInstrumentWithDefaultValues({
          instrument: omitDeep(instru, "__typename")
        }),
        fetchPolicy: "no-cache"
      });
      loadInstruments({
        instruments: updateItem(instruments, instru, keyIds)
      });
    } else {
      instru["entryType"] = entryType?.standaloneEquipment;
      await client.mutate({
        mutation: CREATE_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY,
        variables: getInstrumentWithDefaultValues({
          instrument: omitDeep(instru, "__typename")
        }),
        fetchPolicy: "no-cache"
      });
    }
    setInstrument(null);
  };

const deleteInstrumentAction =
  ({ client, setInstrumentToDelete, selectedReason, addComment, trackEvent }) =>
  async (instru, setDataDelRes) => {
    trackEvent({ category: "Delete Equipment", action: "click-event" });
    try {
      const result = await client.mutate({
        mutation:
          instru?.status === equipmentStatus?.active?.key
            ? DEACTIVATE_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY
            : ACTIVATE_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY,
        variables: {
          id: instru.id,
          editReason: selectedReason,
          editComment: addComment
        },
        fetchPolicy: "no-cache"
      });
      if (result) {
        setDataDelRes(() => [`${instru?.status[0]}-${new Date().getTime()}`]);
      }
      setInstrumentToDelete(null);
      Notify({
        type: "success",
        icon: "circle_confirm",
        appName: "",
        text:
          instru?.status === equipmentStatus?.active?.key
            ? `Equipment deleted successfully! `
            : `Equipment restored successfully! `
      });
    } catch (e) {
      Notify({
        type: "warning",
        icon: "caution",
        appName: "",
        text:
          instru?.status === equipmentStatus?.active?.key
            ? `Equipment deleted failed!`
            : `Equipment restored failed!`
      });
    }
  };

/**
 * Deleting cluster equipment .
 * @param {Object} client - The object is to make api call's.
 * @param {Object} setInstrumentToDelete- The object is to set delete current instrument cluster.
 * @param {dispatchAction} cb - The function is to fetch List data.
 * @param {string} selectedReason - The string is for select reason string.
 * @param {string} instrument.id - The string is unique identifire for cluster identification.
 */

const deleteClusterAction =
  ({
    client,
    setInstrumentToDelete,
    dispatchAction,
    selectedReason,
    addComment,
    trackEvent
  }) =>
  async (instrument, setDataDelRes) => {
    trackEvent({ category: "Delete cluster", action: "click-event" });
    try {
      const result = await client.mutate({
        mutation: DECOMMISSION_CLUSTER,
        variables: {
          clusterId: instrument.id,
          editReason: selectedReason,
          editComment: addComment
        },
        fetchPolicy: "no-cache"
      });
      if (result) {
        setDataDelRes(() => [
          `${instrument?.status[0]}-${new Date().getTime()}`
        ]);
      }
      dispatchAction({
        type: "setTableEditable",
        payload: true
      });
      dispatchAction({
        type: "refresh"
      });
      setInstrumentToDelete(null);
      Notify({
        type: "success",
        icon: "circle_confirm",
        appName: "",
        text: "Cluster decommission successfully! "
      });
    } catch (e) {
      dispatchAction({
        type: "setTableEditable",
        payload: false
      });
      Notify({
        type: "warning",
        icon: "caution",
        appName: "",
        text: "Cluster decommission failed!"
      });
    }
  };

const handleClusterFieldValuesForEdit = async (instrument, client) => {
  let tempInstrumentDetail = omitDeep(instrument, "__typename");

  if (tempInstrumentDetail.gxpRelevant) {
    tempInstrumentDetail.gxpRelevant =
      find(GXPemptyInstrumentsOptions?.gxpRelevant, {
        key: tempInstrumentDetail?.gxpRelevant
      }) || null;
  }

  if (tempInstrumentDetail.qualificationStatus) {
    const qualificationStatus =
      find(GXPemptyInstrumentsOptions?.qualificationStatus, {
        key: tempInstrumentDetail?.qualificationStatus
      }) || null;
    tempInstrumentDetail.qualificationStatus = qualificationStatus;
  }

  if (tempInstrumentDetail?.buildingLocation) {
    const building = await getBuilding(
      client,
      tempInstrumentDetail?.buildingLocation?.key
    );
    tempInstrumentDetail.buildingLocation = building?.isActive
      ? building
      : null;
  }

  if (tempInstrumentDetail?.floor) {
    const floor = await getFloor(client, tempInstrumentDetail?.floor?.key);
    tempInstrumentDetail.floor = floor?.isActive ? floor : null;
  }
  if (tempInstrumentDetail?.room) {
    const room = await getRoom(client, tempInstrumentDetail?.room?.key);
    tempInstrumentDetail.room = room?.isActive ? room : null;
  }

  if (tempInstrumentDetail?.responsiblePerson) {
    tempInstrumentDetail.responsiblePerson =
      tempInstrumentDetail?.responsiblePerson === "null"
        ? null
        : tempInstrumentDetail?.responsiblePerson;
  }
  if (tempInstrumentDetail?.equipmentNickName) {
    tempInstrumentDetail.equipmentNickName =
      tempInstrumentDetail?.equipmentNickName === "null"
        ? null
        : tempInstrumentDetail?.equipmentNickName;
  }
  if (tempInstrumentDetail?.equipmentAdministrator) {
    tempInstrumentDetail.equipmentAdministrator =
      tempInstrumentDetail?.equipmentAdministrator === "null"
        ? null
        : tempInstrumentDetail?.equipmentAdministrator;
  }

  if (tempInstrumentDetail?.belongingToGroup) {
    tempInstrumentDetail.belongingToGroup =
      tempInstrumentDetail?.belongingToGroup === "null"
        ? null
        : tempInstrumentDetail?.belongingToGroup;
  }

  if (tempInstrumentDetail?.cluster) {
    tempInstrumentDetail.cluster =
      tempInstrumentDetail?.cluster === "null"
        ? null
        : tempInstrumentDetail?.cluster;
  }

  if (tempInstrumentDetail?.responsibleProxy) {
    tempInstrumentDetail.responsibleProxy =
      tempInstrumentDetail?.responsibleProxy === "null"
        ? null
        : tempInstrumentDetail?.responsibleProxy;
  }
  if (tempInstrumentDetail?.softwareVersion) {
    tempInstrumentDetail.softwareVersion =
      tempInstrumentDetail?.softwareVersion === "null"
        ? null
        : tempInstrumentDetail?.softwareVersion;
  }

  tempInstrumentDetail?.subEquipment?.items.map((x) => {
    if (x?.isClusterSWVersion === true) {
      tempInstrumentDetail.leadingSoftwareVersionId = x.id;
    } else {
      x.isClusterSWVersion = false;
    }
    return x;
  });

  return tempInstrumentDetail;
};

const InstrumentsTable = ({
  instruments,
  loadInstruments,
  client,
  addInstrumentClicked,
  updateSearchFilter,
  setActiveModule,
  updateClusterDetail,
  updateEditClusterDetail,
  setIsEditMode,
  editClusterDetail,
  hasPermission,
  setHasPermission,
  activeModule,
  updateLastDisplayColumn,
  updateUserDisplayColumns,
  updateLastFavFilter,
  updateUserFilters,
  updateLastPageSize,
  selectedEquipmentId,
  setSelectedEquipmentId,
  bulkImportFilters,
  setBulkImportFilters,
  enableCheckbox,
  setEnableCheckbox,
  setQueryChoice,
  searchFilterCount,
  setSearchFilterCount,
  onReviewOrRevert,
  groupList
}) => {
  const [instrument, setInstrument] = useState(null);
  const [instrumentToDelete, setInstrumentToDelete] = useState(null);
  const { openDialog, ...dialogProps } = useDialog();
  const [selectedReason, setSelectedReason] = useState("");
  const [addComment, setAddComment] = useState("");
  const { trackEvent } = useMatomo();
  const [loading, setLoading] = useState(false);
  const [auditLogs, setAuditLogs] = useState();
  const [userLists, setUserLists] = useState([]);
  const [openAuditTrial, setOpenAuditTrial] = useState(false);
  const [bulkExportStatus, setBulkExportStatus] = useState(false);
  const [dataDelRes, setDataDelRes] = useState([]);
  const [dataModel, setDataModel] = useState({});
  const [exportDisable, setExportDisable] = useState(true);
  const [downloadBulkCSVDisable, setDownloadBulkCSVDisable] = useState(true);
  const [masterDataFilters, setMasterDataFilters] = useState({});
  const [additionalFields, setAdditionalFields] = useState([]);
  const [attributes, setAttributes] = useState({});
  const [defaultShowColumns, setDefaultShowColumns] = useState([]);
  const [units, setUnits] = useState("metric");
  const [showDimensionList, setShowDimensionList] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dimensionAnchor, setDimensionAnchor] = useState(null);
  const [loadingEditFilterButton, setLoadingEditFilterButton] = useState(true);
  const {
    userFilters,
    myLabFilter,
    lastPageSize,
    lastFavFilter,
    lastDisplayColumns,
    userDisplays,
    systemDisplays,
    email,
    actionStatus,
    groups,
    id: userID,
    user: fullUserID
  } = useSelector((state) => state?.user);
  const userDisplayList = useRef([]);
  const megaclusterIds = useRef([]);
  const masterDataAutoGroup = useRef({});
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const { InventoryId } = useParams();
  const navigation = useHistory();
  const autoGroupColField = "equipment_model";

  const agGridOptions = {
    componentsAdded: selectedEquipmentId?.length
  };

  const remainingHeight = enableCheckbox ? 286 : 225; //  header + footer + pageHeader
  const [containerHeight, setContainerHeight] = useState(
    getContainerHeight(currentEnv, remainingHeight)
  );
  window.addEventListener("resize", function () {
    setContainerHeight(getContainerHeight(currentEnv, remainingHeight));
  });
  useEffect(() => {
    setContainerHeight(getContainerHeight(currentEnv, remainingHeight));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableCheckbox]);

  const onNavigatePath = () => {
    navigation.push("/");
  };

  const handleSettingClick = (event, param) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSettingClose = () => {
    setAnchorEl(null);
  };

  const addInstrumentClick = (state, isEditMode, item) => {
    addInstrumentClicked(state, isEditMode, item);
    if (item?.entryType === entryType?.cluster) {
      setActiveModule(MODIFY_CLUSTER);
    } else {
      setActiveModule(MODIFY_INSTRUMENT);
    }
  };

  const createCluster = () => {
    setIsEditMode(false);
    setActiveModule(MODIFY_CLUSTER);
  };

  const getExportSearchedInstrumentList = async (search) => {
    updateSearchFilter(search);

    let splitSearchValues = bulkImportFilters?.searchValue
      ? bulkImportFilters?.searchValue?.split("AND")
      : [];
    let removeIndex = -1;
    let isSelectAll = true;
    let applyStatuses = [];
    if (
      bulkImportFilters?.searchValue?.includes(
        `status:"${equipmentStatus?.active?.value}"`
      )
    ) {
      applyStatuses.push(`status:'${equipmentStatus?.active?.value}'`);
    }
    if (
      bulkImportFilters?.searchValue?.includes(
        `status:"${equipmentStatus?.pending?.value}"`
      )
    ) {
      let value = bulkExportStatus ? "dummy" : equipmentStatus?.pending?.value;
      applyStatuses.push(`status:'${value}'`);
    }
    if (
      bulkImportFilters?.searchValue?.includes(
        `status:"${equipmentStatus?.deleted?.value}"`
      )
    ) {
      if (!applyStatuses?.includes(`status:'dummy'`))
        applyStatuses.push(`status:'dummy'`);
    }

    if (splitSearchValues?.length > 0) {
      splitSearchValues.forEach((value, index) => {
        if (value?.includes("status:")) {
          removeIndex = index;
          isSelectAll = value?.includes("status:*");
        }
      });
    }

    if (removeIndex > -1) {
      splitSearchValues?.splice(removeIndex, 1);
    }

    if (!isSelectAll) {
      let strValue = applyStatuses?.join(" OR ");
      splitSearchValues?.push(`(${strValue})`);
    } else if (bulkExportStatus || !bulkImportFilters?.searchValue) {
      splitSearchValues?.push(`(status:'${equipmentStatus?.active?.value}')`);
    } else {
      splitSearchValues?.push(
        `(status:'${equipmentStatus?.active?.value}' OR status:'${equipmentStatus?.pending?.value}')`
      );
    }

    let searchQuery = splitSearchValues?.join(" AND ");

    let filterDefinitionObj = {
      query: searchQuery || "*",
      /* For Futrure Reference */
      // filterQuery,
      sort: `${
        bulkImportFilters?.sortBy === "position_in_cluster"
          ? convertToSnakeCase(DATA_MODEL_TABLE?.serialNumber?.key)
          : bulkImportFilters?.sortBy
      } ${bulkImportFilters?.sort}`,
      start: 0
    };

    if (bulkExportStatus) {
      filterDefinitionObj.filterQuery = `(or entry_type:'${subEquipment}' entry_type:'${entryType?.standaloneEquipment}')`;
    }

    if (bulkImportFilters?.searchValue) {
      filterDefinitionObj = {
        ...filterDefinitionObj
      };
      if (bulkImportFilters?.queryOptions) {
        filterDefinitionObj.queryOptions = bulkImportFilters?.queryOptions;
      }
    }

    let variablesObj = {
      filterDefinition: JSON.stringify(filterDefinitionObj),
      listQuery: ""
    };
    if (!bulkExportStatus) {
      let selectedColumns =
        typeof lastDisplayColumns === "string"
          ? JSON.parse(lastDisplayColumns)
          : lastDisplayColumns;
      const showColumns =
        typeof selectedColumns?.displayDefinition?.showColumns === "string"
          ? JSON.parse(selectedColumns?.displayDefinition?.showColumns)
          : selectedColumns?.displayDefinition?.showColumns;

      const defaultShowColumns = filter(allTableColumn(), { show: true });
      const columns =
        showColumns?.length === 0
          ? defaultShowColumns?.map((x) => x.key)
          : showColumns?.map((x) => x.key);
      variablesObj.selectedColumns = `${columns.join()},${
        DATA_MODEL_TABLE?.equipmentModel?.key
      }`;
      variablesObj.dimensionUnit = units;
    }

    await getAllData({
      client,
      query: bulkExportStatus
        ? GET_BULK_EXPORTING_EQUIPMENT
        : GET_EXPORTING_EQUIPMENT,
      variables: variablesObj,
      dataPath: bulkExportStatus
        ? ["data", "getDownloadEquipmentForBulkChange"]
        : ["data", "getExportEquipmentData"],
      drillData: false
    });
    setBulkExportStatus(false);
  };

  useEffect(() => {
    if (bulkExportStatus) {
      getExportSearchedInstrumentList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulkExportStatus]);

  const titleText =
    instrumentToDelete?.status === equipmentStatus?.active?.key
      ? instrumentToDelete?.entryType === entryType?.cluster
        ? "Decommission cluster"
        : "Delete equipment"
      : instrumentToDelete?.entryType === entryType?.cluster
      ? "Restore cluster"
      : "Restore equipment";
  const titleTextButton =
    instrumentToDelete?.status === equipmentStatus?.active?.key
      ? instrumentToDelete?.entryType === entryType?.cluster
        ? "Decommission"
        : "Delete equipment"
      : instrumentToDelete?.entryType === entryType?.cluster
      ? "Restore cluster"
      : "Restore equipment";
  const contentText =
    instrumentToDelete?.status === equipmentStatus?.active?.key
      ? instrumentToDelete?.entryType === entryType?.cluster
        ? "decommission"
        : "delete"
      : "restore";
  const otherText =
    instrumentToDelete?.status === equipmentStatus?.active?.key
      ? instrumentToDelete?.entryType === entryType?.cluster
        ? "decommissioning"
        : "deleting"
      : "restoring";
  const approveColor =
    instrumentToDelete?.status === equipmentStatus?.active?.key
      ? "primary"
      : "primary";

  const editEquipment = async (item) => {
    const result = await getEquipment(item?.id, client);
    if (item?.entryType === entryType?.cluster) {
      let editData = await handleClusterFieldValuesForEdit(result, client);
      editData.subEquipment.items = sortBy(
        editData.subEquipment.items,
        "positionInCluster"
      );
      editData = omitDeep({ ...editData }, "__typename");
      if (Object.keys(editClusterDetail).length === 0) {
        updateEditClusterDetail({ ...editData });
      }
      updateClusterDetail({ ...editData });
      addInstrumentClick(true, true, result);
    } else {
      setInstrument(result);
      addInstrumentClick(true, true, result);
    }
  };

  const loadAuditTrailList = async (item) => {
    try {
      setOpenAuditTrial(true);
      setLoading(true);
      let { items: auditLogChanges } = await getAllData({
        client,
        query: LIST_INSTRUMENT_REPOSITORY_AUDITS,
        variables: {
          inventoryId: item?.id
        },
        dataPath: ["data", "listInstrumentRepositoryAudits"],
        drillData: true
      });

      let { items: userList } = await getAllData({
        client,
        query: LIST_DIGITAL_LAB_INSTRUMENT_REPOSITORY_USERS,
        fetchPolicy: "network-only",
        dataPath: ["data", "listDigitalLabInstrumentRepositoryUsers"],
        drillData: true,
        variables: {
          limit: 1000
        }
      });

      setAuditLogs(auditLogChanges);
      setUserLists(userList);
    } catch (err) {
      console.warn(err);
      setAuditLogs([]);
      setUserLists([]);
    } finally {
      setLoading(false);
    }
  };

  const deleteEquipment = async (item) => {
    const result = await getEquipment(item?.id, client);
    setInstrumentToDelete(result);
    openDialog();
  };

  const handleCloseAuditTrial = (isOpenEdit) => {
    setOpenAuditTrial(false);
    // if (!isOpenEdit) editEquipment(item);
  };

  const { isVisible, toggleMenu } = useContext(TogglerContext);

  const getCloudSearchOptions = (data) => {
    setBulkImportFilters(data);
  };

  //============================AG-GRID-COMMON=================
  useEffect(() => {
    userDisplayList.current = [...userDisplays];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let siteList = useSelector((state) => state?.user?.sites);
  const linkedInstanceList = useSelector(
    (state) => state.instruments?.linkedInstanceList
  );

  const autoGroupColumnDef = useMemo(() => {
    return {
      headerName: "Model",
      field: autoGroupColField,
      sortable: true,
      pinned: "left",
      lockPinned: true,
      filter: "agSetColumnFilter",
      filterParams: {
        buttons: ["reset"],
        defaultToNothingSelected: true,
        values: (params) => {
          const values =
            masterDataAutoGroup?.current[
              DATA_MODEL_TABLE?.equipmentModel?.key
            ] || [];
          params.success(values);
        }
      },
      showDisabledCheckboxes: enableCheckbox,
      cellStyle: (params) => {
        if (params?.data?.id && params?.data?.id[0] === "dummyRow") {
          return { display: "none" };
        }
        return params?.node?.level > 0 &&
          (params?.data?.entry_type[0] === entryType?.cluster ||
            params?.data?.entry_type[0] === entryType?.clusterSubequipment)
          ? {
              border: "none !important",
              backgroundColor: "#F5F5F2",
              backgroundImage:
                "conic-gradient(at 7% 90%, #FFFFFF 180deg, #F5F5F2 180deg)"
            }
          : {
              marginLeft: "none !important"
            };
      },
      cellRendererParams: {
        checkbox: (params) => {
          let UserViewerStatus = false;
          if (
            (actionStatus.hasRole === "User" ||
              actionStatus.hasRole === "Viewer") &&
            params?.data?.status[0] === equipmentStatus?.active?.key
          ) {
            groups.forEach((x) => {
              if (
                params?.data["belonging_to_group"][0].startsWith(x.groupName)
              ) {
                UserViewerStatus = true;
              }
            });
          }
          let updateCheckBox =
            params?.data?.entry_type[0] === "CLUSTER" ||
            params?.data?.entry_type[0] === "CLUSTER-SUBEQUIPMENT"
              ? false
              : actionStatus.hasRole === "Admin" &&
                params?.data?.status[0] === equipmentStatus?.active?.key
              ? true
              : UserViewerStatus;
          return enableCheckbox ? updateCheckBox : enableCheckbox;
        },
        innerRenderer: (params) => {
          return params?.data?.equipment_model[0];
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableCheckbox]);

  const onCheckBoxSelected = ({ id = "", updatedIds = null }) => {
    if (id) {
      setSelectedEquipmentId((prevState) => {
        return onlyUnique([...prevState, id]);
      });
    }
    if (updatedIds) {
      setSelectedEquipmentId(() => {
        return updatedIds;
      });
    }
  };
  useEffect(() => {
    const loadMasterData = async () => {
      const { masterDataFilter } = await loadFilterData({
        client,
        siteList,
        linkedInstanceList
      });
      masterDataAutoGroup.current = { ...masterDataFilter };
      setMasterDataFilters({ ...masterDataFilter });
    };

    loadMasterData();

    const loadMegaClusterIds = async () => {
      const ids = await getMegaClusterIds();
      megaclusterIds.current = uniq(ids);
    };

    loadMegaClusterIds();

    let attr = {
      rowModelType: "serverSide",
      rowQuickSearch: false,
      treeData: true,
      suppressPaginationPanel: true,
      pagination: true,
      animateRows: true,
      rowSelection: "multiple",
      rowMultiSelectWithClick: true,
      rowExport: false,
      masterDetail: true,
      isGroupOpenByDefault: false,
      defaultToolPanel: "filters",
      hiddenByDefault: true,
      serverSideInfiniteScroll: false,
      serverSideSortOnServer: true,
      suppressRowClickSelection: true
    };

    setAttributes(() => attr);

    // eslint-disable-next-line
  }, []);

  const actionRender = (params) => {
    return (
      <>
        <EquipmentAction
          editEquipment={editEquipment}
          loadAuditTrailList={loadAuditTrailList}
          deleteEquipment={deleteEquipment}
          actionStatus={actionStatus}
          groups={groups}
          params={params}
          fullUserID={fullUserID}
          onReviewOrRevert={onReviewOrRevert}
        />
      </>
    );
  };

  useEffect(() => {
    const unorderedHidenColumn = [];
    const orderedShowColumns = sortBy(
      convertStrToObjColumns(lastDisplayColumns)?.displayDefinition
        ?.showColumns,
      ["order"],
      ["asc"]
    );
    allTableColumn()?.forEach((value, index) => {
      const obj = find(orderedShowColumns, {
        key: value?.key
      });
      if (!obj) {
        unorderedHidenColumn.push(value);
      }
    });

    const redefinedColumns = [
      ...orderedShowColumns,
      ...unorderedHidenColumn
    ]?.map((value) => {
      return getColumnObj(
        value,
        masterDataFilters,
        convertToCameCase(autoGroupColField),
        units
      );
    });

    const defaultShowCols = filter(allTableColumn(), { show: true });
    setDefaultShowColumns(() => {
      return defaultShowCols?.map((value) => {
        return getColumnObj(
          value,
          masterDataFilters,
          convertToCameCase(autoGroupColField),
          units
        );
      });
    });

    const aditionColumns = [
      {
        field: "actions",
        headerName: "Actions",
        maxWidth: 50,
        filter: false,
        pinned: "right",
        lockVisible: true,
        lockPosition: "right",
        cellClass: "action-render",
        cellRenderer: actionRender
      }
    ];
    setAdditionalFields(() => [...aditionColumns]);
    setDataModel([...redefinedColumns]);
    // eslint-disable-next-line
  }, [masterDataFilters, units]);

  const onSave = async ({
    currentState,
    chipName = "",
    type = "update",
    isAgGridObj = false,
    pannel = "",
    isSaveConfigUpdated = false,
    updateRedux = true
  }) => {
    let result = null;
    if (pannel === TOOL_PANELS?.filters) {
      let obj = {};
      if (isAgGridObj) {
        for (const key in DEFAULT_FILTER.filterDefinition) {
          if (
            currentState[convertToSnakeCase(filterKeyMapping[key] || key)] ===
            undefined
          ) {
            obj[key] = DEFAULT_FILTER.filterDefinition[key];
          } else if (
            currentState[convertToSnakeCase(filterKeyMapping[key] || key)]
              ?.filterType === "set"
          ) {
            let values =
              currentState[convertToSnakeCase(filterKeyMapping[key] || key)]
                ?.values;
            if (
              filterKeyMapping[key] === DATA_MODEL_TABLE?.equipmentModel?.key ||
              key === DATA_MODEL_TABLE?.equipmentModel?.key
            ) {
              if (
                Object?.keys(currentState)?.includes(agGridAutoGroupfieldName)
              ) {
                values = uniq([
                  ...values,
                  ...currentState[agGridAutoGroupfieldName]?.values
                ]);
              }
            }
            obj[key] = values;
          } else {
            obj[key] =
              currentState[convertToSnakeCase(filterKeyMapping[key] || key)];
          }
        }
      } else {
        obj = {
          ...currentState
        };
      }

      const lastSavedFilter = {
        filterDefinition: obj,
        filterName: chipName
      };
      if (type === "create") {
        lastSavedFilter.userID = userID;
        lastSavedFilter.chipName = chipName;
        result = await onCreatefilter({
          id: userID,
          email,
          userFilters,
          lastFavFilter: lastSavedFilter,
          client,
          query: CREATE_DIGITAL_LAB_INSTRUMENT_PAGE_USER_FILTER,
          userProfileQuery:
            UPDATE_DIGITAL_LAB_INSTRUMENT_USER_PROFILE_PERSONAL_FIELDS,
          updateUserFilters,
          updateLastFavFilter
        });
      } else {
        result = await onUpdateFilter({
          lastFavFilter: lastSavedFilter,
          isSaveConfigUpdated: isSaveConfigUpdated,
          updateRedux,
          id: userID,
          email,
          client,
          query: UPDATE_DIGITAL_LAB_INSTRUMENT_PAGE_USER_FILTER,
          userProfileQuery:
            UPDATE_DIGITAL_LAB_INSTRUMENT_USER_PROFILE_PERSONAL_FIELDS,
          updateLastFavFilter
        });
      }
      return { savedObj: lastSavedFilter, result };
    } else if (pannel === TOOL_PANELS?.columns) {
      const savedColumns = [...currentState];
      const updatedSavedColumns = savedColumns.map((column, index) => {
        const dataModelKey = convertToCameCase(column?.colId);
        const obj = {
          key: dataModelKey,
          val: DATA_MODEL_TABLE[dataModelKey]?.value || "ignore",
          order: index,
          show: DATA_MODEL_TABLE[dataModelKey]?.value?.length
            ? !column?.hide
            : "ignore",
          sortStatus: listOfFieldsSortable.includes(
            DATA_MODEL_TABLE[dataModelKey]?.key
          )
        };
        return obj;
      });
      const showColumns = filter(updatedSavedColumns, { show: true });
      const hideColumns = filter(updatedSavedColumns, { show: false });
      let savecolumnObj = {
        displayName: chipName,
        displayDefinition: {
          showColumns,
          hideColumns
        }
      };
      if (type === "create") {
        savecolumnObj.userID = userID;
        savecolumnObj.chipName = chipName;
        result = await onCreateSelectColumn({
          id: userID,
          email,
          userDisplayList,
          unParsedData: savecolumnObj,
          client,
          query: CREATE_IR_DISPLAY_COLUMN,
          userProfileQuery:
            UPDATE_DIGITAL_LAB_INSTRUMENT_USER_PROFILE_PERSONAL_FIELDS,
          updateUserDisplayColumns,
          updateLastDisplayColumn
        });
      } else {
        result = await onUpdateSelectColumn({
          data: JSON.stringify(savecolumnObj),
          isSaveConfigUpdated,
          updateRedux,
          id: userID,
          email,
          client,
          userProfileQuery:
            UPDATE_DIGITAL_LAB_INSTRUMENT_USER_PROFILE_PERSONAL_FIELDS,
          query: UPDATE_IR_DISPLAY_COLUMN,
          userDisplayList,
          updateUserDisplayColumns,
          updateLastDisplayColumn
        });
      }
      return { savedObj: savecolumnObj, result };
    }
  };

  const isServerSideGroup = useCallback((dataItem) => {
    // indicate if node is a group

    if (
      dataItem?.entry_type &&
      dataItem?.entry_type?.includes(entryType?.cluster)
    ) {
      return dataItem?.id;
    }

    return "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getServerSideGroupKey = useCallback((dataItem) => {
    // specify which group key to use

    if (
      dataItem?.entry_type &&
      dataItem?.entry_type?.includes(entryType?.cluster)
    ) {
      return dataItem?.id;
    }

    return "";
  }, []);

  const onChipDelete = async (
    chipToDelete,
    lastSavedChip,
    panel,
    userFilters
  ) => {
    return await onDeleteSaveConfigurations({
      userID,
      chipToDelete,
      panel,
      lastSavedChip,
      client,
      email,
      deleteFilterQuery: DELETE_DIGITAL_LAB_INSTRUMENT_PAGE_USER_FILTER,
      deleteColumnQuery: DELETE_IR_DISPLAY_COLUMN,
      userProfileQuery:
        UPDATE_DIGITAL_LAB_INSTRUMENT_USER_PROFILE_PERSONAL_FIELDS,
      userFilters,
      userDisplayList,
      updateUserFilters,
      updateUserDisplayColumns,
      updateLastFavFilter,
      updateLastDisplayColumn
    });
  };

  const onResetAll = async () => {
    await onUpdateFilter({
      lastFavFilter: DEFAULT_FILTER,
      updateRedux: true,
      id: userID,
      email,
      client,
      query: UPDATE_DIGITAL_LAB_INSTRUMENT_PAGE_USER_FILTER,
      userProfileQuery:
        UPDATE_DIGITAL_LAB_INSTRUMENT_USER_PROFILE_PERSONAL_FIELDS,
      updateLastFavFilter
    });
    await onUpdateSelectColumn({
      data: JSON.stringify(DEFAULT_COLUMNS),
      updateRedux: true,
      id: userID,
      email,
      client,
      userProfileQuery:
        UPDATE_DIGITAL_LAB_INSTRUMENT_USER_PROFILE_PERSONAL_FIELDS,
      query: UPDATE_IR_DISPLAY_COLUMN,
      userDisplayList,
      updateUserDisplayColumns,
      updateLastDisplayColumn
    });
  };

  const onRowsPerChange = async (rowPerPage) => {
    await saveLastPageSize({
      limit: rowPerPage,
      limitKey: "ir_grid",
      lastPageSize,
      query: UPDATE_DIGITAL_LAB_INSTRUMENT_USER_PROFILE_PERSONAL_FIELDS,
      updateLastPageSize,
      id: userID,
      email,
      client
    });
  };

  const requestServer = (cloudSearchConfigOption) => {
    return CloudSearchConfig(cloudSearchConfigOption);
  };

  const searchFilterData = useCallback(() => {
    const belongingToGroupKey = convertToSnakeCase(
      DATA_MODEL_TABLE?.belongingToGroup?.key
    );
    /* columnFilterGroups will have the list of column filter groups */
    let filterSplit = bulkImportFilters?.searchValue?.split(" ");
    let columnFilterGroups = [];

    filterSplit.forEach((value) => {
      if (value?.includes(belongingToGroupKey)) {
        let groupStr = value?.split(":")[1];
        groupStr = groupStr?.replaceAll(")", "");
        groupStr = groupStr?.replaceAll(`"`, "");
        columnFilterGroups.push(groupStr);
      }
    });
    /* columnFilterGroups will have the list of column filter groups */

    let groupName = "";
    let userGroupStrs = [];
    let groupCloumnFilterStrs = [];
    if (actionStatus?.hasRole === "User" && groups.length > 0) {
      groups.forEach((userGroup) => {
        if (
          bulkImportFilters?.searchValue?.includes(belongingToGroupKey) &&
          columnFilterGroups?.length > 0
        ) {
          columnFilterGroups?.forEach((groupStr) => {
            if (groupStr?.startsWith(userGroup?.groupName)) {
              let checkDuplicateObj = `${belongingToGroupKey}:${groupStr}`;
              const addGroups = find(groupCloumnFilterStrs, (strValue) => {
                if (strValue === checkDuplicateObj) {
                  return true;
                }
              });
              if (!addGroups)
                groupCloumnFilterStrs.push(
                  `${belongingToGroupKey}:${groupStr}`
                );
            }
          });
        } else {
          userGroupStrs.push(
            `(${belongingToGroupKey} : ${userGroup.groupName}*)`
          );
        }
      });
      groupName = userGroupStrs?.join(" OR ");
    }

    let dataOptionList = { ...bulkImportFilters };
    dataOptionList.start = 0;
    dataOptionList.filterQuery =
      dataOptionList?.filterQuery &&
      dataOptionList?.filterQuery?.includes(
        `status:'${equipmentStatus?.pending?.value}'`
      )
        ? dataOptionList?.filterQuery?.replace(
            `status:'${equipmentStatus?.pending?.value}'`,
            ""
          )
        : dataOptionList?.filterQuery;
    const itemList = async () => {
      let splitSearchValues = dataOptionList.searchValue
        ? dataOptionList.searchValue?.split("AND")
        : [];
      let removeIndex = -1;
      let isActiveAvailable = true;
      if (splitSearchValues?.length > 0) {
        splitSearchValues.forEach((value, index) => {
          if (value?.includes(`status:`)) {
            isActiveAvailable =
              value?.includes(`Active`) || value?.includes(`*`);
            removeIndex = index;
          }
        });
      }
      if (removeIndex !== -1 && isActiveAvailable) {
        splitSearchValues?.splice(removeIndex, 1);
        splitSearchValues?.push(`(status:'${equipmentStatus?.active?.value}')`);
      } else if (!dataOptionList?.filterQuery && removeIndex === -1) {
        splitSearchValues?.push(`(status:'${equipmentStatus?.active?.value}')`);
      }

      if (splitSearchValues?.length > 0) {
        splitSearchValues = splitSearchValues?.map((value) => value?.trim());
      }

      let joinedAndStr = splitSearchValues?.join(" AND ");
      dataOptionList.size = LIMIT_EDIT_FILTER;
      dataOptionList.searchValue = joinedAndStr
        ? `${joinedAndStr} AND (entry_type:${entryType?.standaloneEquipment})`
        : `(entry_type:${entryType?.standaloneEquipment})`;

      if (actionStatus?.hasRole === "User") {
        if (!dataOptionList?.searchValue?.includes(belongingToGroupKey)) {
          dataOptionList.searchValue = groupName
            ? `${dataOptionList.searchValue} AND (${groupName})`
            : `${dataOptionList.searchValue}`;
        } else {
          let replaceStr = "";
          dataOptionList.searchValue?.split(")").forEach((splitValues) => {
            if (splitValues?.includes(belongingToGroupKey)) {
              replaceStr = `${splitValues})`;
            }
          });
          dataOptionList.searchValue = dataOptionList.searchValue?.replace(
            replaceStr,
            `(${groupCloumnFilterStrs?.join(" OR ")})`
          );
        }
      }

      try {
        setLoadingEditFilterButton(true);
        if (
          actionStatus.hasRole === "Admin" ||
          (actionStatus.hasRole === "User" &&
            !bulkImportFilters?.searchValue?.includes(belongingToGroupKey)) ||
          (actionStatus.hasRole === "User" && groupCloumnFilterStrs?.length > 0)
        ) {
          if (actionStatus.hasRole === "User") {
            dataOptionList.searchValue = dataOptionList.searchValue.replaceAll(
              belongingToGroupKey,
              GROUP_LITERAL
            );
          }
          if (isActiveAvailable) {
            let resultSearchFilter = await CloudSearchConfig(dataOptionList);
            setSearchFilterCount([...resultSearchFilter?.data]);
          } else {
            setSearchFilterCount([]);
          }
        } else {
          setSearchFilterCount([]);
        }
        setLoadingEditFilterButton(false);
      } catch (err) {
        console.log(err);
      }
    };
    itemList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulkImportFilters]);

  useEffect(() => {
    if (enableCheckbox) {
      if (
        bulkImportFilters !== undefined &&
        bulkImportFilters?.searchValue !== "" &&
        !bulkImportFilters.searchValue.includes("cluster_id")
      ) {
        if (actionStatus.hasRole === "Admin") {
          searchFilterData();
        } else if (actionStatus.hasRole === "User" && groups.length > 0) {
          searchFilterData();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableCheckbox, bulkImportFilters]);

  const handleClick = (event, item) => {
    item?.onClick(event);
  };

  const handleMenuItemClick = (event, key, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handelExportActivation = (virtualRowRemovedEvent) => {
    const nodes = virtualRowRemovedEvent?.api?.getRenderedNodes();
    let viewFlag = true;
    let viewFlagDCSV = true;
    nodes.forEach((node) => {
      if (node?.data?.status[0] === equipmentStatus?.active?.key) {
        viewFlagDCSV = false;
      }
      if (
        node?.data?.status[0] === equipmentStatus?.active?.key ||
        node?.data?.status[0] === equipmentStatus?.pending?.key
      ) {
        viewFlag = false;
      }
    });
    setDownloadBulkCSVDisable(viewFlagDCSV);
    setExportDisable(viewFlag);
  };

  const groupButtonOptions = {
    hideAction: !(actionStatus?.showAddCluster && actionStatus?.showAddEquip),
    actions: [
      {
        key: "add_equipment",
        value: "Add equipment",
        onClick: () => {
          addInstrumentClick(true, false, null);
        }
      },
      {
        key: "add_cluster",
        value: "Add cluster",
        onClick: () => {
          createCluster();
        }
      }
    ],
    defaultLabel: "Add",
    prefixLabel: ""
  };

  const handleDimentionIconClick = (event, param) => {
    setDimensionAnchor(event.currentTarget);
  };

  const handleDimentionIconClose = () => {
    setDimensionAnchor(null);
    setShowDimensionList(false);
  };
  return (
    <>
      {openAuditTrial && (
        <AuditTrialDialog
          title="Log entry information"
          openAuditTrial={openAuditTrial}
          setOpenAuditTrial={setOpenAuditTrial}
          handleClose={handleCloseAuditTrial}
          changeItem={auditLogs}
          canEdit={actionStatus}
          dataTestId="audit-trial"
          isLoading={loading}
          hasPermission={hasPermission}
          userLists={userLists}
        />
      )}
      <div
        style={{
          backgroundColor: "var(--one-color-cobas-white)",
          boxShadow:
            activeModule === SHOW_INSTRUMENT
              ? "0px 1px 3px rgba(0, 0, 0, 0.4)"
              : "none",
          paddingTop: activeModule === SHOW_INSTRUMENT ? "16px" : "0px",
          height:
            activeModule === SHOW_INSTRUMENT
              ? `${containerHeight + 185}px`
              : "100%"
        }}
      >
        <ConfirmDialog
          {...dialogProps}
          approveText={titleTextButton}
          approveColor={approveColor}
          approveVariant="contained"
          cancelText="Cancel"
          cancelVariant="outlined"
          cancelColor="primary"
          size="md"
          approveDisable={
            selectedReason === ""
              ? true
              : selectedReason && selectedReason?.trim() === OTHER_REASON
              ? addComment === ""
              : false
          }
          // style={{ maxWidth: "755px", width: "755px", minWidth: "755px" }}
          onApprove={() => {
            instrumentToDelete?.entryType === entryType?.cluster
              ? deleteClusterAction({
                  loadInstruments,
                  instruments,
                  client,
                  setInstrumentToDelete,
                  selectedReason,
                  addComment,
                  trackEvent
                })(instrumentToDelete, setDataDelRes)
              : deleteInstrumentAction({
                  loadInstruments,
                  instruments,
                  client,
                  setInstrumentToDelete,
                  selectedReason,
                  addComment,
                  trackEvent
                })(instrumentToDelete, setDataDelRes);
            setAddComment("");
          }}
          onCancel={() => {
            setInstrumentToDelete(null);
            setAddComment("");
          }}
          title={
            <OwcTypography style={{ fontWeight: "bold" }}>
              {titleText}
            </OwcTypography>
          }
          // content={<InstrumentToDelete instrumentToDelete={instrumentToDelete} />}
          content={
            instrumentToDelete && (
              <>
                <OwcTypography
                  style={{
                    fontSize: 18,
                    marginBottom: 20,
                    color: "#333",
                    fontWeight: "bold"
                  }}
                >
                  {instrumentToDelete?.entryType === entryType?.cluster
                    ? `Are you sure you want to ${contentText} the cluster ?`
                    : `Are you sure you want to ${contentText} the equipment ?`}
                </OwcTypography>
                <div
                  style={{
                    borderTop: "1px solid #C2BAB5",
                    borderBottom: "1px solid #C2BAB5",
                    padding: "16px 0px 16px 4px"
                  }}
                >
                  <ShowEquipmentInfo equipmentDetail={instrumentToDelete} />
                </div>
                <OwcTypography
                  style={{
                    fontSize: 18,
                    margin: "20px 0 0 0",
                    color: "#333",
                    fontWeight: "bold"
                  }}
                >
                  {instrumentToDelete?.entryType === entryType?.cluster
                    ? `Reason for ${otherText} cluster`
                    : `Reason for ${otherText} equipment`}
                  <ReasonDropdown
                    selectedReason={selectedReason}
                    setSelectedReason={setSelectedReason}
                    addComment={addComment}
                    setAddComment={setAddComment}
                  />
                </OwcTypography>
              </>
            )
          }
        />
        {/**
         * the below InstrumentsModal code can be removed after implementation of the unicity check
         * until then it can remain commented for reference
         */}
        <InstrumentsModal
          instruments={instruments}
          open={instrument !== null}
          title={
            instrument?.materialNumber && instrument?.serialNumber
              ? "Edit equipment"
              : "Add equipment"
          }
          instrument={instrument}
          cancel={() => setInstrument(null)}
          save={saveInstrumentAction({
            instrument,
            client,
            instruments,
            loadInstruments,
            setInstrument
          })}
        ></InstrumentsModal>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 20,
            position: "relative"
          }}
        >
          <OwcTypography
            variant="title5"
            style={{ paddingLeft: "16px", display: "flex", margin: "1px" }}
          >
            Equipment list
          </OwcTypography>
          <span
            onClick={toggleMenu}
            style={{
              position: "absolute",
              right: "6px"
            }}
          >
            {isVisible ? (
              <>
                <OwcIconButton
                  icon="full_screen"
                  type="filled"
                  id="full_screen"
                  flat
                />
                <OwcTooltip anchor="full_screen" placement="left">
                  Go to fullscreen mode
                </OwcTooltip>
              </>
            ) : (
              <>
                <OwcIconButton
                  icon="full_screen_exit"
                  type="filled"
                  id="exit_full_screen"
                  flat
                />
                <OwcTooltip anchor="exit_full_screen" placement="left">
                  Exit fullscreen mode
                </OwcTooltip>
              </>
            )}
          </span>
        </div>
        {Object.keys(masterDataFilters)?.length > 0 && (
          <DLabEquipmentList
            autoGroupColumnDefObj={autoGroupColumnDef}
            columnData={dataModel}
            height={containerHeight}
            masterDataFilters={masterDataFilters}
            additionalFields={additionalFields}
            selectedEquipment={selectedEquipmentId}
            userFilters={userFilters}
            myLabFilter={myLabFilter}
            lastFavFilter={InventoryId ? DEFAULT_FILTER : lastFavFilter}
            lastDisplayColumns={lastDisplayColumns ?? DEFAULT_COLUMNS}
            userDisplays={userDisplays}
            systemDisplays={systemDisplays}
            onSave={onSave}
            onVirtualRowRemoved={handelExportActivation}
            onCheckBoxChange={onCheckBoxSelected}
            onChipDelete={onChipDelete}
            defaultFilterObj={DEFAULT_FILTER}
            defaultShowColumns={defaultShowColumns}
            listOfFieldsIgnoredForSearch={listOfFieldsIgnoredForSearch}
            listOfNumberFilter={listOfNumberFilter}
            listOfFieldAcceptOnlyInteger={listOfFieldAcceptOnlyInteger}
            isServerSideGroupObj={isServerSideGroup}
            getServerSideGroupKeyObj={getServerSideGroupKey}
            onResetAll={onResetAll}
            agGridOptions={agGridOptions}
            getCloudSearchOptions={getCloudSearchOptions}
            limit={
              typeof lastPageSize === "string"
                ? JSON.parse(lastPageSize)?.ir_grid
                : lastPageSize?.ir_grid
            }
            onRowsPerChange={onRowsPerChange}
            requestServer={requestServer}
            dataDelRes={dataDelRes}
            onNavigatePath={onNavigatePath}
            equipmentInventoryId={InventoryId}
            metricValue={units}
            literalFields={literalFields}
            {...attributes}
          >
            <span name="topBarAgGrid">
              <span
                style={{
                  marginLeft: "auto"
                }}
              >
                <div>
                  <OwcIconButton
                    flat
                    onClick={(e) => {
                      setShowDimensionList(!showDimensionList);
                      handleDimentionIconClick(e);
                    }}
                  >
                    <OwcIcon
                      name="ruler"
                      type="outlined"
                      style={{
                        color: dimensionAnchor
                          ? "var(--one-color-interaction-default-brand-1)"
                          : ""
                      }}
                      id="dimensionList"
                    />
                  </OwcIconButton>
                  <OwcTooltip anchor="dimensionList" placement="left">
                    Change Units
                  </OwcTooltip>
                </div>
                {ReactDOM.createPortal(
                  <OwcMenu
                    anchor="dimensionList"
                    visible={dimensionAnchor}
                    onClickOutside={handleDimentionIconClose}
                    style={
                      !showDimensionList
                        ? { display: "none" }
                        : {
                            // display: "flex",
                            width: "244px",
                            height: "160px",
                            bordeRadius: "4px",
                            border: "1px",
                            position: "relative"
                          }
                    }
                  >
                    <OwcList expandable expanded header="Select Units">
                      <OwcListItem
                        selected={dimentionUnits.metricUnit === units}
                        onClick={() => {
                          setUnits(dimentionUnits.metricUnit);
                        }}
                      >
                        <OwcRadio>Metric</OwcRadio>
                      </OwcListItem>
                      <OwcListItem
                        onClick={() => {
                          setUnits(dimentionUnits.imperialUnit);
                        }}
                      >
                        <OwcRadio>Imperial</OwcRadio>
                      </OwcListItem>
                    </OwcList>
                  </OwcMenu>,
                  document.body
                )}
              </span>
              <div style={{ marginLeft: "auto" }}>
                <OwcIconButton
                  icon="more_vertical"
                  type="legacy"
                  flat
                  onClick={(event) => {
                    handleSettingClick(event);
                  }}
                  id="csv-menu"
                />
                {ReactDOM.createPortal(
                  <OwcMenu
                    data-testid="simple-menu"
                    anchor="csv-menu"
                    visible={anchorEl}
                    onClickOutside={handleSettingClose}
                    disablePortal={true}
                  >
                    {actionStatus?.exportBulkCSV && (
                      <>
                        <OwcListItem
                          disabled={downloadBulkCSVDisable}
                          value={3}
                          onClick={() => {
                            setBulkExportStatus(true);
                            setAnchorEl(null);
                          }}
                        >
                          Download CSV for <br />
                          bulk change
                        </OwcListItem>
                      </>
                    )}
                    <>
                      <OwcListItem
                        disabled={exportDisable}
                        value={3}
                        onClick={() => {
                          setAnchorEl(null);
                          getExportSearchedInstrumentList();
                        }}
                      >
                        Export CSV
                      </OwcListItem>
                      <CoverMenuItem />
                    </>
                    {(actionStatus.hasRole === "Admin" ||
                      actionStatus.hasRole === "User") && (
                      <OwcListItem
                        key="Bulk edit"
                        noBorder={true}
                        onClick={() => {
                          setEnableCheckbox(true);
                          setAnchorEl(null);
                        }}
                        value={3}
                      >
                        Bulk edit
                      </OwcListItem>
                    )}
                  </OwcMenu>,
                  document.body
                )}
              </div>
            </span>
            {actionStatus?.showAddCluster && actionStatus?.showAddEquip && (
              <span name="pagination">
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "end",
                    marginRight: enableCheckbox ? 0 : "10px"
                  }}
                >
                  {!enableCheckbox ? (
                    <>
                      <ButtonGroup
                        variant="outlined"
                        size="small"
                        color="primary"
                        ref={anchorRef}
                        aria-label="split button"
                      >
                        <Button
                          style={{
                            textTransform: "none",
                            width: "max-content"
                          }}
                          onClick={(event) => {
                            handleClick(
                              event,
                              groupButtonOptions?.actions[selectedIndex]
                            );
                          }}
                          size="small"
                        >
                          {selectedIndex === null
                            ? groupButtonOptions?.defaultLabel
                            : groupButtonOptions?.prefixLabel +
                              groupButtonOptions?.actions[selectedIndex]?.value}
                        </Button>
                        <Button
                          color="primary"
                          size="small"
                          aria-controls={open ? "split-button-menu" : undefined}
                          aria-expanded={open ? "true" : undefined}
                          aria-label="select merge strategy"
                          aria-haspopup="menu"
                          onClick={handleToggle}
                        >
                          <ArrowDropDown />
                        </Button>
                      </ButtonGroup>
                      <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin:
                                placement === "bottom"
                                  ? "center top"
                                  : "center bottom"
                            }}
                          >
                            <Paper>
                              <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu">
                                  {groupButtonOptions?.actions?.map(
                                    (option, index) => (
                                      <MenuItem
                                        key={option?.key}
                                        selected={index === selectedIndex}
                                        onClick={(event) =>
                                          handleMenuItemClick(
                                            event,
                                            option?.key,
                                            index
                                          )
                                        }
                                      >
                                        {option?.value}
                                      </MenuItem>
                                    )
                                  )}
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </>
                  ) : (
                    <>
                      <OwcTypography
                        variant="body2"
                        style={{ marginRight: "10px" }}
                      >
                        Components selected
                      </OwcTypography>
                      <OwcBadge type="active">
                        {agGridOptions?.componentsAdded}
                      </OwcBadge>
                    </>
                  )}
                </div>
              </span>
            )}
          </DLabEquipmentList>
        )}
        {enableCheckbox && (
          <div
            data-testid="bulk-edit-Cover"
            style={{
              display: "flex",
              width: "100%",
              backgroundColor: "var(--one-color-cobas-white)",
              alignItems: "center",
              paddingTop: "15px",
              paddingBottom: "10px"
            }}
          >
            <div style={{ marginLeft: "25px", width: "100%" }}>
              <OwcButton
                data-testid="button-edit-filtered-approve-bulk-edits"
                disabled={
                  loadingEditFilterButton ||
                  bulkImportFilters?.searchValue === "" ||
                  (searchFilterCount !== undefined &&
                    searchFilterCount?.length === 0)
                }
                onClick={() => {
                  setActiveModule(BULK_EDIT_ACTIVE);
                  setQueryChoice(SEARCH_FILTER_QUERY);
                }}
                variant="secondary"
              >
                Edit filtered{" "}
                {loadingEditFilterButton === false &&
                  bulkImportFilters?.searchValue !== "" &&
                  searchFilterCount?.length !== 0 &&
                  "(" + searchFilterCount?.length + ")"}
              </OwcButton>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end"
              }}
            >
              <div style={{ marginRight: "15px", marginLeft: "15px" }}>
                <OwcButton
                  data-testid="confirm-cancel-bulk-edit-button"
                  onClick={() => {
                    setEnableCheckbox(false);
                    setSelectedEquipmentId(() => []);
                  }}
                  variant="secondary"
                >
                  Cancel
                </OwcButton>
              </div>
              <div style={{ marginRight: "25px" }}>
                <OwcButton
                  disabled={selectedEquipmentId.length === 0}
                  data-testid="confirm-bulk-edit-button-approve"
                  onClick={() => {
                    setActiveModule(BULK_EDIT_ACTIVE);
                    setQueryChoice(NORMAL_BULK_EDIT);
                  }}
                  variant="primary"
                >
                  Bulk edit
                </OwcButton>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  editClusterDetail: state?.clusters?.editClusterDetail,
  groupList: state.instruments?.groupList
});

export default compose(
  connect(mapStateToProps, {
    loadInstruments: loadInstrumentsAction,
    updateSearchFilter: updateSearchFilterAction,
    updateClusterDetail: updateClusterDetailAction,
    updateEditClusterDetail: updateEditClusterDetailAction,
    updateLastDisplayColumn: updateLastDisplayColumnAction,
    updateUserDisplayColumns: updateUserDisplayColumnsAction,
    updateLastFavFilter: updateLastFavFilterAction,
    updateUserFilters: updateUserFiltersAction,
    updateLastPageSize: updateLastPageSizeAction
  }),
  withApollo
)(InstrumentsTable);
