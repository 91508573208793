const DATA_LABEL_TABLE = {
  LEFT_MENU_BAR: {
    main_menu_label: "Menu",
    equiment_list_menu_label: "Equipment list",
    equipment_import_menu_label: "Import",
    equipment_admin_menu_label: "Admin panel"
  },
  IMPORT_TABS: {
    equipment_import: "Equipment import",
    master_data_import: "Master data import"
  },
  ADMIN_TABS: {
    group_access: "Group access management",
    manage_reasons: "Reason management",
    linked_instance: "Linked instance management",
    master_data: "Master data management"
  }
};

export default DATA_LABEL_TABLE;
