import { pick, isEqual, omit } from "underscore";

const isItemsEqual = (item, other, idKeys) =>
  isEqual(pick(item, ...idKeys), pick(other, ...idKeys));

export const updateItem = (data, payload, idKeys = ["id"]) => {
  const rest = omit(payload, ...idKeys);
  const other = pick(payload, ...idKeys);
  return data.map((item) =>
    isItemsEqual(item, other, idKeys) ? { ...item, ...rest } : item
  );
};

export const deleteItem = (data, other, idKeys = ["id"]) =>
  data.filter((item) => !isItemsEqual(item, other, idKeys));

export const addItem = (data, item, idKeys = ["id"]) =>
  data.some((it) => isItemsEqual(it, item, idKeys)) ? data : [item, ...data];
