import { intersection, pick, set, omit, difference, find } from "lodash";
import * as R from "ramda";
import flatten, { unflatten } from "flat";
import { InstrumentValidationSchema } from "./schema";
import { emptyImportInstruments } from "../../instruments/InstrumentsModal";
import { InstrumentValidationError } from "./InstrumentValidationError";
import DATA_MODEL_TABLE from "../../../utils/constants/dataModelTable";

export const omittedCSVKeys = ["qualificationDocuments", "installedTests"];

export const getAllAvailableKeys = (obj, omittedKeys) => {
  return Object.keys(omit(obj, omittedKeys));
};

export const requiredInstrumentProperties = [
  "serialNumber",
  "siteName",
  "siteTimezone",
  "manufacturer",
  "equipmentModel",
  "belongingToGroup",
  "buildingLocation",
  "room",
  "floor",
  "responsiblePerson",
  "qualificationStatus"
];

export const requiredProps = [
  "serialNumber",
  "siteName",
  "siteTimezone",
  "manufacturer",
  "equipmentModel",
  "belongingToGroup",
  "buildingLocation",
  "room",
  "floor",
  "responsiblePerson",
  "qualificationStatus"
];

export const keyValue = ["buildingLocation", "floor", "room"];
export const enumValue = ["qualificationStatus"];

export const valueArray = ["sop"]; // change to lower case
export const valueBoolean = ["isBookable", "isVisualized"];
export const instrumentIdentifiers = ["materialNumber", "serialNumber"];

export const headersTranslationMap = {
  softwareVersion: "Software version",
  belongingToGroup: DATA_MODEL_TABLE.belongingToGroup.value,
  buildingLocation: DATA_MODEL_TABLE.buildingLocation.value,
  configurationBaseline: DATA_MODEL_TABLE.configurationBaseline.value,
  dateOfLastMaintanance: DATA_MODEL_TABLE.dateOfLastMaintanance.value,
  dateOfNextMaintanance: DATA_MODEL_TABLE.dateOfNextMaintanance.value,
  room: DATA_MODEL_TABLE.room.value,
  floor: DATA_MODEL_TABLE.floor.value,
  installedTests: DATA_MODEL_TABLE.installedTests.value,
  instrumentGTIN: "Equipment GTIN",
  qualificationStatus: DATA_MODEL_TABLE.qualificationStatus.value,
  instrumentRUDI: "Equipment RUDI",
  equipmentModel: DATA_MODEL_TABLE.equipmentModel.value,
  isBookable: DATA_MODEL_TABLE.isBookable.value,
  isVisualized: DATA_MODEL_TABLE.isVisualized.value,
  materialNumber: DATA_MODEL_TABLE.materialNumber.value,
  qualificationDocuments: DATA_MODEL_TABLE.qualificationDocuments.value,
  responsiblePerson: DATA_MODEL_TABLE.responsiblePerson.value,
  serialNumber: DATA_MODEL_TABLE.serialNumber.value,
  systemStatus: DATA_MODEL_TABLE.systemStatus.value,
  siteName: DATA_MODEL_TABLE.siteName.value,
  siteTimezone: "Site timezone",
  equipmentId: DATA_MODEL_TABLE.equipmentId.value,
  manufacturer: DATA_MODEL_TABLE.manufacturer.value,
  responsibleProxy: DATA_MODEL_TABLE.responsibleProxy.value,
  systemOwner: DATA_MODEL_TABLE.systemOwner.value,
  equipmentCategory: DATA_MODEL_TABLE.equipmentCategory.value,
  comment: DATA_MODEL_TABLE.comment.value,
  sop: DATA_MODEL_TABLE.sop.value,
  csv: DATA_MODEL_TABLE.csv.value,
  electronicRecord: DATA_MODEL_TABLE.electronicRecord.value,
  electronicSignatures: DATA_MODEL_TABLE.electronicSignatures.value,
  dateOfNextPeriodicReview: "Date Of next periodic review",
  maintenancePlan: DATA_MODEL_TABLE.maintenancePlan.value,
  gxpRelevant: DATA_MODEL_TABLE.gxpRelevant.value,
  location: DATA_MODEL_TABLE.location.value,
  cluster: DATA_MODEL_TABLE.cluster.value,
  equipmentAdministrator: DATA_MODEL_TABLE.equipmentAdministrator.value
};

export const getHeaders = (arr) => {
  const availableHeaders = Object.keys(headersTranslationMap);
  return [
    ...new Set(
      arr?.map((x) => {
        const dot = x?.indexOf(".");
        return dot !== -1 ? x?.slice(0, dot) : x;
      })
    )
  ].filter((header) => availableHeaders?.includes(header));
};

export const getObjectTypesByKey = (Obj = {}) => {
  if (typeof Obj !== "object" || Obj === null) return {};
  return Object.keys(Obj)?.reduce(
    (acc, k) => ({ ...acc, [k]: typeof Obj[k] }),
    {}
  );
};

export const hasAllRequiredProperties = (requiredData, data) => {
  if (!Array.isArray(data)) return { valid: false };
  if (!Array.isArray(requiredData)) return { valid: false };
  const reqProps = intersection(data, requiredData);
  return {
    valid: reqProps.length === requiredData.length,
    data: difference(requiredData, reqProps)
  };
};

const curriedHasAllRequiredProperties = R.curry(hasAllRequiredProperties);

export const hasRequiredInstrumentProperties = curriedHasAllRequiredProperties(
  requiredInstrumentProperties
);

const getObject = (key, row, id) => {
  let value = row[id]?.trim();

  if (keyValue.includes(key)) {
    return value
      ? {
          key: value,
          value: value
        }
      : null;
  } else if (valueBoolean.includes(key)) {
    let boolValue = value?.toLowerCase();
    if (boolValue === "false") return false;
    else if (boolValue === "true") return true;

    return null;
  } else if (valueArray.includes(key)) {
    let arraySop = value ? value.split(",") : [];
    let arrayObj = [];
    arraySop.forEach((item) => {
      arrayObj.push({ key: item.toLowerCase(), value: item });
    });
    return arrayObj;
  }
  return value
    ? key === "dateOfNextPeriodicReview" ||
      key === "dateOfLastMaintanance" ||
      key === "dateOfNextMaintanance"
      ? Dateformatter(value)
      : value
    : null;
};

export const mapRow = R.curry(
  (header, availableProperties, defaultObject, row) =>
    pick(
      header?.reduce(
        (acc, k, idx) => ({
          ...acc,
          [k]: getObject(k, row, idx)
        }),
        {}
      ) ?? {},
      availableProperties
    )
);

export const curriedMapItem = R.curry((types, item) => {
  const _newObj = unflatten(item);
  Object.keys(item)?.forEach((key) => {
    if (key.endsWith("isSynchronized") || types[key] === "boolean") {
      set(_newObj, key, item[key]?.trim()?.toLowerCase() === "true");
    } else {
      set(_newObj, key, item[key]);
    }
  });
  return _newObj;
});

export const curriedMapItemInstrument = curriedMapItem(
  getObjectTypesByKey(emptyImportInstruments)
);

export const getParsedInstruments = (
  header,
  content,
  availableProperties = getAllAvailableKeys(
    emptyImportInstruments,
    omittedCSVKeys
  )
) => {
  const emptyInstrumentsFlatten = flatten(emptyImportInstruments);
  const mapRowInstrument = mapRow(
    header,
    availableProperties,
    emptyInstrumentsFlatten
  );
  return content?.reduce((acc, row) => [...acc, mapRowInstrument(row)], []);
};

export const validateValuesCurried = R.curry(
  async (validationSchema, values) => {
    const errors = {};
    for (const item of values) {
      try {
        await validationSchema?.validate(item);
      } catch (err) {
        console.log("@@@@Eeer", err);
        if (err.message in errors) {
          errors[err.message] = ++errors[err.message];
        } else {
          errors[err.message] = 1;
        }
      }
    }
    const errorMessages = Object.keys(errors);
    if (errorMessages.length) throw new InstrumentValidationError(errors);
    return true;
  }
);

const validateWithMasterData = (fieldName, equipment, masterData) => {
  let err = {};
  if (!equipment[fieldName]) {
    err.message = false;
  } else {
    switch (fieldName) {
      case "siteName":
        err.message = find(masterData.siteList, {
          siteName: equipment[fieldName]
        })
          ? false
          : "Invalid site name";
        break;
      case "manufacturer":
        err.message = find(masterData.manufacturerList, {
          value: equipment[fieldName]
        })
          ? false
          : "Invalid manufacturer data";
        break;
      case "buildingLocation":
        err.message = find(masterData.buildingDataOnSite[equipment.siteName], {
          value: equipment[fieldName]?.value
        })
          ? false
          : "Invalid building data";
        break;
      case "floor":
        err.message = find(masterData.floorDataOnSite[equipment.siteName], {
          value: equipment[fieldName]?.value
        })
          ? false
          : "Invalid floor data";
        break;
      case "room":
        err.message = find(masterData.roomDataOnSite[equipment.siteName], {
          value: equipment[fieldName]?.value
        })
          ? false
          : "Invalid room data";
        break;
      case "sop":
        equipment[fieldName].forEach((sop) => {
          err.message = find(masterData.sopList, {
            value: sop?.value
          })
            ? false
            : "Invalid sop data";
        });
        break;
      default:
    }
  }
  return err;
};

const validateData = (equipment, masterData) => {
  const errors = {};
  for (const key in equipment) {
    let err = validateWithMasterData(key, equipment, masterData);
    if (err?.message) {
      if (err.message in errors) {
        errors[err.message] = ++errors[err.message];
      } else {
        errors[err.message] = 1;
      }
    }
  }
  return errors;
};

export const validateValidData = async (equipments, masterData) => {
  let errors = [];
  let finalErrorObj = {};
  for (const equipment of equipments) {
    errors.push(validateData(equipment, masterData));
  }
  errors.forEach((error) => {
    for (const key in error) {
      if (key in finalErrorObj) {
        finalErrorObj[key] = ++finalErrorObj[key];
      } else {
        finalErrorObj[key] = 1;
      }
    }
  });
  const errorMessages = Object.keys(finalErrorObj);
  if (errorMessages.length) throw new InstrumentValidationError(finalErrorObj);
  return true;
};

export const validateInstrumentValues = validateValuesCurried(
  InstrumentValidationSchema
);

export const filterDataToImport = R.curry(
  (importData, existingRecordsIds, pickedProps, skipExising, compareProps) => {
    const containIds = R.flip(R.contains)(
      R.map(R.pick(compareProps))(existingRecordsIds)
    );
    const filterProps = R.map((item) => ({
      ...R.pick(pickedProps)(item)
    }));
    if (skipExising) {
      return R.compose(
        filterProps,
        R.filter(R.compose(R.not, containIds, R.pick(compareProps)))
      )(importData);
    }
    return filterProps(importData);
  }
);

function Dateformatter(inputDate) {
  if (!inputDate) return null;
  var date = new Date(inputDate);
  if (!isNaN(date.getTime())) {
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);

    return date.getFullYear() + "-" + month + "-" + day;
  }
}
