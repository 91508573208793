import styled from "styled-components";
import DATA_MODEL_TABLE from "../../utils/constants/dataModelTable";
import { commonPropsForInputsWithoutValue } from "../instruments/helpers";
import { GridAutocomplete } from "@digitallab/grid-common-components";
import { FormComponents } from "../instruments/addEditEquipment/AddEquipmentStyle";
import { GridAutoCompleteStyles } from "../../components/shared/GridAutoCompleteStyles";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { emptyLinkedInstanceEdit, OTHER_REASON } from "../../constants";
import { uniqList } from "../importFile/utils/helpers";
// import { isEqual } from "lodash";
import {
  OwcButton,
  OwcInput,
  OwcModalDialog,
  OwcTypography,
  OwcIcon
} from "@one/react";
import {
  LIST_IR_ENTRY_NONE_UNICITY_CHECK,
  LIST_IR_ENTRY_UNICITY_CHECK
} from "../../gql/landingapi/queries";
import { withApollo } from "react-apollo";
import { UPDATE_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY } from "../../gql/landingapi/mutations";
import Notify from "../notifications/Notify";

// const DialogTitle = withStyles(styles)((props) => {
//   const { children, classes, onClose, ...other } = props;
//   return (
//     <MuiDialogTitle disableTypography className={classes.root} {...other}>
//       <ModalTitle>{children}</ModalTitle>
//       {onClose ? (
//         <IconButton
//           aria-label="close"
//           className={classes.closeButton}
//           onClick={onClose}
//         >
//           <CloseIcon />
//         </IconButton>
//       ) : null}
//     </MuiDialogTitle>
//   );
// });

const DialogForm = styled.form`
  max-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const LinkedInstanceDialog = ({
  open,
  setOpen,
  equipmentDetail,
  reasonList,
  client,
  setRefreshFlag
}) => {
  const classes = {};
  const formik = useFormikContext();
  const listOptions = useSelector((state) => state.instruments);
  const linkedInstanceList = listOptions?.linkedInstanceList;
  const categoryMappingList = listOptions.categoryMappingMasterList;
  const [categoryList, setCategoryList] = useState(listOptions.categoryList);
  const [manufacturerList, setManufacturerList] = useState(
    listOptions.manufacturerList
  );
  const [modelList, setModelList] = useState(listOptions.modelList);
  const [requiredSecondayIdArray, setRequiredSecondayIdArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const categoryChange = (value) => {
    formik.setValues({
      ...formik.values,
      equipmentCategory: value
    });
    if (formik.values.linkedInstance?.linkedInstance !== "None") {
      const filterList = categoryMappingList.filter(
        (item) => item.equipmentCategory === value
      );
      const tempMFList = uniqList(filterList.map((x) => x.manufacturer)).sort();
      const tempMList = uniqList(
        filterList.map((x) => x.equipmentModel)
      ).sort();
      const tempVList = uniqList(
        categoryMappingList
          .filter((item) => tempMList.includes(item.equipmentModel))
          .map((x) => x.variant)
          .sort()
      );

      formik.setValues({
        ...formik.values,
        equipmentCategory: value,
        manufacturer: tempMFList.length === 1 ? tempMFList[0] : "",
        equipmentModel:
          tempMList.length === 1 && tempMFList.length === 1 ? tempMList[0] : "",
        variant:
          tempVList.length === 1 &&
          tempMList.length === 1 &&
          tempMFList.length === 1
            ? tempVList[0]
            : ""
      });

      setManufacturerList(tempMFList);
      setModelList(tempMList);
    } else {
      const tempVList = uniqList(
        categoryMappingList
          .filter(
            (item) => item.equipmentModel === formik.values?.equipmentModel
          )
          .map((x) => x.variant)
          .sort()
      );

      formik.setValues({
        ...formik.values,
        equipmentCategory: value,
        variant: tempVList.length === 1 ? tempVList[0] : ""
      });
    }
  };
  const manufacturerChange = (value) => {
    const filterList = categoryMappingList.filter(
      (item) =>
        item.manufacturer === value &&
        item.equipmentCategory === formik.values?.equipmentCategory
    );

    const tempMList = uniqList(filterList.map((x) => x.equipmentModel)).sort();
    const tempVList = uniqList(
      categoryMappingList
        .filter((item) => tempMList.includes(item.equipmentModel))
        .map((x) => x.variant)
    ).sort();

    formik.setValues({
      ...formik.values,
      manufacturer: value,
      equipmentModel: tempMList.length === 1 ? tempMList[0] : "",
      variant:
        tempVList.length === 1 && tempMList.length === 1 ? tempVList[0] : ""
    });

    setModelList(tempMList);
  };

  const modelChange = (value) => {
    const filterList = categoryMappingList.filter(
      (item) => item.equipmentModel === value
    );
    const tempVList = uniqList(filterList.map((x) => x.variant)).sort();
    formik.setValues({
      ...formik.values,
      equipmentModel: value,
      variant: tempVList.length === 1 ? tempVList[0] : ""
    });

    if (!formik.values?.manufacturer) {
      const CMFilterList = categoryMappingList.filter(
        (item) =>
          item.equipmentModel === value &&
          item.equipmentCategory === formik.values?.equipmentCategory
      );

      const tempMFList = uniqList(
        CMFilterList.map((x) => x.manufacturer)
      ).sort();

      const tempMList = uniqList(
        categoryMappingList
          .filter((item) => tempMFList.includes(item.manufacturer))
          .map((x) => x.equipmentModel)
      ).sort();

      if (tempMFList.length === 1) {
        formik.setValues({
          ...formik.values,
          equipmentModel: value,
          variant: tempVList.length === 1 ? tempVList[0] : "",
          manufacturer: tempMFList[0]
        });
      }
      setModelList(tempMList);
      setManufacturerList(tempMFList);
    }
  };
  const onCancel = () => {
    formik.resetForm();
    setOpen(false);
  };
  useEffect(() => {
    const currentEquipmentObj = { ...equipmentDetail };
    if (
      currentEquipmentObj?.linkedInstance &&
      currentEquipmentObj?.linkedInstance?.secondaryIdMapping
    ) {
      const tempArray = currentEquipmentObj?.linkedInstance?.secondaryIdMapping
        .split(";")
        .map((x) => x.trim());

      setRequiredSecondayIdArray(tempArray);
    }

    formik.setValues({
      ...formik.values,
      ...currentEquipmentObj
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentDetail]);
  useEffect(() => {
    const tempList = categoryMappingList.filter((item) => {
      return (
        item.equipmentModel === formik.values.equipmentModel &&
        item.equipmentCategory === formik.values.equipmentCategory &&
        item.manufacturer === formik.values.manufacturer
      );
    });
    if (tempList.length === 1) {
      formik.values.displayImage = tempList[0].displayImage;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.equipmentModel]);
  useEffect(() => {
    const filterList = categoryMappingList.filter(
      (item) => item.equipmentCategory === equipmentDetail?.equipmentCategory
    );
    const tempMFList = uniqList(filterList.map((x) => x.manufacturer)).sort();
    const tempMList = uniqList(filterList.map((x) => x.equipmentModel)).sort();

    setManufacturerList(tempMFList);
    setModelList(tempMList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formik.values.linkedInstance?.linkedInstance === "None") {
      const filterList = categoryMappingList.filter(
        (item) =>
          item.manufacturer === formik.values?.manufacturer &&
          item.equipmentModel === formik.values?.equipmentModel
      );

      const tempCList = uniqList(
        filterList.map((x) => x.equipmentCategory)
      ).sort();

      const tempVList = uniqList(
        categoryMappingList
          .filter(
            (item) => item.equipmentModel === formik.values?.equipmentModel
          )
          .map((x) => x.variant)
          .sort()
      );

      if (!formik.values?.equipmentCategory) {
        formik.setFieldValue("equipmentCategory", "");
        if (tempCList.length === 1) {
          formik.setFieldValue("equipmentCategory", tempCList[0]);
        }
      }
      if (!formik.values?.variant) {
        formik.setFieldValue("variant", "");
        if (tempVList.length === 1 && tempCList.length === 1) {
          formik.setFieldValue("variant", tempVList[0]);
        }
      }
      setCategoryList(tempCList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.linkedInstance?.linkedInstance]);

  const checkUnicityForEquipmentOnAdd = async (formData) => {
    try {
      if (formData?.linkedInstance?.linkedInstance === "None") {
        const manufacturer = formData?.manufacturer ?? null;
        const equipmentModel = formData?.equipmentModel ?? null;
        const serialNumber = formData?.serialNumber ?? null;

        const result = await client.query({
          query: LIST_IR_ENTRY_NONE_UNICITY_CHECK,
          fetchPolicy: "no-cache",
          variables: {
            manufacturer,
            equipmentModel,
            serialNumber
          }
        });
        const unicityRecord =
          result?.data?.listIREntryByManufacturerModelAndSNo?.items;
        return unicityRecord ?? [];
      } else {
        setIsLoading(true);
        const selected = linkedInstanceList.find(
          (x) => x.linkedInstance === formData?.linkedInstance?.linkedInstance
        );
        if (selected !== undefined) {
          const tempArray = selected?.secondaryIdMapping
            .split(";")
            .map((x) => x.trim());

          const secondaryIDArray = tempArray.map(
            (x) => formData[x] ?? emptyLinkedInstanceEdit[x]
          );
          const secondaryID = secondaryIDArray.join(";");
          const result = await client.query({
            query: LIST_IR_ENTRY_UNICITY_CHECK,
            fetchPolicy: "no-cache",
            variables: {
              linkedInstance: formData?.linkedInstance?.linkedInstance,
              secondaryID
            }
          });
          const unicityRecord =
            result?.data?.listIREntryByLinkedInstanceAndSecondaryID?.items;
          return unicityRecord ?? [];
        } else {
          Notify({
            type: "warning",
            icon: "caution",
            appName: "",
            text: "Try again later!."
          });
        }
      }
    } catch (err) {
      console.log("unicity check error", err);
    } finally {
      setIsLoading(false);
    }
  };

  const getSecondaryId = (data) => {
    let obj = {};
    let secondaryIdValues = [];
    let secondaryIdFields =
      data?.linkedInstance?.secondaryIdMapping?.split(";");

    secondaryIdFields?.forEach((field) => {
      secondaryIdValues?.push(data[field?.trim()]);
    });

    obj["secondaryIDMapping"] = data?.linkedInstance?.secondaryIdMapping;
    obj["secondaryID"] = secondaryIdValues?.join("; ");

    return obj;
  };

  const updateEquipment = async () => {
    let data = formik.values;
    const unicityResponse = await checkUnicityForEquipmentOnAdd(data);
    let filterResult = [];
    if (unicityResponse.length > 0) {
      filterResult = unicityResponse.filter((x) => x.id !== data.id);
    }
    const secondaryIdObj = getSecondaryId(data);
    const instrumentDetail = {
      ...data,
      linkedInstance: data?.linkedInstance?.linkedInstance,
      ...secondaryIdObj
    };

    if (filterResult.length < 1) {
      await client.mutate({
        mutation: UPDATE_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY,
        variables: {
          instrument: instrumentDetail
        },
        fetchPolicy: "no-cache"
      });
      Notify({
        type: "success",
        icon: "yes",
        appName: "",
        text: "Update Successfully."
      });
      setOpen(false);
      setRefreshFlag((prev) => !prev);
      formik.resetForm();
    } else {
      Notify({
        type: "warning",
        icon: "caution",
        appName: "",
        text: "This equipment already exists."
      });
    }
  };
  return (
    <OwcModalDialog
      visible={open}
      data-testid="modal-for-instrument-editing"
      size="lg"
      className="owcmodalZIndex"
    >
      <DialogForm data-testid="instrument-repositorium-modal-form" noValidate>
        <div
          slot="header"
          data-testid="instrument-repositorium-modal-title"
          style={{ padding: "10px 10px 20px 0" }}
        >
          Edit Linked instance and Secondary ID(s)
        </div>
        <div data-testid="instrument-repositorium-modal-content">
          <FormComponents>
            <div style={{ marginBottom: 36, display: "flex" }}>
              <>
                <GridAutocomplete
                  style={{ width: 299, marginRight: 8 }}
                  id={DATA_MODEL_TABLE.linkedInstance.key}
                  size="small"
                  sx={GridAutoCompleteStyles?.gridAutoCompleteLableShrink}
                  dataTestid={`autocomplete-${DATA_MODEL_TABLE.linkedInstance.key}`}
                  optionsList={
                    formik.values?.linkedInstance?.linkedInstance !== "None"
                      ? linkedInstanceList?.filter(
                          (item) => item?.linkedInstanceDisplay !== "None"
                        )
                      : linkedInstanceList
                  }
                  propLabel="linkedInstanceDisplay"
                  propValue="linkedInstanceDisplay"
                  label={DATA_MODEL_TABLE.linkedInstance.value}
                  value={
                    formik.values[DATA_MODEL_TABLE.linkedInstance.key] || null
                  }
                  key={formik.values[DATA_MODEL_TABLE.linkedInstance.key]}
                  onAutoCompleteChange={(selectedLinkedInstance) => {
                    if (selectedLinkedInstance) {
                      const tempArray =
                        selectedLinkedInstance?.secondaryIdMapping
                          .split(";")
                          .map((x) => x.trim());
                      setRequiredSecondayIdArray(tempArray);
                    }
                    if (
                      selectedLinkedInstance?.linkedInstance === "None" &&
                      equipmentDetail?.linkedInstance?.linkedInstance !== "None"
                    ) {
                      Notify({
                        type: "warning",
                        icon: "caution",
                        appName: "",
                        text: "You can't select None."
                      });
                    }

                    formik.setFieldValue(
                      DATA_MODEL_TABLE.linkedInstance.key,
                      selectedLinkedInstance
                    );
                  }}
                  onOpen={() => {}}
                  loading={false}
                  name={DATA_MODEL_TABLE.linkedInstance.key}
                  error={
                    formik.touched[DATA_MODEL_TABLE.linkedInstance.key] &&
                    Boolean(formik.errors[DATA_MODEL_TABLE.linkedInstance.key])
                  }
                  helperText={
                    formik.touched[DATA_MODEL_TABLE.linkedInstance.key] &&
                    formik.errors[DATA_MODEL_TABLE.linkedInstance.key]
                  }
                  onBlur={formik.handleBlur}
                  required={true}
                  disableClearable
                />
              </>

              <OwcInput
                style={{ width: 299, marginRight: 8 }}
                data-testid={`text-field-for-serial-number`}
                id={`text-field-for-serial-number`}
                label={
                  requiredSecondayIdArray.includes(
                    DATA_MODEL_TABLE.serialNumber.key
                  )
                    ? "Serial number *"
                    : DATA_MODEL_TABLE.serialNumber.value
                }
                name={DATA_MODEL_TABLE.serialNumber.key}
                value={formik.values?.serialNumber}
                onInputChange={formik.handleChange}
                onBlur={formik.handleBlur}
                {...commonPropsForInputsWithoutValue(
                  classes,
                  formik,
                  "serialNumber",
                  "serialNumber",
                  "text-field",
                  false,
                  false
                )}
                required={requiredSecondayIdArray.includes(
                  DATA_MODEL_TABLE.serialNumber.key
                )}
                helperText={
                  requiredSecondayIdArray.includes(
                    DATA_MODEL_TABLE.serialNumber.key
                  ) && "Relevant for Secondary ID"
                }
              >
                <>
                  <span
                    // id={generateID.UUID(adminTopBannerMessage, `topBannerMessage`, "error_slot")}
                    slot="error-text"
                  >
                    {requiredSecondayIdArray.includes(
                      DATA_MODEL_TABLE.serialNumber.key
                    ) && "Relevant for Secondary ID"}
                  </span>
                </>

                {formik.values[DATA_MODEL_TABLE.serialNumber.key] !== "" && (
                  <OwcIcon
                    // id={generateID.UUID(
                    //   adminTopBannerMessage,
                    //   `topBannerMessage`,
                    //   "icon_clear_circle"
                    // )}
                    name="circle_clear_filled"
                    slot="suffix"
                    type="legacy"
                    onClick={() =>
                      formik.setFieldValue(
                        DATA_MODEL_TABLE.serialNumber.key,
                        "",
                        true
                      )
                    }
                  />
                )}
              </OwcInput>
              <OwcInput
                style={{ width: 299 }}
                data-testid={`text-field-for-equipment-administrator`}
                id={`text-field-for-equipment-administrator`}
                label={
                  requiredSecondayIdArray.includes(
                    DATA_MODEL_TABLE.equipmentId.key
                  )
                    ? "Equipment ID *"
                    : DATA_MODEL_TABLE.equipmentId.value
                }
                name="equipmentId"
                value={formik.values?.equipmentId}
                onInputChange={formik.handleChange}
                onBlur={formik.handleBlur}
                {...commonPropsForInputsWithoutValue(
                  classes,
                  formik,
                  "equipmentId",
                  "equipmentId",
                  "text-field",
                  false,
                  false
                )}
                required={requiredSecondayIdArray.includes(
                  DATA_MODEL_TABLE.equipmentId.key
                )}
              >
                <>
                  <span
                    // id={generateID.UUID(adminTopBannerMessage, `topBannerMessage`, "error_slot")}
                    slot="error-text"
                  >
                    {requiredSecondayIdArray.includes(
                      DATA_MODEL_TABLE.equipmentId.key
                    ) && "Relevant for Secondary ID"}
                  </span>
                </>

                {formik.values["equipmentId"] !== "" && (
                  <OwcIcon
                    // id={generateID.UUID(
                    //   adminTopBannerMessage,
                    //   `topBannerMessage`,
                    //   "icon_clear_circle"
                    // )}
                    name="circle_clear_filled"
                    slot="suffix"
                    type="legacy"
                    onClick={() =>
                      formik.setFieldValue("equipmentId", "", true)
                    }
                  />
                )}
              </OwcInput>
            </div>
            <div style={{ marginBottom: 36, display: "flex" }}>
              <OwcInput
                data-testid={`text-field-for-equipment-administrator`}
                id={`text-field-for-equipment-administrator`}
                label={
                  requiredSecondayIdArray.includes(
                    DATA_MODEL_TABLE.materialNumber.key
                  )
                    ? "Material number *"
                    : DATA_MODEL_TABLE.materialNumber.value
                }
                style={{ width: 222, marginRight: 8 }}
                name="materialNumber"
                value={formik.values?.materialNumber}
                onInputChange={formik.handleChange}
                onBlur={formik.handleBlur}
                {...commonPropsForInputsWithoutValue(
                  classes,
                  formik,
                  "materialNumber",
                  "materialNumber",
                  "text-field",
                  false,
                  false
                )}
                required={requiredSecondayIdArray.includes(
                  DATA_MODEL_TABLE.materialNumber.key
                )}
              >
                <>
                  <span
                    // id={generateID.UUID(adminTopBannerMessage, `topBannerMessage`, "error_slot")}
                    slot="error-text"
                  >
                    {requiredSecondayIdArray.includes(
                      DATA_MODEL_TABLE.materialNumber.key
                    ) && "Relevant for Secondary ID"}
                  </span>
                </>

                {formik.values["materialNumber"] !== "" && (
                  <OwcIcon
                    // id={generateID.UUID(
                    //   adminTopBannerMessage,
                    //   `topBannerMessage`,
                    //   "icon_clear_circle"
                    // )}
                    name="circle_clear_filled"
                    slot="suffix"
                    type="legacy"
                    onClick={() =>
                      formik.setFieldValue("materialNumber", "", true)
                    }
                  />
                )}
              </OwcInput>
              <>
                <GridAutocomplete
                  style={{ width: 222, marginRight: 8 }}
                  className="selectBox"
                  id={DATA_MODEL_TABLE.equipmentCategory.key}
                  size="small"
                  sx={GridAutoCompleteStyles?.gridAutoCompleteLableShrink}
                  dataTestid={`autocomplete-${DATA_MODEL_TABLE.equipmentCategory.key}`}
                  optionsList={categoryList}
                  label={DATA_MODEL_TABLE.equipmentCategory.value}
                  value={formik.values[DATA_MODEL_TABLE.equipmentCategory.key]}
                  key={formik.values[DATA_MODEL_TABLE.equipmentCategory.key]}
                  onAutoCompleteChange={(selectedValue) => {
                    categoryChange(selectedValue);
                  }}
                  name={DATA_MODEL_TABLE.equipmentCategory.key}
                  error={
                    formik.touched[DATA_MODEL_TABLE.equipmentCategory.key] &&
                    Boolean(
                      formik.errors[DATA_MODEL_TABLE.equipmentCategory.key]
                    )
                  }
                  helperText={
                    formik.touched[DATA_MODEL_TABLE.equipmentCategory.key] &&
                    formik.errors[DATA_MODEL_TABLE.equipmentCategory.key]
                  }
                  onBlur={formik.handleBlur}
                  required={true}
                  disableClearable
                />
              </>
              <>
                <GridAutocomplete
                  style={{ width: 222, marginRight: 8 }}
                  className="selectBox"
                  id={DATA_MODEL_TABLE.manufacturer.key}
                  size="small"
                  sx={GridAutoCompleteStyles?.gridAutoCompleteLableShrink}
                  dataTestid={`autocomplete-${DATA_MODEL_TABLE.manufacturer.key}`}
                  optionsList={manufacturerList}
                  label={DATA_MODEL_TABLE.manufacturer.value}
                  value={formik.values[DATA_MODEL_TABLE.manufacturer.key]}
                  key={formik.values[DATA_MODEL_TABLE.manufacturer.key]}
                  onAutoCompleteChange={(selectedValue) => {
                    manufacturerChange(selectedValue);
                  }}
                  name={DATA_MODEL_TABLE.manufacturer.key}
                  error={
                    formik.touched[DATA_MODEL_TABLE.manufacturer.key] &&
                    Boolean(formik.errors[DATA_MODEL_TABLE.manufacturer.key])
                  }
                  onBlur={formik.handleBlur}
                  disableClearable
                  required={requiredSecondayIdArray.includes(
                    DATA_MODEL_TABLE.manufacturer.key
                  )}
                  helperText={
                    requiredSecondayIdArray.includes(
                      DATA_MODEL_TABLE.manufacturer.key
                    ) && "Relevant for Secondary ID"
                  }
                />
              </>
              <>
                <GridAutocomplete
                  style={{ width: 222, marginRight: 8 }}
                  className="selectBox"
                  id={DATA_MODEL_TABLE.equipmentModel.key}
                  size="small"
                  sx={GridAutoCompleteStyles?.gridAutoCompleteLableShrink}
                  dataTestid={`autocomplete-${DATA_MODEL_TABLE.equipmentModel.key}`}
                  optionsList={modelList}
                  label={DATA_MODEL_TABLE.equipmentModel.value}
                  value={formik.values[DATA_MODEL_TABLE.equipmentModel.key]}
                  key={formik.values[DATA_MODEL_TABLE.equipmentModel.key]}
                  onAutoCompleteChange={(selectedValue) => {
                    modelChange(selectedValue);
                  }}
                  name={DATA_MODEL_TABLE.equipmentModel.key}
                  error={
                    formik.touched[DATA_MODEL_TABLE.equipmentModel.key] &&
                    Boolean(formik.errors[DATA_MODEL_TABLE.equipmentModel.key])
                  }
                  onBlur={formik.handleBlur}
                  required={requiredSecondayIdArray.includes(
                    DATA_MODEL_TABLE.equipmentModel.key
                  )}
                  helperText={
                    requiredSecondayIdArray.includes(
                      DATA_MODEL_TABLE.equipmentModel.key
                    ) && "Relevant for Secondary ID"
                  }
                  disableClearable
                />
              </>
            </div>
          </FormComponents>
          <OwcTypography variant="subtitle2" style={{ marginBottom: 26 }}>
            Please select and edit reason
          </OwcTypography>
          <FormComponents>
            <>
              <GridAutocomplete
                style={{ width: 332, marginRight: 8 }}
                id={DATA_MODEL_TABLE.editReason.key}
                size="small"
                sx={GridAutoCompleteStyles?.gridAutoCompleteLableShrink}
                dataTestid={`autocomplete-${DATA_MODEL_TABLE.editReason.key}`}
                optionsList={reasonList}
                propValue="value"
                propLabel="key"
                value={formik?.values[DATA_MODEL_TABLE.editReason.key]}
                label={DATA_MODEL_TABLE.editReason.value}
                onAutoCompleteChange={(selectedValue) => {
                  if (selectedValue !== null) {
                    formik.setFieldValue(
                      DATA_MODEL_TABLE.editReason.key,
                      selectedValue?.value
                    );
                  }
                }}
                name={DATA_MODEL_TABLE.editReason.key}
                error={
                  formik.touched[DATA_MODEL_TABLE.editReason.key] &&
                  Boolean(formik.errors[DATA_MODEL_TABLE.editReason.key])
                }
                helperText={
                  formik.touched[DATA_MODEL_TABLE.editReason.key] &&
                  formik.errors[DATA_MODEL_TABLE.editReason.key]
                }
                onBlur={formik.handleBlur}
                required={true}
                disableClearable
              />
            </>

            <OwcInput
              style={{ width: 575 }}
              formik={formik}
              data-testid={`textarea-field-edit-comment`}
              label={DATA_MODEL_TABLE.comment.value}
              id={DATA_MODEL_TABLE.editComment.key}
              name={DATA_MODEL_TABLE.editComment.key}
              error={
                formik?.values[DATA_MODEL_TABLE.editReason.key] ===
                  OTHER_REASON && formik.values.editComment === ""
              }
              required={
                formik?.values[DATA_MODEL_TABLE.editReason.key] === OTHER_REASON
              }
              value={formik.values?.editComment}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              inputProps={{
                maxLength: 80
              }}
              multiline
              minRows={2}
            />
          </FormComponents>
        </div>

        <div
          slot="actions"
          data-testid="instrument-repositorium-modal-action-buttons"
          style={{
            display: "flex",
            marginLeft: "auto",
            padding: "15px 10px 10px 0"
          }}
        >
          <OwcButton
            data-testid="instrument-repositorium-modal-action-button-cancel"
            variant="secondary"
            onClick={onCancel}
            style={{ marginRight: 32 }}
          >
            Cancel
          </OwcButton>
          <OwcButton
            data-testid="instrument-repositorium-modal-action-button-confirm"
            autoFocus
            variant="primary"
            onClick={() => {
              if (
                formik?.values?.linkedInstance?.linkedInstance === "None" &&
                equipmentDetail?.linkedInstance?.linkedInstance !== "None"
              ) {
                Notify({
                  type: "warning",
                  icon: "caution",
                  appName: "",
                  text: "You can't select None."
                });
              } else {
                updateEquipment();
              }
            }}
            disabled={!formik.isValid || isLoading}
          >
            Save
          </OwcButton>
        </div>
      </DialogForm>
    </OwcModalDialog>
  );
};

export default withApollo(LinkedInstanceDialog);
