import React, { useState, useEffect } from "react";
import { CoverMenuItem } from "../addEditEquipment/AddEquipmentStyle";
import {
  EQUIPMENT_REVIEW_ACTIONS,
  entryType,
  equipmentStatus
} from "../../../constants";
import { OwcIconButton, OwcListItem, OwcMenu } from "@one/react";
import ReactDOM from "react-dom";
import { v4 } from "uuid";

const EquipmentAction = ({
  params,
  editEquipment,
  loadAuditTrailList,
  deleteEquipment,
  actionStatus,
  groups,
  fullUserID,
  onReviewOrRevert
}) => {
  const [anchorEl, setAnchorEl] = useState(false);
  const [hasPermission, setHasPermission] = useState(
    actionStatus?.hasRole === "Admin"
  );
  const handleSettingClose = () => {
    setAnchorEl(false);
  };
  const handleSettingClick = (event, param) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (actionStatus.hasRole === "User") {
      groups.forEach((x) => {
        if (
          params?.data?.id[0] !== "dummyRow" &&
          params?.data["belonging_to_group"][0].startsWith(x.groupName)
        ) {
          setHasPermission(true);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setHasPermission]);
  const menuId = v4();

  return (
    <>
      {params?.data?.id && params?.data?.id[0] !== "dummyRow" && (
        <>
          <OwcIconButton
            icon="more_vertical"
            type="legacy"
            flat
            onClick={(event) => {
              handleSettingClick(event, params);
            }}
            id={menuId}
          />
          {ReactDOM.createPortal(
            <OwcMenu
              data-testid="simple-menu"
              anchor={menuId}
              visible={anchorEl}
              onClickOutside={handleSettingClose}
              disablePortal={true}
            >
              {params?.data?.status[0] !== equipmentStatus?.pending?.key && (
                <OwcListItem
                  data-testid="simple-menu-filter"
                  onClick={() => {
                    setAnchorEl(null);
                    editEquipment({
                      id: params?.data?.id[0],
                      entryType: params?.data?.entry_type[0]
                    });
                  }}
                  disabled={
                    !(
                      actionStatus.showEdit &&
                      hasPermission &&
                      params?.data?.status &&
                      params?.data?.status?.length > 0 &&
                      params?.data?.status[0] === equipmentStatus?.active?.key
                    )
                  }
                >
                  Edit{" "}
                  {params?.data?.entry_type &&
                  params?.data?.entry_type?.length > 0 &&
                  params?.data?.entry_type[0] === entryType?.cluster
                    ? "cluster"
                    : "equipment"}
                </OwcListItem>
              )}

              <CoverMenuItem />
              {params?.data?.status[0] !== equipmentStatus?.pending?.key &&
                params?.data?.entry_type &&
                params?.data?.entry_type?.length > 0 &&
                params?.data?.entry_type[0] !==
                  entryType?.clusterSubequipment &&
                !params?.data?.cluster_id && (
                  <OwcListItem
                    data-testid="simple-menu-filter"
                    onClick={() => {
                      setAnchorEl(null);
                      deleteEquipment({
                        id: params?.data?.id[0]
                      });
                    }}
                    disabled={
                      params?.data?.status &&
                      params?.data?.status?.length > 0 &&
                      params?.data?.status[0] === equipmentStatus?.pending?.key
                        ? params?.data?.status[0] ===
                          equipmentStatus?.pending?.key
                        : params?.data?.status[0] ===
                          equipmentStatus?.deleted?.key
                        ? !(actionStatus.showRestore && hasPermission) ||
                          (params?.data?.entry_type &&
                            params?.data?.entry_type?.length > 0 &&
                            params?.data?.entry_type[0] === entryType?.cluster)
                        : !(actionStatus.showDelete && hasPermission)
                    }
                  >
                    {params?.data?.status &&
                    params?.data?.status?.length > 0 &&
                    params?.data?.status[0] === equipmentStatus?.deleted?.key
                      ? "Restore"
                      : "Remove"}{" "}
                    {params?.data?.entry_type &&
                    params?.data?.entry_type?.length > 0 &&
                    params?.data?.entry_type[0] === entryType?.cluster
                      ? "cluster"
                      : "equipment"}
                  </OwcListItem>
                )}

              <CoverMenuItem />
              {params?.data?.status[0] === equipmentStatus?.pending?.key &&
                params?.data?.reviewers &&
                params?.data?.reviewers?.includes(fullUserID) && (
                  <OwcListItem
                    data-testid="simple-menu-filter"
                    onClick={() => {
                      setAnchorEl(null);
                      onReviewOrRevert(
                        EQUIPMENT_REVIEW_ACTIONS?.review?.value,
                        params?.data?.id[0]
                      );
                    }}
                  >
                    Review{" "}
                  </OwcListItem>
                )}
              {hasPermission &&
                params?.data?.status[0] === equipmentStatus?.pending?.key &&
                hasPermission && (
                  <OwcListItem
                    data-testid="simple-menu-filter"
                    onClick={() => {
                      setAnchorEl(null);
                      onReviewOrRevert(
                        EQUIPMENT_REVIEW_ACTIONS?.revert?.value,
                        params?.data?.id[0]
                      );
                    }}
                  >
                    Revert changes{" "}
                  </OwcListItem>
                )}

              <OwcListItem
                data-testid="simple-menu-filter"
                onClick={() => {
                  setAnchorEl(null);
                  loadAuditTrailList({ id: params?.data?.id[0] });
                }}
              >
                Audit trail of{" "}
                {params?.data?.entry_type &&
                params?.data?.entry_type?.length > 0 &&
                params?.data?.entry_type[0] === entryType?.cluster
                  ? "cluster"
                  : "equipment"}
              </OwcListItem>
            </OwcMenu>,
            document.body
          )}
        </>
      )}
    </>
  );
};

export default EquipmentAction;
