import styled, { css } from "styled-components";
import DetailsCells from "../../../components/shared/details-cells/DetailsCells";
import { DetailsCellStyled } from "../../../components/shared/details-cells/DetailsCellStyled";
import ClusterDetailsCells from "./ClusterDetailsCells";
import {
  AUDIT_ACTIONS,
  AUDIT_CLUSTER_ACTION,
  entryType
} from "../../../constants";

export const Frame = styled.div`
  color: #000000;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px 20px;
`;
const FrameWithBackground = styled(Frame)`
  background-color: var(--one-color-gray-50);
  // border: 1px solid #d3d3d3;
  border-radius: 4px;
  padding: 20px 16px;
`;
const DetailsCellWithFullRowStyled = styled(DetailsCellStyled)`
  overflow: hidden;
  ${(props) =>
    props.fullRow &&
    css`
      grid-column: 1 / -1;
    `}
`;

const AuditItemSingleFrame = ({
  isAuditTrial = false,
  history,
  CellsInfoArray,
  withBackground = true,
  ownWrapper,
  auditAction,
  relevantPositionState = false,
  proposedChanges,
  proposedChangesValues,
  newData = null
}) => {
  const isEquipment = newData
    ? newData?.linkedInstance !== entryType?.cluster
    : true;
  switch (auditAction) {
    case AUDIT_ACTIONS.CLUSTER_CREATE:
      return (
        <ClusterDetailsCells
          messageText="Cluster commissioned with sub-component configuration"
          items={history?.addedSubEquipments}
          type={AUDIT_CLUSTER_ACTION?.cluster}
          relevantPositionState={relevantPositionState}
          isEquipment={isEquipment}
        />
      );

    case AUDIT_ACTIONS.CLUSTER_DELETE:
      return (
        <ClusterDetailsCells
          messageText="Cluster decommissioned with sub-component configuration"
          items={history?.removedSubEquipments}
          type={AUDIT_CLUSTER_ACTION?.cluster}
          relevantPositionState={relevantPositionState}
          isEquipment={isEquipment}
        />
      );

    case AUDIT_ACTIONS.ADD_TO_CLUSTER:
      return (
        <ClusterDetailsCells
          items={
            history?.clusterInfo
              ? history?.clusterInfo?.newvalue
              : history?.cluster?.newvalue
          }
          type={AUDIT_CLUSTER_ACTION?.subEquipmentAdd}
          isEquipment={isEquipment}
        />
      );
    case AUDIT_ACTIONS.REMOVE_FROM_CLUSTER:
      return (
        <ClusterDetailsCells
          items={
            history?.clusterInfo
              ? history?.clusterInfo?.newvalue
              : history?.cluster?.newvalue
          }
          type={AUDIT_CLUSTER_ACTION?.subEquipmentRemove}
          isEquipment={isEquipment}
        />
      );
    default:
      return (
        <DetailsCells
          isAuditTrial={isAuditTrial}
          item={history}
          Wrapper={
            ownWrapper
              ? ownWrapper
              : withBackground
              ? FrameWithBackground
              : Frame
          }
          CellWrapper={DetailsCellWithFullRowStyled}
          infoMeta={{ fields: CellsInfoArray }}
          relevantPositionState={relevantPositionState}
          auditAction={auditAction}
          proposedChanges={proposedChanges}
          proposedChangesValues={proposedChangesValues}
        />
      );
  }
};

export default AuditItemSingleFrame;
