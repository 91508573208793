import React, { useCallback, useEffect } from "react";
import styled from "styled-components";

const FileSelectStyled = styled.div`
  position: relative;

  & [type="file"] {
    visibility: hidden;
    width: 0 !important;
    height: 0 !important;
    overflow: hidden;
    position: absolute;
  }
  &,
  & .wrap {
    margin: 0;
    padding: 0;
    &:hover {
      cursor: pointer;
    }
  }
`;

const FileSelect = ({
  onFileSelect,
  onFailedRequirements,
  renderContent,
  renderClear,
  fileValidate,
  uploadProps = {},
  accept = ".xlsx, .xls, .csv",
  disableInput = false,
  shouldClear = false
}) => {
  const fileInput = React.useRef(null);
  const handleFileInput = useCallback(
    async (e) => {
      try {
        const file = e.target.files[0];
        if(file !== undefined){
          if (typeof fileValidate === "function") {
            await fileValidate(file);
          }
          onFileSelect(e.target.files[0]);
        }  
      } catch (err) {
        if (typeof onFailedRequirements === "function")
          onFailedRequirements(err);
      }
    },
    [onFileSelect, onFailedRequirements, fileValidate]
  );
  const handleClear = useCallback(() => {
    if (fileInput.current) fileInput.current.value = null;
  }, []);
  const clickHandler = useCallback(
    () => fileInput.current && fileInput.current.click(),
    []
  );
  useEffect(() => {
    if (shouldClear) {
      handleClear();
    }
  }, [shouldClear, handleClear]);
  return (
    <FileSelectStyled data-testid="file-uploader" {...uploadProps}>
      <input
        type="file"
        ref={fileInput}
        onChange={handleFileInput}
        accept={accept}
        data-testid="file-input"
        disabled={disableInput}
      />
      <div
        data-testid="file-input-click"
        className="wrap"
        onClick={clickHandler}
      >
        {renderContent && renderContent()}
      </div>
      {renderClear && renderClear({ clear: handleClear })}
    </FileSelectStyled>
  );
};

export default FileSelect;
