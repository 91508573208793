import React from "react";
import { utcStringToLocaleString } from "../instruments/helpers";

const DateAndTimeField = ({ item }) => {
  return (
    <div data-testid="date-time-field">{`${utcStringToLocaleString(
      item?.uploadDate
    )}`}</div>
  );
};

export default DateAndTimeField;
