import React, { useState } from "react";
import DATA_LABEL_TABLE from "../../../src/utils/constants/dataLabelTable";
import ReasonMainPage from "../reasons/ReasonMainPage";
import GroupMainPage from "../groups/GroupMainPage";
import { OwcTab, OwcTabs } from "@one/react";
import LinkedInstanceMainPage from "../linked-instance/LinkedInstanceMainPage";
import MasterDataMainPage from "../master-data-management/MasterDataMainPage";

const AdminTabs = () => {
  const [value, setValue] = useState("groupAccess");
  const handleChange = (e) => {
    setValue(e.detail);
  };

  return (
    <div style={{ background: "var(--one-color-cobas-white)" }}>
      <OwcTabs
        data-testid="admin-tabs"
        value={value}
        onTabChange={handleChange}
      >
        <OwcTab
          data-testid="admin-tab-drop-booking"
          label={DATA_LABEL_TABLE.ADMIN_TABS.group_access}
          value="groupAccess"
        >
          {DATA_LABEL_TABLE.ADMIN_TABS.group_access}
        </OwcTab>
        <OwcTab
          data-testid="admin-tab-reason-management"
          label={DATA_LABEL_TABLE.ADMIN_TABS.manage_reasons}
          value="manageReasons"
        >
          {DATA_LABEL_TABLE.ADMIN_TABS.manage_reasons}
        </OwcTab>
        <OwcTab
          data-testid="admin-tab-linked-instance"
          label={DATA_LABEL_TABLE.ADMIN_TABS.linked_instance}
          value="linkedInstance"
        >
          {DATA_LABEL_TABLE.ADMIN_TABS.linked_instance}
        </OwcTab>
        <OwcTab
          data-testid="admin-tab-master-data"
          label={DATA_LABEL_TABLE.ADMIN_TABS.master_data}
          value="masterData"
        >
          {DATA_LABEL_TABLE.ADMIN_TABS.master_data}
        </OwcTab>
      </OwcTabs>
      {value === "groupAccess" && <GroupMainPage />}
      {value === "manageReasons" && <ReasonMainPage />}
      {value === "linkedInstance" && <LinkedInstanceMainPage />}
      {value === "masterData" && <MasterDataMainPage />}
    </div>
  );
};

export default AdminTabs;
