import { OwcButton } from "@one/react";
import { useContext } from "react";
import { MasterDataContext } from "../../master-data/MasterDataContext";

export const UploadButton = () => {
  const { isActionButtonDisabled } = useContext(MasterDataContext);
  return (
    <OwcButton
      data-testid="upload-csv-master-data"
      variant="primary"
      style={{ textTransform: "none", height: "50px", marginRight: "10px" }}
      disabled={isActionButtonDisabled}
    >
      Import CSV
    </OwcButton>
  );
};
