import React from "react";
import styled from "styled-components";
import { OwcButton } from "@one/react";
import Notify from "../../notifications/Notify";
import { Button } from "@mui/material";

const ImportButtonStyled = styled.div`
  && {
    text-transform: none;
    background-color: var(--one-color-cobas-white);
    color: var(--one-color-background-brand-1);
    font-weight: 500;
    border: thin solid var(--one-color-gray-300);
  }
`;
const IconStyle = styled(Button)`
  && {
    text-transform: none;
    padding: 0px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
`;

const ImportButton = () => {
  return (
    <OwcButton
      data-testid="confirm-dialog-actions-button-approve"
      variant="primary"
    >
      Import CSV
    </OwcButton>
  );
};

export const ImportExampleDownload = ({ status }) => {
  const handleerr = (event) => {
    try {
      Notify({
        type: "success",
        icon: "circle_confirm",
        appName: "",
        text: `Template downloaded successfully.`
      });
    } catch (err) {
      console.warn(err);
      return null;
    }
  };
  return (
    <IconStyle
      data-testid="import-example-instrument"
      component="a"
      href={"/files/Equipment_Data_Upload_Template.csv"}
      download="Equipment_Data_Upload_Template.csv"
      onClick={handleerr}
    >
      Download template
    </IconStyle>
  );
};

export const DisableImportButton = () => {
  return (
    <ImportButtonStyled
      data-testid="disable-import-instrument-file-select"
      variant="primary"
      disabled={true}
    >
      Import CSV
    </ImportButtonStyled>
  );
};

export default ImportButton;
