import { Paper, Tab, Tabs } from "@mui/material";
import React from "react";
import DATA_MODEL_TABLE from "../../utils/constants/dataModelTable";

const TabsForModalEditInstrument = ({ choosenTab, setChoosenTab }) => {
  const handleChange = (event, newValue) => {
    setChoosenTab(newValue);
  };
  return (
    <Paper>
      <Tabs
        variant="fullWidth"
        value={choosenTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
        data-testid="tabs-for-modal-edit-instrument"
      >
        <Tab
          value="basicData"
          label="Basic data"
          data-testid="tabs-for-modal-edit-tab-basic-data"
        />
        <Tab
          label={DATA_MODEL_TABLE.installedTests.value}
          value="assays"
          data-testid="tabs-for-modal-edit-tab-installed-tests"
        />
        <Tab
          label="Documents"
          value="documents"
          data-testid="tabs-for-modal-edit-tab-documents"
        />
      </Tabs>
    </Paper>
  );
};
export default TabsForModalEditInstrument;
