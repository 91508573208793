import React from "react";
import styled from "styled-components";
import {
  RowInputs,
  TitleRow,
  MainRow,
  CheckboxRow
} from "./BasicDataReqFieldsForm";
import BooleanButtons from "./BooleanButtons";
import locale from "date-fns/locale/en-US";
import { format } from "date-fns";
import { commonPropsForInputsWithoutValue } from "./helpers";
import InputWithCheckbox from "./InputWithCheckbox";
import DATA_MODEL_TABLE from "../../utils/constants/dataModelTable";
import { Checkbox, FormControlLabel } from "@mui/material";
import { OwcDatepicker } from "@one/react";

const BooleanButtonsLabel = styled.h3`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: var(--one-color-gray-800);
  margin-bottom: 0.5rem;
`;

const DatePicker = ({
  formik,
  dataTestid,
  id,
  name,
  label,
  value,
  formikTouched,
  formikErrors,
  dataTestIdHelp
}) => {
  return (
    <OwcDatepicker
      locale={{
        ...locale,
        options: {
          ...locale.options,
          weekStartsOn: 1
        }
      }}
      disableToolbar
      refuse={/[^\dA-Za-z]+/gi}
      format="dd-MMM-yyyy"
      data-testid={dataTestid}
      id={id}
      name={name}
      label={label}
      value={value}
      onValueChange={(e) => {
        const date = e.detail;
        if (date instanceof Date && !isNaN(date)) {
          formik.setFieldValue(name, format(date, "yyyy-MM-dd"), true);
        } else {
          formik.setFieldValue(name, date, true);
        }
      }}
      variant="inline"
      error={formikTouched && Boolean(formikErrors)}
      helperText={formikTouched && formikErrors}
      FormHelperTextProps={{
        "data-testid": dataTestIdHelp
      }}
      autoClose
    />
  );
};

const DatePickers = ({ formik }) => {
  return (
    <>
      <RowInputs>
        <DatePicker
          formik={formik}
          dataTestid={
            "basic-data-additional-info-fields-instrument-date-of-next-maintanance-input"
          }
          id={"dateOfNextMaintanance"}
          name={"dateOfNextMaintanance"}
          label={"Date of next maintenance"}
          value={formik.values.dateOfNextMaintanance}
          formikTouched={formik.touched.dateOfNextMaintanance}
          formikErrors={formik.errors.dateOfNextMaintanance}
          dataTestIdHelp={
            "basic-data-additional-info-fields-instrument-helper-text-date-of-next-maintanance-input"
          }
        />
        <CheckboxRow isChecked={formik.values.dateOfLastMaintanance}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                data-testid="basic-data-additional-info-fields-instrument-date-of-last-maintanance-checkbox"
                checked={formik.values.dateOfLastMaintanance}
                onChange={formik.handleChange}
                name="dateOfLastMaintanance.isSynchronized"
              />
            }
            label={
              formik.values.dateOfLastMaintanance ? "Will update" : "Update?"
            }
          />
        </CheckboxRow>
      </RowInputs>
      <RowInputs>
        <DatePicker
          formik={formik}
          dataTestid={
            "basic-data-additional-info-fields-instrument-date-of-last-maintanance-input"
          }
          id={"dateOfLastMaintanance"}
          name={"dateOfLastMaintanance"}
          label={DATA_MODEL_TABLE.dateOfLastMaintanance.value}
          value={formik.values.dateOfLastMaintanance}
          formikTouched={formik.touched.dateOfLastMaintanance}
          formikErrors={formik.errors.dateOfLastMaintanance}
          dataTestIdHelp={
            "basic-data-additional-info-fields-instrument-helper-text-date-of-last-maintanance-input"
          }
        />
        <CheckboxRow isChecked={formik.values.dateOfNextMaintanance}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                data-testid="basic-data-additional-info-fields-instrument-date-of-next-maintanance-checkbox"
                checked={formik.values.dateOfNextMaintanance}
                onChange={formik.handleChange}
                name="dateOfNextMaintanance"
              />
            }
            label={
              formik.values.dateOfNextMaintanance ? "Will update" : "Update?"
            }
          />
        </CheckboxRow>
      </RowInputs>
    </>
  );
};

const BooleansFields = ({ formik }) => {
  return (
    <>
      <TitleRow>Additional informations</TitleRow>
      <BooleanButtonsLabel>
        {DATA_MODEL_TABLE.isBookable.value}
      </BooleanButtonsLabel>
      <RowInputs>
        <BooleanButtons
          trueProps={{
            "data-testid": "additional-info-bookable-true"
          }}
          falseProps={{
            "data-testid": "additional-info-bookable-false"
          }}
          fullWidth
          color="primary"
          trueLabel="Yes"
          falseLabel="No"
          onChange={(value) => formik.setFieldValue("isBookable", value, true)}
          value={formik.values.isBookable}
        />
        <CheckboxRow isDisabled={true}>
          <FormControlLabel
            control={
              <Checkbox
                disabled
                data-testid="basic-data-additional-info-fields-isBookable-disabled-checkbox"
              />
            }
            label="Update?"
          />
        </CheckboxRow>
      </RowInputs>
      <BooleanButtonsLabel>Status Visualization</BooleanButtonsLabel>
      <RowInputs>
        <BooleanButtons
          trueProps={{
            "data-testid": "additional-info-visualized-true"
          }}
          falseProps={{
            "data-testid": "additional-info-visualized-false"
          }}
          fullWidth
          color="primary"
          trueLabel={DATA_MODEL_TABLE.isVisualized.value}
          falseLabel="Not shown"
          onChange={(value) =>
            formik.setFieldValue("isVisualized", value, true)
          }
          value={formik.values.isVisualized}
        />
        <CheckboxRow isDisabled={true}>
          <FormControlLabel
            control={
              <Checkbox
                disabled
                data-testid="basic-data-additional-info-fields-isVisualized-disabled-checkbox"
              />
            }
            label="Update?"
          />
        </CheckboxRow>
      </RowInputs>
    </>
  );
};

const BasicDataAdditionalInfoForm = ({ formik }) => {
  return (
    <MainRow data-testid="basic-data-additional-form">
      <BooleansFields formik={formik} />

      <InputWithCheckbox
        fieldName="buildingLocation"
        formik={formik}
        testid="building-location"
        label={DATA_MODEL_TABLE.buildingLocation.value}
      />

      <InputWithCheckbox
        fieldName="responsiblePerson"
        formik={formik}
        testid="responsible-person"
        label={DATA_MODEL_TABLE.responsiblePerson.value}
      />

      <InputWithCheckbox
        fieldName="responsibleProxy"
        formik={formik}
        testid="responsible-proxy"
        label={DATA_MODEL_TABLE.responsibleProxy.value}
      />

      <InputWithCheckbox
        fieldName="softwareVersion"
        formik={formik}
        testid="software-version"
        label="Software version"
        commonProps={commonPropsForInputsWithoutValue}
        isDisabled={true}
      />

      <InputWithCheckbox
        fieldName="configurationBaseline"
        formik={formik}
        testid="configuration-baseline"
        label="Configure baseline"
        commonProps={commonPropsForInputsWithoutValue}
        isDisabled={true}
      />

      <InputWithCheckbox
        fieldName="systemStatus"
        formik={formik}
        testid="system-status"
        label={DATA_MODEL_TABLE.systemStatus.value}
      />

      <InputWithCheckbox
        fieldName="qualificationStatus"
        formik={formik}
        testid="gxp-status"
        label={DATA_MODEL_TABLE.qualificationStatus.value}
      />

      <InputWithCheckbox
        fieldName="belongingToGroup"
        formik={formik}
        testid="belonging-to-group"
        label="Belonging to group"
        commonProps={commonPropsForInputsWithoutValue}
        isDisabled={true}
      />

      <InputWithCheckbox
        fieldName="equipmentId"
        formik={formik}
        testid="req-equipmentId"
        label={DATA_MODEL_TABLE.equipmentId.value}
        inputProps={{
          required: true
        }}
      />

      <InputWithCheckbox
        fieldName="manufacturer"
        formik={formik}
        testid="req-manufacturer"
        label={DATA_MODEL_TABLE.manufacturer.value}
      />
      <DatePickers formik={formik} />
    </MainRow>
  );
};
export default BasicDataAdditionalInfoForm;
