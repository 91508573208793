import React, { useState } from "react";
import { OwcModalDialog, OwcButton, OwcIcon, OwcTypography } from "@one/react";
import styled from "styled-components";
import { connect, useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { GridAutocomplete } from "@digitallab/grid-common-components";
import { GridAutoCompleteStyles } from "./GridAutoCompleteStyles";
import { find } from "lodash";
import { OTHER_REASON } from "../../constants";
import { OwcTextarea } from "@one/react";
import CustomSearchText from "./CustomSearchText";

const IdentificationForm = styled.div`
  & > .selectBoxLarge {
    margin: 0 0 25px 0;
    width: 418px;
  }
`;

export const ConfirmDialog = ({
  isReasonUpdate = false,
  testid = "confirm-dialog",
  title = "",
  content = "",
  cancelText,
  approveText,
  cancelVariant,
  cancelColor,
  approveColor,
  approveVariant,
  onCancel,
  onApprove,
  close,
  open,
  children,
  renderActions,
  isDivider = false,
  approveDisable = false,
  size = "sm",
  disableBackdropClick = false,
  isReviewerUpdate = false,
  userList = []
}) => {
  const [opened, setOpened] = useState(true);
  const [isOtherReason, setOtherReason] = useState(false);
  const listOptions = useSelector((state) => state.instruments);
  const formik = useFormikContext();
  const handleCancel = () => {
    close();
    if (onCancel) {
      onCancel(formik);
    }
    setOpened(true);
    setOtherReason(false);
    if (isReasonUpdate) {
      formik.setFieldValue("configText", "", false);
      formik.setFieldValue("editComment", "", false);
      formik.setFieldValue("reviewers", [], false);
    }
  };
  const handleApprove = () => {
    close();
    if (onApprove) {
      onApprove();
    }
  };
  return (
    <OwcModalDialog
      data-testid={testid}
      disableScrollLock
      visible={open}
      size={isReasonUpdate ? false : size}
      className="owcmodalZIndex"
      disableBackdropClick={disableBackdropClick}
    >
      {!isReasonUpdate && (children ? children : content)}
      {isReasonUpdate && (
        <IdentificationForm>
          <GridAutocomplete
            dataTestid="action-data"
            className="selectBoxLarge"
            size="small"
            sx={GridAutoCompleteStyles?.gridAutoCompleteLableShrink}
            optionsList={listOptions?.reasonList}
            propValue="configText"
            propLabel="configText"
            label={isReviewerUpdate ? "Reason *" : "Select reason"}
            value={find(listOptions?.reasonList, {
              configText: formik.values?.configText
            })}
            key={formik.values?.configText}
            onAutoCompleteChange={(selectedValue) => {
              if (selectedValue !== null) {
                formik.setFieldValue("configText", selectedValue.configText);
                setOpened(false);
                selectedValue?.configText &&
                selectedValue?.configText.trim() === OTHER_REASON
                  ? setOtherReason(true)
                  : setOtherReason(false);
              }
            }}
            // isReasonUpdate={isReasonUpdate}
            disableClearable
          />
          {(formik.values?.configText || isReviewerUpdate) && (
            <OwcTextarea
              formik={formik}
              data-testid={`textarea-field-edit-comment`}
              label={
                isOtherReason
                  ? isReviewerUpdate
                    ? "Comment *"
                    : "Add comment *"
                  : isReviewerUpdate
                  ? "Comment "
                  : "Add comment "
              }
              name="editComment"
              validation-mode="realtime"
              error={isOtherReason && formik.values.editComment === ""}
              onInputReset={() => formik.setFieldValue("editComment", "", true)}
              required={isOtherReason}
              value={formik.values?.editComment}
              onInputChange={formik.handleChange}
              onInputKeyDown={formik.handleChange}
              onBlur={formik.handleBlur}
              reset
              cols="18"
              maxLength={80}
              minLength={0}
              minRows={2}
            />
          )}
          {isReviewerUpdate && (
            <>
              <OwcTypography
                variant="subtitle2"
                style={{ padding: "0px 20px 20px 0px" }}
              >
                Reviewer required
              </OwcTypography>
              <CustomSearchText
                id="reviewers"
                dataTestid={`reviewers-inputs-reviewers-input`}
                optionsList={userList}
                propValue="key"
                propLabel="value"
                label="Reviewer"
                value={formik.values?.reviewers || []}
                setValue={(newValue) => {
                  formik.setFieldValue("reviewers", newValue, true);
                }}
                name="reviewers"
                fullWidth={true}
                error={
                  formik.touched["reviewers"] &&
                  Boolean(formik.errors["reviewers"])
                }
                helperText={
                  formik.touched["reviewers"] && formik.errors["reviewers"]
                }
                multiple={true}
                onBlur={formik.handleBlur}
                required={true}
              />
              <OwcTypography
                variant="subtitle2"
                style={{ padding: "0px 0px 20px 0px" }}
              ></OwcTypography>
            </>
          )}
        </IdentificationForm>
      )}
      <div slot="header">
        {title === "Master data already exists" && (
          <OwcIcon
            name="alarm"
            type="outlined"
            style={{
              color: "var(--one-color-interaction-default-warning-1)",
              paddingRight: "2px"
            }}
          />
        )}
        {title}
      </div>
      <div
        slot="actions"
        style={{
          justifyContent: "flex-end",
          display: "flex"
        }}
      >
        {typeof renderActions === "function" ? (
          renderActions({
            cancelColor,
            cancelVariant,
            approveColor,
            approveVariant,
            cancelText,
            approveText,
            close
          })
        ) : (
          <div style={{ display: "flex" }}>
            {cancelText && (
              <OwcButton
                data-testid="confirm-dialog-actions-button-cancel"
                onClick={handleCancel}
                variant="secondary"
                style={{ marginRight: 32 }}
              >
                {cancelText || null}
              </OwcButton>
            )}
            {approveText && (
              <OwcButton
                disabled={
                  (isReasonUpdate ? opened : null) ||
                  approveDisable ||
                  (!isOtherReason || formik.values.editComment
                    ? false
                    : true) ||
                  (isReviewerUpdate
                    ? formik.values?.reviewers === undefined ||
                      formik.values?.reviewers?.length === 0
                      ? true
                      : false
                    : false)
                }
                data-testid="confirm-dialog-actions-button-approve"
                onClick={handleApprove}
                variant={isReasonUpdate ? "primary" : approveColor}
              >
                {approveText || null}
              </OwcButton>
            )}
          </div>
        )}
      </div>
    </OwcModalDialog>
  );
};
const mapStateToProps = (state) => ({
  instrumentDetail: state.instruments?.instrumentDetail
});
export default connect(mapStateToProps, null)(ConfirmDialog);
