import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import PaginationWrapper from "../../components/shared/pagination/PaginationWrapper";
import {
  DEFAULT_IR_PAGE_SIZE,
  MASTER_DATA_LIST,
  masterDataImportStepContent
} from "../../constants";
import StatusLogTable from "../status-log/StatusLogTable";
import StatusLogTopBar from "../status-log/StatusLogTopBar";
import { useSelector } from "react-redux";
import CommonWrapper from "../../common-context/CommonWrapper";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import StaticStepContent from "../../components/shared/import/views/StaticStepContent";
import { UploadButton } from "../importFile/components/UploadButton";
import UploadComponent from "../importFile/components/UploadComponent";
import { FileContextWapper } from "../importFile/FileContext";
import MasterDataDropDown from "./MasterDataDropDown";
import { MasterDataContext } from "./MasterDataContext";
import {
  StepSecFooterContainer,
  StepSecFooterBtn
} from "../status-log/StatusLogPage";
import DownloadTemplateButton from "./DownloadTemplateButton";

const MasterDataContainer = styled.div`
  padding: 0 20px 20px 20px;
  margin-top: 1.125rem;
`;

const MasterDataPage = () => {
  let lastPageSize = useSelector((state) => state?.user?.lastPageSize);
  const { selectedModule, isActionButtonDisabled } =
    useContext(MasterDataContext);
  const stepContents = masterDataImportStepContent;
  const [importedUsers, setImportedUsers] = useState([]);
  const [importedBy, setImportedBy] = useState("");
  const [importedType, setImportedType] = useState("");
  if (typeof lastPageSize === "string" && lastPageSize !== "") {
    lastPageSize = JSON.parse(lastPageSize);
  }
  // Track page view
  const { trackPageView } = useMatomo();
  useEffect(() => {
    trackPageView({
      documentTitle: "Master Data",
      href: `${window.location?.origin}/master-data`
    });
    // eslint-disable-next-line
  }, []);

  const initialValues = {
    fetching: false,
    prevTokens: [],
    nextToken: null,
    nextData: 0,
    limit: lastPageSize?.ir_status_log
      ? lastPageSize?.ir_status_log
      : DEFAULT_IR_PAGE_SIZE.ir_status_log,
    data: [],
    sortDirection: "DESC",
    orderBy: "uploadDate",
    refreshData: 0
  };
  return (
    <MasterDataContainer data-testid="master-data-container">
      <StaticStepContent stepContents={stepContents} />

      <StepSecFooterContainer>
        <StepSecFooterBtn>
          <MasterDataDropDown />
          <DownloadTemplateButton />
        </StepSecFooterBtn>
        <StepSecFooterBtn>
          <FileContextWapper>
            <UploadComponent
              buttonContent={UploadButton}
              targetModule={selectedModule?.targetModule}
              isActionButtonDisabled={isActionButtonDisabled}
            />
          </FileContextWapper>
        </StepSecFooterBtn>
      </StepSecFooterContainer>

      <StatusLogTopBar
        importedUsers={importedUsers}
        importedTypes={MASTER_DATA_LIST}
        setImportedType={setImportedType}
        setImportedBy={setImportedBy}
      />
      <PaginationWrapper initialValues={initialValues}>
        <CommonWrapper>
          <StatusLogTable
            isMasterDataTable={true}
            importedBy={importedBy}
            importedType={importedType}
            setImportedUsers={setImportedUsers}
          />
        </CommonWrapper>
      </PaginationWrapper>
    </MasterDataContainer>
  );
};

export default MasterDataPage;
