import React, { useState, useEffect, useContext } from "react";
import CustomTable from "../../components/shared/ListOwcTable";
import TopBar from "../instruments/TopBar";
import { closeDialog, cancelCloseDialog } from "./ReasonHelper";
import ReasonDialog from "./ReasonDialog";
import DeleteReasonDialog from "./DeleteReasonDialog";
import { getAllData } from "../../utils/helpers/fetching";
import { connect } from "react-redux";
import { compose } from "redux";
import { withApollo } from "react-apollo";
import { LIST_DIGITAL_LAB_INSTRUMENT_REASON } from "../../gql/landingapi/queries";
import { PaginationContext } from "../../components/shared/pagination/PaginationContext";
import { ReasonContext } from "./context";
import { ReasonTableMeta } from "./ReasonMeta";
import TopBarButton from "./TopBarButton";
import { Formik } from "formik";
import { reasonValidationSchema } from "./validationSchema";
import { checkRoleInstrumentRepo } from "../../utils/helpers/checkRoleInstrumentRepo";
import { useHistory } from "react-router";
import { CommonContextWrapper } from "../../common-context/context";
import { getCurrentDatas } from "../instruments/instrument-repository-pagination/PaginationActions";
import { DEFAULT_RECORDS_FETCHED } from "../../constants";

const ReasonContent = ({ client }) => {
  const {
    dispatchAction,
    fetching,
    limit,
    orderBy,
    sortDirection,
    refreshData,
    nextToken
  } = useContext(PaginationContext);
  const {
    dialogOpen,
    setDialogOpen,
    isEditMode,
    dialogCancelOpen,
    setDialogCancelOpen
  } = useContext(ReasonContext);
  const [pageIndex, setPageIndex] = useState(0);
  const [totalReasons, setTotalReasons] = useState([]);
  const [reasonList, setReasonList] = useState([]);
  const [searchGroup, setSearchGroup] = useState("");
  const history = useHistory();
  const { saveLastPageSize } = useContext(CommonContextWrapper);

  const reasonsGql = async (isTokenNull = false) => {
    let variables = {
      limit: DEFAULT_RECORDS_FETCHED,
      nextToken: isTokenNull ? null : nextToken
    };
    if (searchGroup !== "") {
      variables = {
        ...variables,
        filter: { configText: { contains: searchGroup } }
      };
    }
    return getAllData({
      client,
      query: LIST_DIGITAL_LAB_INSTRUMENT_REASON,
      variables,
      fetchPolicy: "network-only",
      dataPath: ["data", "configurationByModelAndCreatedDate"],
      drillData: false
    });
  };

  const getReasonList = async () => {
    dispatchAction({
      type: "fetching",
      payload: true
    });
    setPageIndex(0);
    const { items, nextToken: token } = await reasonsGql(true);
    dispatchAction({
      type: "nextToken",
      payload: { token: token, data: items }
    });
    const currentItems = getCurrentDatas(limit, 0, items);
    setTotalReasons(items);
    setReasonList(currentItems);
  };

  useEffect(() => {
    const fetchRolePermission = async () => {
      const actions = await checkRoleInstrumentRepo();
      if (!actions.showGroup) {
        history?.goBack();
      }
    };
    fetchRolePermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getReasonList();
    saveLastPageSize(limit, "ir_reason");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchGroup, orderBy, sortDirection, limit, refreshData]);

  return (
    <Formik
      validationSchema={reasonValidationSchema}
      initialValues={{ reasonName: "" }}
      isInitialValid={false}
      validateOnMount={false}
    >
      <>
        {dialogOpen && (
          <ReasonDialog
            open={dialogOpen}
            onCancel={() => {
              closeDialog(setDialogOpen);
            }}
            isEditMode={isEditMode}
            // client={client}
          />
        )}
        {dialogCancelOpen && (
          <DeleteReasonDialog
            open={dialogCancelOpen}
            onCancel={() => {
              cancelCloseDialog(setDialogCancelOpen);
            }}
          />
        )}
        <div style={{ marginBottom: 10 }}>
          <TopBar
            label={"Search for reason"}
            setQuery={setSearchGroup}
            placeholder="Search for reason"
            compact
          >
            <TopBarButton />
          </TopBar>
        </div>

        <CustomTable
          filteredDatas={reasonList}
          setFilteredDatas={setReasonList}
          handlerQueryDatas={reasonsGql}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          totalDatas={totalReasons}
          setTotalDatas={setTotalReasons}
          meta={ReasonTableMeta}
          data={reasonList}
          fieldArray={[]}
          onRequestSort={false}
          isReverseOrder={true}
          orderBy={"name"}
          fetching={fetching}
        />
      </>
    </Formik>
  );
};
const mapStateToProps = (state) => ({
  user: state.user
});

export default compose(connect(mapStateToProps), withApollo)(ReasonContent);
