import { useEffect, useRef, useState } from "react";
import {
  OwcExpandableGroup,
  OwcExpandable,
  OwcBadge,
  OwcTypography
} from "@one/react";
import { connect } from "react-redux";
import { compose, withApollo } from "react-apollo";
import {
  AddReviewContainer,
  AddMessageContainer,
  AddMessageDiv,
  AddMessageDivBody
} from "../addEditEquipment/AddEquipmentStyle";
import { OwcWorkflowOverlay, OwcButton } from "@one/react";
import ConfirmDialog from "../../../components/shared/ConfirmDialog";
import useDialog from "../../../utils/hooks/useDialog";
import {
  EQUIPMENT_APPROVE_REVIEW,
  EQUIPMENT_REJECT_REVIEW,
  EQUIPMENT_REVERT_REVIEW
} from "../../../gql/landingapi/mutations";
import {
  GET_EQUIPMENT_PROPOSED_CHANGES,
  GET_EQUIPMENT_PROPOSED_CHANGES_INVENTORY_ID
} from "../../../gql/landingapi/queries";
import Notify from "../../notifications/Notify";
import {
  EQUIPMENT_REVIEW_ACTIONS,
  EQUIPMENT_REVIEW_SECTIONS,
  OTHER_REASON,
  SHOW_REVIEW_MODAL,
  equipmentStatus
} from "../../../constants";
import ReviewReasonDialogContent from "../../../components/shared/ReviewReasonDialogContent";
import { ReviewDetailsCells } from "../../../components/shared/details-cells/DetailsCells";
import { AuditItemContentCellsInfoArray } from "../audit-trial/AuditItemCellsConfig";
import { getAllData } from "../../../utils/helpers/fetching";
import { useHistory } from "react-router-dom";
import DATA_MODEL_TABLE from "../../../utils/constants/dataModelTable";

const EquipmentReviewDialog = ({
  client,
  ReviewId,
  reviewAction,
  openReviewModal,
  onCloseReviewModel,
  onChangeOfActiveModule,
  inventoryId,
  fullUserID
}) => {
  const history = useHistory();
  const [editReason, setEditReason] = useState(null);
  const [editComment, setEditComment] = useState("");
  const [reviewData, setReviewData] = useState(null);
  const [openReasonDialog, setOpenReasonDialog] = useState(false);
  const { openDialog, ...dialogProps } = useDialog();
  const expandableGroupElement = useRef(null);
  const [expandID, setExpandID] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const reviewData = async () => {
      try {
        let result = null;
        let dataObj = null;
        if (ReviewId) {
          result = await client.query({
            query: GET_EQUIPMENT_PROPOSED_CHANGES,
            variables: {
              id: ReviewId
            },
            fetchPolicy: "no-cache"
          });
          if (!result?.error) {
            dataObj = result?.data?.getEquipmentProposedChanges;
            if (
              dataObj?.reviewStatus === equipmentStatus?.pending?.key &&
              !dataObj?.reviewers?.includes(fullUserID)
            ) {
              dataObj = null;
              Notify({
                type: "warning",
                icon: "caution",
                appName: "",
                text: "You are not part of the reviewer list."
              });
              history?.replace("/");
            }
          }
        } else if (inventoryId) {
          result = await getAllData({
            client,
            query: GET_EQUIPMENT_PROPOSED_CHANGES_INVENTORY_ID,
            fetchPolicy: "no-cache",
            dataPath: [
              "data",
              "listEquipmentProposedChangesByInventoryIdAndReviewStatus"
            ],
            variables: {
              limit: 1000,
              id: inventoryId
            },
            drillData: true
          });
          if (!result?.error && result?.items?.length > 0) {
            dataObj = result?.items[0];
          }
          if (!dataObj) {
            Notify({
              type: "warning",
              icon: "caution",
              appName: "",
              text: "Error in fetching data."
            });
          }
        }

        if (
          dataObj &&
          dataObj?.reviewStatus === equipmentStatus?.pending?.key
        ) {
          onChangeOfActiveModule(SHOW_REVIEW_MODAL);
        }

        if (
          dataObj &&
          (dataObj?.reviewStatus === EQUIPMENT_REVIEW_ACTIONS?.approved?.key ||
            dataObj?.reviewStatus === EQUIPMENT_REVIEW_ACTIONS?.revert?.key ||
            dataObj?.reviewStatus === EQUIPMENT_REVIEW_ACTIONS?.reject?.key)
        ) {
          openDialog();
        }

        if (!dataObj) {
          onCloseReviewModel();
        } else {
          setReviewData(dataObj);
        }
      } catch (error) {
        Notify({
          type: "warning",
          icon: "caution",
          appName: "",
          text: "Error in fetching data."
        });
        onCloseReviewModel();
      }
    };
    reviewData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //for approve changes
  const approveReview = async () => {
    try {
      setLoading((preState) => !preState);
      const result = await client.mutate({
        mutation: EQUIPMENT_APPROVE_REVIEW,
        variables: {
          reviewIds: [reviewData?.id]
        },
        fetchPolicy: "no-cache"
      });
      if (!result?.error) {
        if (
          result?.data?.approveChange?.status ===
          EQUIPMENT_REVIEW_ACTIONS?.failure?.key
        ) {
          Notify({
            type: "warning",
            icon: "caution",
            appName: "",
            text: "Unable to approve at this time. Please try again."
          });
        } else {
          onCloseReviewModel();
          Notify({
            type: "success",
            icon: "circle_confirm",
            appName: "",
            text: `Equipment details are reviewed and approved successfully.`
          });
        }
      }
    } catch (error) {
      Notify({
        type: "warning",
        icon: "caution",
        appName: "",
        text: "Unable to approve at this time. Please try again."
      });
    } finally {
      setLoading((preState) => !preState);
    }
  };

  //for reject changes
  const rejectReview = async () => {
    try {
      setLoading((preState) => !preState);
      const result = await client.mutate({
        mutation: EQUIPMENT_REJECT_REVIEW,
        variables: {
          reviewIds: [reviewData?.id],
          editComment: editComment,
          editReason: editReason
        },
        fetchPolicy: "no-cache"
      });
      if (!result?.error) {
        if (
          result?.data?.approveChange?.status ===
          EQUIPMENT_REVIEW_ACTIONS?.failure?.key
        ) {
          Notify({
            type: "warning",
            icon: "caution",
            appName: "",
            text: "Unable to reject at this time. Please try again."
          });
        } else {
          cancelReasons();
          onCloseReviewModel();
          Notify({
            type: "success",
            icon: "circle_confirm",
            appName: "",
            text: `Change(s) reverted due to rejection and the equipment status is "Active" again.`
          });
        }
      }
    } catch (error) {
      Notify({
        type: "warning",
        icon: "caution",
        appName: "",
        text: "Unable to reject at this time. Please try again."
      });
    } finally {
      setLoading((preState) => !preState);
    }
  };

  //for revert changes
  const revertReview = async () => {
    try {
      setLoading((preState) => !preState);
      const result = await client.mutate({
        mutation: EQUIPMENT_REVERT_REVIEW,
        variables: {
          reviewIds: [reviewData?.id],
          editComment: editComment,
          editReason: editReason
        },
        fetchPolicy: "no-cache"
      });
      if (!result?.error) {
        if (
          result?.data?.approveChange?.status ===
          EQUIPMENT_REVIEW_ACTIONS?.failure?.key
        ) {
          Notify({
            type: "warning",
            icon: "caution",
            appName: "",
            text: "Unable to revert at this time. Please try again."
          });
        } else {
          cancelReasons();
          onCloseReviewModel();
          Notify({
            type: "success",
            icon: "circle_confirm",
            appName: "",
            text: `Change(s) reverted and the equipment status is "Active" again.`
          });
        }
      }
    } catch (error) {
      Notify({
        type: "warning",
        icon: "caution",
        appName: "",
        text: "Unable to revert at this time. Please try again."
      });
    } finally {
      setLoading((preState) => !preState);
    }
  };

  const openReasons = () => {
    setOpenReasonDialog(true);
  };

  const cancelReasons = () => {
    setOpenReasonDialog(false);
  };

  const visibleChangeHandler = (event) => {
    /* Cancel popup implementation */
    if (event?.target?.className === "owcoverayZIndex title has-title") {
      onCloseReviewModel();
    }
  };

  const expandedElementsChangedHandler = (event) => {
    setExpandID(() => event?.detail[0]);
  };

  const badgeCount = (item, reviewData) => {
    if (reviewData && reviewData?.changes) {
      const reviewChangesValue = JSON.parse(reviewData?.changes);
      if (
        reviewChangesValue[DATA_MODEL_TABLE?.floor?.key] &&
        !reviewChangesValue[DATA_MODEL_TABLE?.floor?.key]?.value
      ) {
        delete reviewChangesValue[DATA_MODEL_TABLE?.floor?.key];
      }

      if (
        reviewChangesValue[DATA_MODEL_TABLE?.room?.key] &&
        !reviewChangesValue[DATA_MODEL_TABLE?.room?.key]?.value
      ) {
        delete reviewChangesValue[DATA_MODEL_TABLE?.room?.key];
      }
      if (
        reviewChangesValue[DATA_MODEL_TABLE?.buildingLocation?.key] &&
        !reviewChangesValue[DATA_MODEL_TABLE?.buildingLocation?.key]?.value
      ) {
        delete reviewChangesValue[DATA_MODEL_TABLE?.room?.key];
      }

      const matchCount = item?.fields?.filter(
        (iVal) => Object.keys(reviewChangesValue).indexOf(iVal) !== -1
      );
      return matchCount?.length > 0 ? matchCount?.length : 0;
    }
    return 0;
  };

  return (
    <>
      {reviewData &&
      reviewData?.reviewStatus === equipmentStatus?.pending?.key ? (
        <OwcWorkflowOverlay
          className="owcoverayZIndex"
          visible={openReviewModal}
          onVisibleChange={visibleChangeHandler}
          disableBackdropClick
        >
          <>
            <div slot="title">
              {reviewAction === EQUIPMENT_REVIEW_ACTIONS?.review?.value
                ? "Review "
                : "Revert "}
              changes proposed by{" "}
              {reviewData && reviewData?.createdBy?.items.length > 0
                ? reviewData?.createdBy?.items[0]?.name !== ""
                  ? reviewData?.createdBy?.items[0]?.name
                  : reviewData?.createdBy?.items[0]?.email
                : ""}
            </div>
            <div
              slot="content"
              style={{
                padding: "0",
                display: "block",
                marginBottom: "0",
                height: "100%"
              }}
            >
              <AddReviewContainer id="add-edit-cluster-container">
                <OwcExpandableGroup
                  ref={expandableGroupElement}
                  onExpandedElementsChanged={expandedElementsChangedHandler}
                >
                  {EQUIPMENT_REVIEW_SECTIONS.map((item, index) => {
                    const badgeCountValue = badgeCount(item, reviewData);
                    return (
                      <OwcExpandable
                        variant="standard"
                        round
                        expanded={expandID === index}
                      >
                        <span slot="title">
                          {item?.name}{" "}
                          {badgeCountValue > 0 && (
                            <OwcBadge
                              type="active"
                              style={{ margin: "0 20px 10px 0" }}
                            >
                              {badgeCountValue}
                            </OwcBadge>
                          )}
                        </span>
                        <span
                          slot="content"
                          style={{
                            height: "fit-content"
                          }}
                        >
                          {/* for cellRender */}
                          <ReviewDetailsCells
                            changedItem={JSON.parse(reviewData.changes)}
                            item={JSON.parse(reviewData?.newData)}
                            displayColumns={item.fields}
                            infoMeta={{
                              fields: AuditItemContentCellsInfoArray
                            }}
                          />
                        </span>
                      </OwcExpandable>
                    );
                  })}
                </OwcExpandableGroup>
              </AddReviewContainer>
              <AddMessageContainer>
                {reviewAction === EQUIPMENT_REVIEW_ACTIONS?.review?.value ? (
                  <>
                    <AddMessageDiv>
                      <OwcTypography variant="button">
                        Review proposed changes
                      </OwcTypography>
                    </AddMessageDiv>
                    <AddMessageDivBody>
                      <OwcTypography variant="body1">
                        Approve or reject the above shown changes.
                      </OwcTypography>
                    </AddMessageDivBody>
                  </>
                ) : (
                  <>
                    <AddMessageDiv>
                      <OwcTypography variant="button">
                        Revert changes
                      </OwcTypography>
                    </AddMessageDiv>
                    <AddMessageDivBody>
                      <OwcTypography variant="body1">
                        For the above shown changes a review is required. By
                        reverting the changes, the equipment status will be
                        "Active" again.
                      </OwcTypography>
                    </AddMessageDivBody>
                  </>
                )}
              </AddMessageContainer>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                height: "calc(100% - 120px)",
                backgroundColor: "var(--one-color-interaction-hover-brand-3)"
              }}
            >
              <div
                style={{
                  width: "60%",
                  margin: "auto",
                  height: "calc(100% - 60px)",
                  backgroundColor:
                    "var(--one-color-interaction-disabled-base-1)"
                }}
              ></div>
            </div>

            <div slot="actions" style={{ padding: "16px" }}>
              {reviewAction === EQUIPMENT_REVIEW_ACTIONS?.review?.value ? (
                <div style={{ display: "flex" }}>
                  <OwcButton
                    data-testid="master-data-previous-step-button"
                    onClick={openReasons}
                    variant="secondary"
                    disabled={loading}
                    style={{ textTransform: "none", marginRight: "20px" }}
                  >
                    Reject
                  </OwcButton>
                  <OwcButton
                    variant="primary"
                    onClick={() => {
                      approveReview();
                    }}
                    style={{
                      textTransform: "none"
                    }}
                    disabled={loading}
                    type="submit"
                    data-testid="master-data-save-step-button"
                  >
                    Approve
                  </OwcButton>
                </div>
              ) : (
                <div style={{ display: "flex" }}>
                  <OwcButton
                    data-testid="master-data-previous-step-button"
                    onClick={() => onCloseReviewModel()}
                    variant="secondary"
                    disabled={loading}
                    style={{ textTransform: "none", marginRight: "20px" }}
                  >
                    Cancel
                  </OwcButton>
                  <OwcButton
                    variant="primary"
                    onClick={openReasons}
                    style={{
                      textTransform: "none"
                    }}
                    disabled={loading}
                    type="submit"
                    data-testid="master-data-save-step-button"
                  >
                    Revert changes
                  </OwcButton>
                </div>
              )}
              <ConfirmDialog
                {...dialogProps}
                open={openReasonDialog}
                approveText="Save"
                content={
                  <ReviewReasonDialogContent
                    editReason={editReason}
                    setEditReason={setEditReason}
                    editComment={editComment}
                    setEditComment={setEditComment}
                  />
                }
                approveColor="primary"
                approveVariant="contained"
                approveDisable={
                  editReason === OTHER_REASON ? !editComment : !editReason
                }
                cancelText="Cancel"
                cancelVariant="outlined"
                cancelColor="primary"
                onApprove={() => {
                  if (reviewAction === EQUIPMENT_REVIEW_ACTIONS?.review?.value)
                    rejectReview();
                  else revertReview();
                }}
                onCancel={cancelReasons}
                title={
                  reviewAction === EQUIPMENT_REVIEW_ACTIONS?.review?.value
                    ? "Reason for rejection"
                    : "Reason for revert changes"
                }
                disableBackdropClick={true}
              />
            </div>
          </>
        </OwcWorkflowOverlay>
      ) : (
        <>
          {reviewData &&
          (reviewData?.reviewStatus ===
            EQUIPMENT_REVIEW_ACTIONS?.approved?.key ||
            reviewData?.reviewStatus ===
              EQUIPMENT_REVIEW_ACTIONS?.revert?.key ||
            reviewData?.reviewStatus ===
              EQUIPMENT_REVIEW_ACTIONS?.reject?.key) ? (
            <>
              <ConfirmDialog
                {...dialogProps}
                approveText="OK"
                approveColor="secondary"
                approveVariant="contained"
                onApprove={() => {
                  history?.replace("/");
                }}
                title="Equipment already reviewed"
                content="The equipment you are trying to review has already been reviewed."
                disableBackdropClick={true}
              />
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

export default compose(connect(), withApollo)(EquipmentReviewDialog);
