import { OLD_SCHEMA_FIELDS } from "../../../constants";
import DATA_MODEL_TABLE from "../../../utils/constants/dataModelTable";

export const AuditItemHeaderCellsInfoArray = [
  {
    label: DATA_MODEL_TABLE.equipmentCategory.value,
    field: [DATA_MODEL_TABLE.equipmentCategory.key],
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.manufacturer.value,
    field: [DATA_MODEL_TABLE.manufacturer.key],
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.equipmentModel.value,
    field: [DATA_MODEL_TABLE.equipmentModel.key],
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.variant.value,
    field: [DATA_MODEL_TABLE.variant.key],
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.equipmentNickName.value,
    field: [DATA_MODEL_TABLE.equipmentNickName.key],
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.location.value,
    field: [DATA_MODEL_TABLE.location.key],
    props: {
      fullRow: false
    }
  }
];

export const AuditItemContentCellsInfoArray = [
  {
    label: DATA_MODEL_TABLE.siteName.value,
    field: DATA_MODEL_TABLE.siteName.key,
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.serialNumber.value,
    field: DATA_MODEL_TABLE.serialNumber.key,
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.materialNumber.value,
    field: DATA_MODEL_TABLE.materialNumber.key,
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.inventoryNumber.value,
    field: DATA_MODEL_TABLE.inventoryNumber.key,
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.equipmentId.value,
    field: DATA_MODEL_TABLE.equipmentId.key,
    props: {
      fullRow: false
    }
  },
  {
    label: "RUDI number",
    field: DATA_MODEL_TABLE.instrumentRUDI.key,
    props: {
      fullRow: false
    }
  },
  {
    label: "GTIN number",
    field: DATA_MODEL_TABLE.instrumentGTIN.key,
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.equipmentCategory.value,
    field: DATA_MODEL_TABLE.equipmentCategory.key,
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.manufacturer.value,
    field: DATA_MODEL_TABLE.manufacturer.key,
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.equipmentModel.value,
    field: DATA_MODEL_TABLE.equipmentModel.key,
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.variant.value,
    field: DATA_MODEL_TABLE.variant.key,
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.cluster.value,
    field: DATA_MODEL_TABLE.cluster.key,
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.equipmentNickName.value,
    field: DATA_MODEL_TABLE.equipmentNickName.key,
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.comment.value,
    field: DATA_MODEL_TABLE.comment.key,
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.belongingToGroup.value,
    field: DATA_MODEL_TABLE.belongingToGroup.key,
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.buildingLocation.value,
    field: DATA_MODEL_TABLE.buildingLocation.key,
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.floor.value,
    field: DATA_MODEL_TABLE.floor.key,
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.room.value,
    field: DATA_MODEL_TABLE.room.key,
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.equipmentAdministrator.value,
    field: DATA_MODEL_TABLE.equipmentAdministrator.key,
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.responsiblePerson.value,
    field: DATA_MODEL_TABLE.responsiblePerson.key,
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.systemOwner.value,
    field: DATA_MODEL_TABLE.systemOwner.key,
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.responsibleProxy.value,
    field: DATA_MODEL_TABLE.responsibleProxy.key,
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.functionalLocation.value,
    field: DATA_MODEL_TABLE.functionalLocation.key,
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.costCenter.value,
    field: DATA_MODEL_TABLE.costCenter.key,
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.sop.value,
    field: DATA_MODEL_TABLE.sop.key,
    props: {
      fullRow: false,
      multiLine: true
    }
  },
  {
    label: DATA_MODEL_TABLE.csv.value,
    field: DATA_MODEL_TABLE.csv.key,
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.electronicRecord.value,
    field: DATA_MODEL_TABLE.electronicRecord.key,
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.electronicSignatures.value,
    field: DATA_MODEL_TABLE.electronicSignatures.key,
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.dateOfNextPeriodicReview.value,
    field: DATA_MODEL_TABLE.dateOfNextPeriodicReview.key,
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.maintenanceIntervalInDays.value,
    field: DATA_MODEL_TABLE.maintenanceIntervalInDays.key,
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.maintenancePlan.value,
    field: DATA_MODEL_TABLE.maintenancePlan.key,
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.gxpRelevant.value,
    field: DATA_MODEL_TABLE.gxpRelevant.key,
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.qualificationStatus.value,
    field: DATA_MODEL_TABLE.qualificationStatus.key,
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.dateOfLastMaintanance.value,
    field: DATA_MODEL_TABLE.dateOfLastMaintanance.key,
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.dateOfNextMaintanance.value,
    field: DATA_MODEL_TABLE.dateOfNextMaintanance.key,
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.documentedMaintenanceDate.value,
    field: DATA_MODEL_TABLE.documentedMaintenanceDate.key,
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.qualificationDocuments.value,
    field: DATA_MODEL_TABLE.qualificationDocuments.key,
    props: {
      fullRow: true,
      multiLine: true
    }
  },
  {
    label: "Version",
    field: DATA_MODEL_TABLE.softwareVersion.key,
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.configurationBaseline.value,
    field: DATA_MODEL_TABLE.configurationBaseline.key,
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.installedTests.value,
    field: DATA_MODEL_TABLE.installedTests.key,
    props: {
      fullRow: true,
      multiLine: true
    }
  },
  {
    label: DATA_MODEL_TABLE.isBookable.value,
    field: DATA_MODEL_TABLE.isBookable.key,
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.isVisualized.value,
    field: DATA_MODEL_TABLE.isVisualized.key,
    props: {
      fullRow: false
    }
  },
  {
    label: "Description",
    field: "instrumentDescription",
    props: {
      fullRow: false
    }
  },
  {
    label: OLD_SCHEMA_FIELDS?.instrumentType?.value,
    field: OLD_SCHEMA_FIELDS?.instrumentType?.key,
    props: {
      fullRow: false
    }
  },
  {
    label: "Module",
    field: "module",
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.trackPositionInCluster.value,
    field: DATA_MODEL_TABLE.trackPositionInCluster.key,
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.controlledEquipmentStatus.value,
    field: DATA_MODEL_TABLE.controlledEquipmentStatus.key,
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.linkedInstance.value,
    field: DATA_MODEL_TABLE.linkedInstance.key,
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.tags.value,
    field: DATA_MODEL_TABLE.tags.key,
    props: {
      fullRow: true
    }
  },
  {
    label: OLD_SCHEMA_FIELDS.instrumentName.value,
    field: OLD_SCHEMA_FIELDS.instrumentName.key,
    props: { fullRow: false }
  },
  {
    label: OLD_SCHEMA_FIELDS.instrumentGxPStatus.value,
    field: OLD_SCHEMA_FIELDS.instrumentGxPStatus.key,
    props: { fullRow: false }
  },
  {
    label: OLD_SCHEMA_FIELDS.remark.value,
    field: OLD_SCHEMA_FIELDS.remark.key,
    props: { fullRow: false }
  },
  {
    label: OLD_SCHEMA_FIELDS.secondResponsiblePerson.value,
    field: OLD_SCHEMA_FIELDS.secondResponsiblePerson.key,
    props: { fullRow: false }
  },
  {
    label: DATA_MODEL_TABLE.systemStatus.value,
    field: DATA_MODEL_TABLE.systemStatus.key,
    props: { fullRow: false }
  },
  {
    label: DATA_MODEL_TABLE?.displayImage?.value,
    field: DATA_MODEL_TABLE?.displayImage?.key,
    props: { fullRow: false }
  },
  {
    label: DATA_MODEL_TABLE?.weightInkg?.value,
    field: DATA_MODEL_TABLE?.weightInkg?.key,
    props: { fullRow: false }
  },
  {
    label: DATA_MODEL_TABLE?.heightInmm?.value,
    field: DATA_MODEL_TABLE?.heightInmm?.key,
    props: { fullRow: false }
  },
  {
    label: DATA_MODEL_TABLE?.lengthInmm?.value,
    field: DATA_MODEL_TABLE?.lengthInmm?.key,
    props: { fullRow: false }
  },
  {
    label: DATA_MODEL_TABLE?.depthInmm?.value,
    field: DATA_MODEL_TABLE?.depthInmm?.key,
    props: { fullRow: false }
  },
  {
    label: DATA_MODEL_TABLE?.clearanceLeftInmm?.value,
    field: DATA_MODEL_TABLE?.clearanceLeftInmm?.key,
    props: { fullRow: false }
  },
  {
    label: DATA_MODEL_TABLE?.clearanceRightInmm?.value,
    field: DATA_MODEL_TABLE?.clearanceRightInmm?.key,
    props: { fullRow: false }
  },
  {
    label: DATA_MODEL_TABLE?.clearanceHeightInmm?.value,
    field: DATA_MODEL_TABLE?.clearanceHeightInmm?.key,
    props: { fullRow: false }
  },
  {
    label: DATA_MODEL_TABLE?.clearanceToFrontInmm?.value,
    field: DATA_MODEL_TABLE?.clearanceToFrontInmm?.key,
    props: { fullRow: false }
  },
  {
    label: DATA_MODEL_TABLE?.clearanceBackwardsInmm?.value,
    field: DATA_MODEL_TABLE?.clearanceBackwardsInmm?.key,
    props: { fullRow: false }
  },
  {
    label: DATA_MODEL_TABLE?.isActive?.value,
    field: DATA_MODEL_TABLE?.isActive?.key,
    props: { fullRow: false }
  },
  {
    label: DATA_MODEL_TABLE?.heatOutputInW?.value,
    field: DATA_MODEL_TABLE?.heatOutputInW?.key,
    props: { fullRow: false }
  },
  {
    label: DATA_MODEL_TABLE?.pressureEquipment?.value,
    field: DATA_MODEL_TABLE?.pressureEquipment?.key,
    props: { fullRow: false }
  },
  {
    label: DATA_MODEL_TABLE?.noiseIndBA?.value,
    field: DATA_MODEL_TABLE?.noiseIndBA?.key,
    props: { fullRow: false }
  },
  {
    label: DATA_MODEL_TABLE?.noiseSource?.value,
    field: DATA_MODEL_TABLE?.noiseSource?.key,
    props: { fullRow: false }
  },
  {
    label: DATA_MODEL_TABLE?.qualificationStatus?.value,
    field: DATA_MODEL_TABLE?.qualificationStatus?.key,
    props: { fullRow: false }
  },
  {
    label: DATA_MODEL_TABLE?.noiseSourceDetails?.value,
    field: DATA_MODEL_TABLE?.noiseSourceDetails?.key,
    props: { fullRow: false }
  },
  {
    label: DATA_MODEL_TABLE?.positioningNote?.value,
    field: DATA_MODEL_TABLE?.positioningNote?.key,
    props: { fullRow: false }
  },
  {
    label: DATA_MODEL_TABLE?.positioning?.value,
    field: DATA_MODEL_TABLE?.positioning?.key,
    props: { fullRow: false }
  },
  {
    label: DATA_MODEL_TABLE?.vibrationSensitive?.value,
    field: DATA_MODEL_TABLE?.vibrationSensitive?.key,
    props: { fullRow: false }
  },
  {
    label: DATA_MODEL_TABLE?.status?.value,
    field: DATA_MODEL_TABLE?.status?.key,
    props: { fullRow: true }
  }
];
