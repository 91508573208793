import styled from "styled-components";

export const CoverMenuItem = styled.div`
  height: 0.973480224609375px;
  width: 100%;
  border-radius: 0px;
  background: var(--one-color-gray-300);
`;

export const FormComponents = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  & > .selectBox {
    margin: 3px 20px 20px 0px;
    width: 100%;
    max-width: 240px;
  }
  & > .selectBoxCES {
    margin: 3px 20px 20px 0px;
    width: 100%;
    max-width: 270px;
  }
  & > .selectBoxES {
    margin: 0 20px 20px 0px;
    width: 100%;
    max-width: 238px;
  }
  & > .selectBoxGxP {
    margin: 3px 20px 20px 0px;
    width: 100%;
    max-width: 260px;
  }
  & > .textInputBox {
    margin: 3px 20px 15px 0px;
    width: 100%;
    max-width: 230px;
  }
  & > .owcInputBox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 8px 8px 0px;
  }
  & > .owcInputBoxComplex {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  & > .owcTextarea {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 8px 8px 0px;
  }
  & > .specificationLinup {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  & > .owctagInputBox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 8px 8px 0px;
  }
  & > .textInputBoxLarge {
    margin: 0 20px 20px 0px;
    width: 100%;
    max-width: 300px;
  }
  & > .textInputBoxMCP {
    margin: 0 20px 20px 0px;
    width: 100%;
    max-width: 267px;
  }
  & > .textInputBoxMCPMaintainance {
    margin: 0 20px 20px 0px;
    width: 100%;
    max-width: 350px;
  }
  & > .dataPickerOwc {
    margin: 0 20px 20px 0px;
  }
  & > .selectBoxLarge {
    margin: 0 20px 20px 0px;
    width: 100%;
    max-width: 300px;
  }
  & > .displayNone {
    display: none;
  }
  & > .datePicker {
    margin: 0 20px 20px 0px;
    min-width: 297px;
    background-color: rgb(245, 245, 245);
    border-radius: 7px;
    max-height: 50px;
    margin-bottom: 32px;
  }
  & > .datePicker > label {
    padding-left: 15px;
  }
  & > .datePicker input {
    padding-left: 15px;
  }
`;

export const DescriptionForm = styled.div`
  & > .TextFieldInput {
    margin: 0 20px 20px 0px;
    min-width: 220px;
    max-width: 220px;
    width: 100%;
  }
  & > .selectBox {
    margin: 0 20px 20px 0px;
    width: 100%;
    max-width: 220px;
    min-width: 220px;
  }
  & > .selectBoxLarge {
    margin: 0 20px 20px 0px;
    width: 100%;
    max-width: 322;
    min-width: 220px;
  }
  & > .displayNone {
    display: none;
  }
`;
export const CoverSpace = styled.div`
  margin-bottom: 20px;
  display: none;
`;
// export const CustomBadgeTypography = withStyles({
//   root: {
//     color: "var(--one-color-gray-400)",
//     fontFamily: "Roboto",
//     fontStyle: "normal",
//     fontWeight: 400,
//     fontSize: 11,
//     height: 10,
//     float: "right",
//     position: "Relative",
//     top: -8
//   }
// })(Typography);

export const StepperElementStyled = styled.div`
  width: 100%;
  background: var(--one-color-cobas-white);
  margin-bottom: 10px;
  box-shadow: 0px 1px 0px var(--one-color-gray-300);
`;

export const AddContainer = styled.div`
  width: 100%;
  min-height: 420px;
  background: var(--one-color-cobas-white);
  border: thin solid thin;
  box-sizing: border-box;
  padding: 16px 28px;
`;

export const AddReviewContainer = styled.div`
  width: 100%;
  background: var(--one-color-cobas-white);
  border: thin solid thin;
  box-sizing: border-box;
  padding: 16px 28px 0 28px;
`;

export const AddMessageContainer = styled.div`
  min-height: 100px;
  background: var(--one-color-cobas-white);
  border: thin solid thin;
  box-sizing: border-box;
  margin: 0 28px 0 28px;
  box-shadow: inset 0 0 0 0.0625rem
    var(--one-color-interaction-default-neutral-2);
  background-color: var(--one-color-interaction-default-base-1);
  border-radius: 0.25rem;
`;

export const AddMessageDiv = styled.div`
  padding: 20px;
`;

export const AddMessageDivBody = styled.div`
  padding: 0 20px 20px 20px;
`;

export const Highlighter = styled.div`
  width: 135px;
  height: 2px;
  background: var(--one-color-background-brand-1);
  margin-top: 10px;
`;

export const NavReactangleBar = styled.div`
  width: 100%;
  height: 48px;
  background: var(--one-color-cobas-white);
  margin-bottom: 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.34902);
`;

export const TestCover = styled.div`
  float: left;
  margin-right: 20px;
`;
export const LabCover = styled.div``;
export const AddContainerHeader = styled.div`
  display: grid;
  width: 100%;
  height: 60px;
  padding: 10px 16px;
  border-bottom: thin solid thin;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--one-color-gray-800);
  align-items: center;
  & > .stepNameButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const AddContainerBody = styled.div`
  width: 100%;
  min-height: 300px;
  padding: 20px 16px;
  background: var(--one-color-cobas-white);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  margin-bottom: 20px;
`;
export const AddContainerEditBody = styled.div`
  width: 100%;
  background: var(--one-color-cobas-white);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  margin-bottom: 20px;
`;
export const FinishButton = styled.div`
  padding-left: 20px;
  float: right;
`;

export const AddContainerFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px 16px 16px;
`;

// export const PreviousButtonStyled = styled(Button)`
//   && {
//     text-transform: none;
//     background-color: var(--one-color-cobas-white);
//     color: #0066cc;
//     font-weight: 500;
//     border: 1px solid #d3d3d3;
//     margin-right: 24px;
//   }
// `;

// export const CustomTypography = withStyles({
//   root: {
//     paddingTop: 13,
//     paddingLeft: 16,
//     letterSpacing: "0.02em",
//     color: "#0066CC"
//   }
// })(Typography);

export const AddEquipmentDescription = styled.div`
  display: flex;
  flex-wrap: wrap;
  float: left;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-top: 10px;
`;
