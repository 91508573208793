import styled from "styled-components";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useFormikContext } from "formik";
import { CoverSpace } from "./AddEquipmentStyle";
import { BadgeIconTop } from "./AddEditEquipmentContainer";
import { entryType } from "../../../constants";
import { OwcSlideToggle, OwcTypography } from "@one/react";

const BookMoniterStyled = styled.div`
  display: flex;
`;

const DigitalLabStep = ({ instrumentDetail }) => {
  const formik = useFormikContext();

  useEffect(() => {
    formik.setFieldValue(
      "isBookable",
      instrumentDetail.isBookable ?? false,
      true
    );
    formik.setFieldValue(
      "isVisualized",
      instrumentDetail.isVisualized ?? false,
      true
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CoverSpace>
        <OwcTypography variant="caption">SAP Mapped Fields</OwcTypography>
        <BadgeIconTop></BadgeIconTop>
      </CoverSpace>
      <OwcTypography
        variant="subtitle2"
        style={{ padding: "10px 20px 20px 0px" }}
      >
        Availability
      </OwcTypography>
      <BookMoniterStyled>
        <OwcTypography
          variant="subtitle1"
          style={{ marginRight: "36px" }}
          data-testid={`doc-data-isbookable`}
        >
          <OwcSlideToggle
            data-testid={`toggle-field-booking`}
            checked={formik.values?.isBookable}
            disabled={
              instrumentDetail?.entryType === entryType.clusterSubequipment
            }
            onInputChange={() =>
              formik.setFieldValue(
                "isBookable",
                !formik.values?.isBookable,
                true
              )
            }
            labelPlacement="left"
          >
            Available in Booking ?
          </OwcSlideToggle>
        </OwcTypography>
        <OwcTypography
          variant="subtitle1"
          id="testtypo"
          data-testid={`doc-data-isVisualized`}
        >
          <OwcSlideToggle
            checked={formik.values?.isVisualized}
            onInputChange={() =>
              formik.setFieldValue(
                "isVisualized",
                !formik.values?.isVisualized,
                true
              )
            }
            labelPlacement="left"
          >
            Available in Monitoring ?
          </OwcSlideToggle>
        </OwcTypography>
      </BookMoniterStyled>
    </>
  );
};

const mapStateToProps = (state) => ({
  instrumentDetail: state.instruments?.instrumentDetail
});

export default connect(mapStateToProps)(DigitalLabStep);
