import { isNull, omitBy } from "lodash";
import {
  CREATE_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY,
  UPDATE_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY
} from "../../../gql/landingapi/mutations";
import { LIST_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRYS } from "../../../gql/landingapi/queries";
import { getAllData } from "../../../utils/helpers/fetching";
import { emptyInstruments } from "../../instruments/InstrumentsModal";
import { getNewMaterialNumber, removeSpaceInString } from "./helpers";
import {
  GXPemptyInstrumentsOptions as enumOptions,
  entryType
} from "../../../constants";
import { find } from "lodash";

export const getInstrumentWithDefaultValues = ({
  instrument,
  defaultInstrument = emptyInstruments
}) => ({
  ...defaultInstrument,
  ...omitBy(instrument, isNull)
});

const ommitOptionalFieldIfEmpty = (instrument) => {
  if (instrument?.csv === "") {
    delete instrument.csv;
  }
  if (instrument?.electronicRecord === "") {
    delete instrument.electronicRecord;
  }
  if (instrument?.electronicSignatures === "") {
    delete instrument.electronicSignatures;
  }
  if (instrument?.gxpRelevant === "") {
    delete instrument.gxpRelevant;
  }
  if (instrument?.qualificationStatus === "") {
    delete instrument.qualificationStatus;
  }

  return instrument;
};

const updateInstrumentObject = (instrument, masterData) => {
  instrument["location"] = "";
  instrument["siteId"] = find(masterData?.sitesList, {
    siteName: instrument.siteName
  })?.siteId;
  if (!instrument?.materialNumber) {
    instrument.materialNumber = removeSpaceInString(
      getNewMaterialNumber(instrument)
    );
  }

  instrument.csv =
    find(enumOptions.csv, { value: instrument.csv })?.key ?? instrument.csv;
  instrument.electronicSignatures =
    find(enumOptions.electronicSignatures, {
      value: instrument.electronicSignatures
    })?.key ?? instrument.electronicSignatures;
  instrument.electronicRecord =
    find(enumOptions.electronicRecord, { value: instrument.electronicRecord })
      ?.key ?? instrument.electronicRecord;
  instrument.gxpRelevant =
    find(enumOptions.gxpRelevant, { value: instrument.gxpRelevant })?.key ??
    instrument.gxpRelevant;
  instrument.qualificationStatus =
    find(enumOptions.qualificationStatus, {
      value: instrument.qualificationStatus
    })?.key ?? instrument.qualificationStatus;

  return instrument;
};

const checkIsNew = (existingRecordsIds, instrument) => {
  if (
    find(existingRecordsIds, {
      materialNumber: instrument.materialNumber,
      serialNumber: instrument.serialNumber
    })
  ) {
    return false;
  }
  return true;
};

export const updateInstrumentsBatch = async (
  client,
  arrayOfInstruments,
  masterData,
  existingRecordsIds
) => {
  const arrayOfInstrumentsPromises = arrayOfInstruments?.map((instrument) => {
    const updatedInstrument = updateInstrumentObject(instrument, masterData);
    if (checkIsNew(existingRecordsIds, instrument)) {
      updatedInstrument["entryType"] = entryType?.standaloneEquipment;
      return client.mutate({
        mutation: CREATE_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY,
        variables: {
          instrument: ommitOptionalFieldIfEmpty(updatedInstrument)
        },
        fetchPolicy: "no-cache"
      });
    } else {
      updatedInstrument["editReason"] = "Import CSV"; // TODO: this is temporary fix
      return client.mutate({
        mutation: UPDATE_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY,
        variables: { instrument: ommitOptionalFieldIfEmpty(updatedInstrument) },
        fetchPolicy: "no-cache"
      });
    }
  });
  return Promise.allSettled([...arrayOfInstrumentsPromises]);
};

export const updateInstrumentsStore = async (
  client,
  loadInstruments,
  limit,
  fetchInstruments = getAllData
) => {
  const { items: instruments } = await fetchInstruments({
    client,
    query: LIST_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRYS,
    drillData: true,
    variables: {
      limit: limit
    },
    dataPath: ["data", "listDigitalLabInstrumentRepositoryEntrys"]
  });
  loadInstruments({
    instruments
  });
};
