import { Button, ButtonGroup } from "@mui/material";

const BooleanButtons = ({
  trueLabel,
  falseLabel,
  trueProps,
  falseProps,
  value,
  onChange,
  ...props
}) => {
  return (
    <ButtonGroup disableElevation {...props}>
      <Button
        {...trueProps}
        variant={value && "contained"}
        onClick={() => {
          onChange(true);
        }}
      >
        {trueLabel}
      </Button>
      <Button
        {...falseProps}
        variant={!value && "contained"}
        onClick={() => {
          onChange(false);
        }}
      >
        {falseLabel}
      </Button>
    </ButtonGroup>
  );
};

export default BooleanButtons;
