import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { pick } from "underscore";
import { map } from "lodash";
import {
  getEnumValue,
  rearangeDisplayDate
} from "../../utils/helpers/text/index";
import PaginationActions from "../../features/instruments/instrument-repository-pagination/PaginationActions";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { GXP_DROP_CHECK, entryType, equipmentStatus } from "../../constants";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  Box,
  CircularProgress,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from "@mui/material";
import { OwcTypography } from "@one/react";

const Cell = styled.div`
  display: flex;
  align-items: center;
`;

const CustomCell = styled.div`
  width: 150px;
  align-items: center;
  margin-right: 12px;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 400;
  color: var(--one-color-gray-800);
`;

const CellValue = styled.div`
  font-weight: 500;
  max-width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-break: break-all;
`;

const CellKey = styled.div`
  display: flex;
`;

export const CustomIcon = styled.div`
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
`;
const CustomDivFlex = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
export const DraggableContainer = styled.div`
  & > .card {
    display: flex;
    width: 250px;
    font-size: 16px;
    margin-bottom: 8px;
    border: thin solid var(--one-color-gray-400);
    border-radius: 4px;
    padding: 2px 0;
    align-items: center;
  }
`;

const TableStyled = styled.div`
  margin-top: 10px;
  padding: 0;
  border-radius: 0.25rem 0.25rem 0 0;
  overflow: hidden;
  border-width: 1px 1px 0 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.table.borderColor};
  & thead,
  & tr.cluster {
    background-color: ${(props) => props.theme.table.header.backgroundColor};
  }
  & tfoot {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  & th {
    color: ${(props) => props.theme.table.color};
    font-weight: 400;
    line-height: 1rem;
    padding: 0.75rem 1rem;
    font-size: 0.875rem;
  }
  & tr.deleteEquip {
    background-color: var(--one-color-gray-50);
    & td {
      color: var(--one-color-gray-400);
    }
    & div {
      color: var(--one-color-gray-400);
    }
    & div > span {
      color: var(--one-color-gray-400);
    }
  }
  & tr > .tablecell {
    padding-bottom: 0;
    padding-top: 0;
    padding-left: 110px;
    background: var(--one-color-blue-100);
  }
  & tr > td {
    color: ${(props) => props.theme.table.color};
    width: 20%;
    font-weight: 500;
    line-height: 1.125rem;
    padding: 0.5px;
    padding-left: 1rem;
    font-size: 0.875rem;
    text-overflow: ellipsis;
    max-width: 12.5rem;
    overflow: hidden;
    height: auto !important;
    &.rowheight {
      height: 48px !important;
    }
  }
  & .nodata {
    display: flex;
    justify-content: center;
    background: var(--one-color-cobas-white);
    padding: 10px;
    font-size: 18px;
  }
  tbody {
    display: block;
    max-height: 45vh;
    overflow: auto;
  }
  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed; /* even columns width , fix width of table too*/
    background-color: ${(props) => props.theme.table.cell.backgroundColor};
  }
`;

const RegularCell = styled.div`
  display: flex;
  align-items: center;
`;
const FalseAnswerSpan = styled.span`
  color: var(--one-color-gray-600);
`;

const getId = (item, keys) => {
  const _keys = Array.isArray(keys) ? keys : [keys];
  const idObj = pick(item, ..._keys);
  return Object.values(idObj).join("");
};

const isObject = (item) => typeof item === "object" && item !== null;

const DisplaySortingArrow = ({ keySort, orderBy, isReverseOrder }) => {
  if (keySort !== orderBy) {
    return <ArrowDropDownIcon />;
  }
  return isReverseOrder ? (
    <ArrowDropUpIcon data-testid="arrow-drop-down-up-icon" color="primary" />
  ) : (
    <ArrowDropDownIcon
      data-testid="arrow-drop-down-down-icon"
      color="primary"
    />
  );
};

const handleSort = ({ onRequestSort, keySort, orderBy, isReverseOrder }) => {
  if (keySort === orderBy) {
    onRequestSort(keySort, !isReverseOrder);
  } else {
    onRequestSort(keySort, false);
  }
};

const CustomTable = ({
  meta,
  data = [],
  fieldArray,
  onRequestSort,
  isReverseOrder,
  orderBy,
  fetching,
  refreshTableEvent,
  filteredDatas,
  setFilteredDatas,
  addClusterEquip,
  clusterEquipments,
  handlerQueryDatas,
  pageIndex,
  setPageIndex,
  totalDatas,
  setTotalDatas,
  disableSortableFields,
  setClusterEquipments,
  setChangeClusterPosition,
  options,
  subEquipmentInfoAction
}) => {
  const onDragEnd = (result) => {
    let { destination, source } = result;

    if (destination === null) {
      destination = { index: 0, droppableId: "droppable-table" };
    }

    // Make sure we're actually moving the item
    if (
      source.droppableId === destination.droppableId &&
      destination.index === source.index
    )
      return null;
    const newClusterData = totalDatas[source.index];
    totalDatas.splice(source.index, 1);
    totalDatas.splice(destination.index, 0, newClusterData);
    setChangeClusterPosition(true);
    setClusterEquipments(totalDatas);
  };

  if (!isObject(meta)) return null;
  if (!isObject(meta?.fields)) return null;
  if (!Array.isArray(data)) return null;
  return (
    <TableStyled data-testid="custom-table">
      <TableContainer sx={{ maxHeight: 600 }}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              {Object.keys(meta.fields).map((key) => {
                const HeadCellComponent =
                  meta.fields[key]?.HeadCellComponent ?? Cell;
                const Component = meta?.fields?.[key]?.headProps?.component
                  ? meta?.fields?.[key]?.headProps?.component
                  : null;
                return meta.fields[key]?.sortable && !disableSortableFields ? (
                  <TableCell
                    style={{
                      backgroundColor: `var(--one-color-cobas-gray-150)`,
                      width: meta?.fields?.[key]?.headProps?.style?.width
                        ? meta?.fields?.[key]?.headProps?.style?.width
                        : "inherit"
                    }}
                    data-testid={`table-head-${key}`}
                    key={key}
                    align={meta?.[key]?.prop?.align ?? "left"}
                    onClick={() =>
                      handleSort({
                        onRequestSort,
                        orderBy,
                        isReverseOrder,
                        keySort: key
                      })
                    }
                  >
                    <HeadCellComponent>
                      {meta?.fields?.[key]?.text}
                      {meta?.fields?.[key]?.sortable ? (
                        <DisplaySortingArrow
                          keySort={key}
                          orderBy={orderBy}
                          isReverseOrder={isReverseOrder}
                        />
                      ) : (
                        ""
                      )}
                    </HeadCellComponent>
                  </TableCell>
                ) : (
                  <TableCell
                    style={{
                      backgroundColor: `var(--one-color-cobas-gray-150)`,
                      width: meta?.fields?.[key]?.headProps?.style?.width
                        ? meta?.fields?.[key]?.headProps?.style?.width
                        : "inherit"
                    }}
                    data-testid={`table-head-${key}`}
                    key={key}
                    align={meta?.[key]?.prop?.align ?? "left"}
                  >
                    <HeadCellComponent>
                      {meta?.fields?.[key]?.text}
                      {Component ? (
                        <Component
                          key={key}
                          refreshTableEvent={refreshTableEvent}
                        />
                      ) : (
                        ""
                      )}
                    </HeadCellComponent>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody
            style={{
              maxHeight: meta?.tableProps?.style?.maxBodyHeight
                ? meta?.tableProps?.style?.maxBodyHeight
                : "45vh"
            }}
          >
            {data.length === 0 && !fetching && !meta?.fields?.dragIndicator
              ? ""
              : !fetching &&
                !meta?.fields?.dragIndicator &&
                data.map((item, index) => {
                  const _id = getId(item, meta?.rowId);
                  return (
                    <ItemRow
                      item={item}
                      key={`key-${_id} ?? ${item?.id}`}
                      id={_id ?? item?.id}
                      fields={meta?.fields}
                      fieldArray={fieldArray}
                      options={options}
                      subEquipmentInfoAction={subEquipmentInfoAction}
                    />
                  );
                })}
            {meta?.fields?.dragIndicator && (
              <DragDropContext
                onDragEnd={onDragEnd}
                style={{ overflow: "auto" }}
              >
                <Droppable droppableId="droppable-table">
                  {(provided) => (
                    <DraggableContainer
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {data.length === 0 && !fetching
                        ? ""
                        : !fetching &&
                          data.map((item, idx) => {
                            const _id = getId(item, meta?.rowId);
                            return (
                              <>
                                <Draggable
                                  draggableId={item?.id}
                                  index={idx}
                                  key={item?.id}
                                >
                                  {(provided) => (
                                    <TableRow
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <ItemRow
                                        item={item}
                                        key={`key-${_id} ?? ${item?.id}`}
                                        id={_id ?? item?.id}
                                        fields={meta?.fields}
                                        fieldArray={fieldArray}
                                        count={parseInt(
                                          item?.positionInCluster
                                        )}
                                        options={options}
                                      />
                                    </TableRow>
                                  )}
                                </Draggable>
                              </>
                            );
                          })}
                      {provided.placeholder}
                    </DraggableContainer>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TableFooter style={{ display: "flex", flexDirection: "column" }}>
        <>
          <div>
            {fetching ? (
              <div
                className="loading"
                style={{
                  height: 200,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <CircularProgress size={30} />
              </div>
            ) : (
              ""
            )}
          </div>
          <div>
            {data.length === 0 && !fetching ? (
              <OwcTypography
                variant="title6"
                data-testid="custom-list-no-data"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "100%"
                }}
              >
                No data
              </OwcTypography>
            ) : (
              ""
            )}
          </div>
          <PaginationActions
            filteredDatas={filteredDatas}
            options={options}
            addClusterEquip={addClusterEquip}
            setFilteredDatas={setFilteredDatas}
            handlerQueryDatas={handlerQueryDatas}
            pageIndex={pageIndex}
            clusterEquipments={clusterEquipments}
            setPageIndex={setPageIndex}
            totalDatas={totalDatas}
            setTotalDatas={setTotalDatas}
          />
        </>
      </TableFooter>
    </TableStyled>
  );
};
export const valCheck = (key, item) => {
  if (
    typeof item[key] === "object" &&
    item[key] !== null &&
    item[key]?.value !== null &&
    item[key]?.value !== "" &&
    !Array.isArray(item[key]) &&
    !Array.isArray(item[key].value)
  ) {
    return item[key]?.value;
  } else if (
    typeof item[key] === "string" &&
    item[key] !== null &&
    item[key] !== "" &&
    !Array.isArray(item[key])
  ) {
    if (key === "dateOfNextMaintanance") {
      return (
        <span>
          {item?.dateOfNextMaintanance
            ? rearangeDisplayDate(item?.dateOfNextMaintanance)
            : "-"}
        </span>
      );
    }
    if (key === "dateOfLastMaintanance") {
      return (
        <span>
          {item?.dateOfLastMaintanance
            ? rearangeDisplayDate(item?.dateOfLastMaintanance)
            : "-"}
        </span>
      );
    }
    if (key === "dateOfNextPeriodicReview") {
      return (
        <span>
          {item?.dateOfNextPeriodicReview
            ? rearangeDisplayDate(item?.dateOfNextPeriodicReview)
            : "-"}
        </span>
      );
    }
    if (GXP_DROP_CHECK.includes(key)) {
      return getEnumValue(key, item[key]);
    }
    return item[key] === "null" ? "-" : item[key];
  } else if (
    typeof item[key] === "boolean" &&
    item[key] !== null &&
    item[key] !== "" &&
    !Array.isArray(item[key])
  ) {
    if (key === "isBookable") {
      return item.isBookable ? (
        <RegularCell>Yes</RegularCell>
      ) : (
        <RegularCell>
          <FalseAnswerSpan>No</FalseAnswerSpan>
        </RegularCell>
      );
    } else {
      return item[key] ? "Shown" : "Not shown";
    }
  } else if (Array.isArray(item[key])) {
    return map(item[key], "value")?.join(", ") || "-";
  } else if (Array.isArray(item[key]?.value) && item[key]?.value.length > 0) {
    let strName = "name: ";
    let strDocument = "documentId: ";
    let strNameDocIdTooltip = "";
    item[key]?.value.forEach((CurrentVal, index) => {
      strName = strName + CurrentVal.name;
      strDocument = strDocument + CurrentVal.documentId;
      strNameDocIdTooltip =
        strNameDocIdTooltip + CurrentVal.name + " " + CurrentVal.documentId;
      if (index < item[key]?.value.length - 1) {
        strName += ", ";
        strDocument += ", ";
        strNameDocIdTooltip += ", ";
      }
    });
    return [strName, strDocument, strNameDocIdTooltip];
  } else if (Array.isArray(item[key]) && item[key].length > 0) {
    let strName = "name: ";
    let strVersion = "version: ";
    let strNameVersionTooltip = "";
    item[key].forEach((CurrentVal, index) => {
      strName = strName + CurrentVal.name;
      strVersion = strVersion + CurrentVal.version;
      strNameVersionTooltip =
        strNameVersionTooltip + CurrentVal.name + " " + CurrentVal.version;
      if (index < item[key].length - 1) {
        strName += ", ";
        strVersion += ", ";
        strNameVersionTooltip += ", ";
      }
    });
    return [strName, strVersion, strNameVersionTooltip];
  } else if (
    typeof item[key] === "number" &&
    item[key] !== null &&
    item[key] !== "" &&
    !Array.isArray(item[key])
  ) {
    return item[key] === "null" ? "-" : item[key];
  } else {
    return "-";
  }
};
const TableOfContentIcon = ({ subEquipmentInfoAction, item }) => {
  return (
    <CustomIcon
      id={`popup-anchor-${item.id}`}
      onClick={() => {
        subEquipmentInfoAction(item);
      }}
    >
      {/* <OwcIcon name="table_of_content" type="legacy" /> */}
    </CustomIcon>
  );
};
export const ItemRow = ({
  item,
  fieldArray,
  fields,
  id,
  count,
  options,
  subEquipmentInfoAction = null
}) => {
  item.isExpanded = false;

  const [collapseOpen, setCollapseOpen] = useState(item.isExpanded);
  const [isClusterDetailExpanded, setIsClusterDetailExpanded] = useState(false);

  const triggerSetCollapse = (val) => {
    setCollapseOpen(val);
  };
  const triggerClusterSetCollapse = (val) => {
    setIsClusterDetailExpanded(val);
  };
  return (
    <>
      <TableRow
        data-testid={`${id}-row`}
        className={`checkRow-${item?.entryType} ${
          item?.status === equipmentStatus?.deleted?.key
            ? "deleteEquip"
            : "" || item?.entryType === entryType?.cluster
            ? "cluster"
            : ""
        }`}
      >
        {Object.keys(fields).map((field, index) => {
          const Component = fields?.[field]?.component
            ? fields?.[field].component
            : null;
          if (!Component)
            return (
              <TableCell
                align="left"
                key={`item-row-${id}-${field}`}
                data-testid={`item-row-${id}-${field}`}
                style={{
                  width: fields?.[field]?.cellProps?.style?.width
                    ? fields?.[field]?.cellProps?.style?.width
                    : "inherit"
                }}
                className="itemRow rowheight"
              >
                <Tooltip
                  title={(() => {
                    if (
                      item[field]?.value !== null &&
                      item[field]?.value !== "" &&
                      item[field]?.value !== undefined
                    ) {
                      return item[field]?.value;
                    } else if (
                      item[field] !== null &&
                      item[field] !== "" &&
                      typeof item[field] === "string"
                    ) {
                      if (GXP_DROP_CHECK.includes(field)) {
                        return getEnumValue(field, item[field]);
                      }
                      return item[field] === "null" ? "-" : item[field];
                    } else if (
                      item[field] !== null &&
                      item[field] !== "" &&
                      typeof item[field] === "number"
                    ) {
                      return item[field] === "null" ? "-" : item[field];
                    } else if (
                      Array.isArray(item[field]) &&
                      item[field].length > 0
                    ) {
                      return valCheck(field, item);
                    } else {
                      return "-";
                    }
                  })()}
                >
                  <Cell>
                    {!options?.actions?.hideSubEqupClusterIcon &&
                      item.entryType === entryType.clusterSubequipment &&
                      index === 0 && (
                        <TableOfContentIcon
                          item={item}
                          subEquipmentInfoAction={subEquipmentInfoAction}
                        />
                      )}
                    <CellValue>
                      {(() => {
                        if (field === "positionInCluster") {
                          return count;
                        } else if (
                          item[field]?.value !== null &&
                          item[field]?.value !== "" &&
                          item[field]?.value !== undefined
                        ) {
                          return item[field]?.value;
                        } else if (
                          item[field] !== null &&
                          item[field] !== "" &&
                          typeof item[field] === "string"
                        ) {
                          if (GXP_DROP_CHECK.includes(field)) {
                            return getEnumValue(field, item[field]);
                          }
                          return item[field] === "null" ? "-" : item[field];
                        } else if (
                          item[field] !== null &&
                          item[field] !== "" &&
                          typeof item[field] === "number"
                        ) {
                          return item[field] === "null" ? "-" : item[field];
                        } else if (
                          Array.isArray(item[field]) &&
                          item[field].length > 0
                        ) {
                          return valCheck(field, item);
                        } else {
                          return "-";
                        }
                      })()}
                    </CellValue>
                  </Cell>
                </Tooltip>
              </TableCell>
            );
          return (
            <TableCell
              align="left"
              key={`item-row-${id}-${field}`}
              data-testid={`item-row-${id}-${field}`}
              style={{
                width: fields?.[field]?.cellProps?.style?.width
                  ? fields?.[field]?.cellProps?.style?.width
                  : "inherit"
              }}
            >
              {item.entryType === entryType.clusterSubequipment &&
              (index === 1 || index === 0) ? (
                <CustomDivFlex>
                  <Component
                    item={item}
                    {...(field?.prop ?? {})}
                    triggerSetCollapse={triggerSetCollapse}
                    triggerClusterSetCollapse={triggerClusterSetCollapse}
                  />
                  {!options?.actions?.hideSubEqupClusterIcon &&
                    Object.keys(fields).includes("checkbox") &&
                    index === 1 &&
                    item.entryType === entryType.clusterSubequipment && (
                      <TableOfContentIcon
                        item={item}
                        subEquipmentInfoAction={subEquipmentInfoAction}
                      />
                    )}
                  {!options?.actions?.hideSubEqupClusterIcon &&
                    !Object.keys(fields).includes("checkbox") &&
                    index === 0 &&
                    item.entryType === entryType.clusterSubequipment && (
                      <TableOfContentIcon
                        item={item}
                        subEquipmentInfoAction={subEquipmentInfoAction}
                      />
                    )}
                </CustomDivFlex>
              ) : (
                <Component
                  item={item}
                  {...(field?.prop ?? {})}
                  triggerSetCollapse={triggerSetCollapse}
                  triggerClusterSetCollapse={triggerClusterSetCollapse}
                />
              )}
            </TableCell>
          );
        })}
      </TableRow>
      {fieldArray && fieldArray.length > 0 && (
        <TableRow
          className={`${
            item?.status === equipmentStatus?.deleted?.key ? "deleteEquip" : ""
          }`}
        >
          <TableCell className="tablecell" colSpan={9}>
            <Collapse in={collapseOpen}>
              {collapseOpen ? (
                <Box margin={1}>
                  <Typography variant="h6" gutterBottom component="div">
                    {fieldArray.map((label, index) => {
                      return (
                        <CustomCell>
                          <CellKey>
                            <Tooltip title={label.val} placement="bottom-start">
                              <div
                                style={{
                                  maxWidth: 140,
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  WebkitLineClamp: 1,
                                  display: "-webkit-box",
                                  WebkitBoxOrient: "vertical",
                                  wordBreak: "break-all"
                                }}
                              >
                                {label.val}
                              </div>
                            </Tooltip>
                            <div>:</div>
                          </CellKey>

                          <CellValue>
                            {(() => {
                              if (label.key === "installedTests") {
                                return (
                                  <>
                                    <Tooltip
                                      title={
                                        item?.installedTests?.length > 0
                                          ? item?.installedTests?.length
                                          : "-"
                                      }
                                      placement="bottom-start"
                                    >
                                      <CellValue>
                                        {item?.installedTests?.length > 0
                                          ? item?.installedTests?.length
                                          : "-"}
                                      </CellValue>
                                    </Tooltip>
                                  </>
                                );
                              } else if (
                                label.key === "qualificationDocuments"
                              ) {
                                return (
                                  <>
                                    <Tooltip
                                      title={
                                        item?.qualificationDocuments?.value
                                          ?.length > 0
                                          ? item?.qualificationDocuments?.value
                                              ?.length
                                          : "-"
                                      }
                                      placement="bottom-start"
                                    >
                                      <CellValue>
                                        {item?.qualificationDocuments?.value
                                          ?.length > 0
                                          ? item?.qualificationDocuments?.value
                                              ?.length
                                          : "-"}
                                      </CellValue>
                                    </Tooltip>
                                  </>
                                );
                              } else {
                                return (
                                  <Tooltip
                                    title={valCheck(label.key, item)}
                                    placement="bottom-start"
                                  >
                                    <CellValue>
                                      {valCheck(label.key, item)}
                                    </CellValue>
                                  </Tooltip>
                                );
                              }
                            })()}
                          </CellValue>
                        </CustomCell>
                      );
                    })}
                  </Typography>
                </Box>
              ) : (
                <></>
              )}
            </Collapse>
          </TableCell>
        </TableRow>
      )}

      {item?.entryType === entryType?.cluster &&
        Object.keys(item?.subEquipment).length > 0 &&
        Object.keys(item?.subEquipment?.items).map((subItem) => (
          <ClusterItemRow
            item={item?.subEquipment?.items?.[subItem]}
            fieldArray={fieldArray}
            fields={fields}
            id={id}
            isClusterDetailExpanded={isClusterDetailExpanded}
            options={options}
            subEquipmentInfoAction={subEquipmentInfoAction}
          />
        ))}
    </>
  );
};

export const ClusterItemRow = ({
  item,
  fieldArray,
  fields,
  id,
  isClusterDetailExpanded,
  triggerClusterSetCollapse,
  options,
  subEquipmentInfoAction = null
}) => {
  item.isExpanded = false;
  const [collapseOpen, setCollapseOpen] = useState(item.isExpanded);

  const triggerSetCollapse = (val) => {
    setCollapseOpen(val);
  };
  useEffect(() => {
    if (!isClusterDetailExpanded && collapseOpen) {
      setCollapseOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClusterDetailExpanded]);

  return (
    <>
      <Collapse in={isClusterDetailExpanded}>
        <TableRow
          data-testid={`${id}-row`}
          className={`cluster-row ${
            item?.status === equipmentStatus?.deleted?.key ? "deleteEquip" : ""
          }`}
        >
          {Object.keys(fields).map((field, index) => {
            const Component = fields?.[field]?.component
              ? fields?.[field].component
              : null;
            if (!Component)
              return (
                <TableCell
                  align="left"
                  key={`item-row-${id}-${field}`}
                  data-testid={`item-row-${id}-${field}`}
                  style={{
                    width: fields?.[field]?.cellProps?.style?.width
                      ? fields?.[field]?.cellProps?.style?.width
                      : "inherit"
                  }}
                  className="itemRow rowheight"
                >
                  <Tooltip
                    title={(() => {
                      if (
                        item[field]?.value !== null &&
                        item[field]?.value !== "" &&
                        item[field]?.value !== undefined
                      ) {
                        return item[field]?.value;
                      } else if (
                        item[field] !== null &&
                        item[field] !== "" &&
                        typeof item[field] === "string"
                      ) {
                        if (GXP_DROP_CHECK.includes(field)) {
                          return getEnumValue(field, item[field]);
                        }
                        return item[field] === "null" ? "-" : item[field];
                      } else if (
                        item[field] !== null &&
                        item[field] !== "" &&
                        typeof item[field] === "number"
                      ) {
                        return item[field] === "null" ? "-" : item[field];
                      } else if (
                        Array.isArray(item[field]) &&
                        item[field].length > 0
                      ) {
                        return valCheck(field, item);
                      } else {
                        return "-";
                      }
                    })()}
                  >
                    <Cell>
                      {!options?.actions?.hideSubEqupClusterIcon &&
                        Object.keys(fields).includes("checkbox") &&
                        index === 1 && (
                          <TableOfContentIcon
                            item={item}
                            subEquipmentInfoAction={subEquipmentInfoAction}
                          />
                        )}
                      {!options?.actions?.hideSubEqupClusterIcon &&
                        !Object.keys(fields).includes("checkbox") &&
                        index === 0 && (
                          <TableOfContentIcon
                            item={item}
                            subEquipmentInfoAction={subEquipmentInfoAction}
                          />
                        )}
                      <CellValue>
                        {(() => {
                          if (
                            item[field]?.value !== null &&
                            item[field]?.value !== "" &&
                            item[field]?.value !== undefined
                          ) {
                            return item[field]?.value;
                          } else if (
                            item[field] !== null &&
                            item[field] !== "" &&
                            typeof item[field] === "string"
                          ) {
                            if (GXP_DROP_CHECK.includes(field)) {
                              return getEnumValue(field, item[field]);
                            }
                            return item[field] === "null" ? "-" : item[field];
                          } else if (
                            item[field] !== null &&
                            item[field] !== "" &&
                            typeof item[field] === "number"
                          ) {
                            return item[field] === "null" ? "-" : item[field];
                          } else if (
                            Array.isArray(item[field]) &&
                            item[field].length > 0
                          ) {
                            return valCheck(field, item);
                          } else {
                            return "-";
                          }
                        })()}
                      </CellValue>
                    </Cell>
                  </Tooltip>
                </TableCell>
              );
            return (
              <TableCell
                align="left"
                key={`item-row-${id}-${field}`}
                data-testid={`item-row-${id}-${field}`}
                style={{
                  width: fields?.[field]?.cellProps?.style?.width
                    ? fields?.[field]?.cellProps?.style?.width
                    : "inherit"
                }}
              >
                {item.entryType === entryType.clusterSubequipment &&
                (index === 1 || index === 0) ? (
                  <CustomDivFlex>
                    <Component
                      item={item}
                      {...(field?.prop ?? {})}
                      triggerSetCollapse={triggerSetCollapse}
                      triggerClusterSetCollapse={triggerClusterSetCollapse}
                    />
                    {!options?.actions?.hideSubEqupClusterIcon &&
                      Object.keys(fields).includes("checkbox") &&
                      index === 1 && (
                        <TableOfContentIcon
                          item={item}
                          subEquipmentInfoAction={subEquipmentInfoAction}
                        />
                      )}
                    {!options?.actions?.hideSubEqupClusterIcon &&
                      !Object.keys(fields).includes("checkbox") &&
                      index === 0 && (
                        <TableOfContentIcon
                          item={item}
                          subEquipmentInfoAction={subEquipmentInfoAction}
                        />
                      )}
                  </CustomDivFlex>
                ) : (
                  <Component
                    item={item}
                    {...(field?.prop ?? {})}
                    triggerSetCollapse={triggerSetCollapse}
                    triggerClusterSetCollapse={triggerClusterSetCollapse}
                  />
                )}
              </TableCell>
            );
          })}
        </TableRow>
      </Collapse>
      <Collapse in={collapseOpen}>
        {fieldArray && fieldArray.length > 0 && (
          <TableRow
            className={`${
              item?.status === equipmentStatus?.deleted?.key
                ? "deleteEquip"
                : ""
            }`}
          >
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
              <Collapse in={true}>
                <Box margin={1}>
                  <Typography variant="h6" gutterBottom component="div">
                    {fieldArray.map((label, index) => {
                      return (
                        <CustomCell>
                          <CellKey>
                            <Tooltip title={label.val} placement="bottom-start">
                              <div
                                style={{
                                  maxWidth: 140,
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  WebkitLineClamp: 1,
                                  display: "-webkit-box",
                                  WebkitBoxOrient: "vertical",
                                  wordBreak: "break-all"
                                }}
                              >
                                {label.val}
                              </div>
                            </Tooltip>
                            <div>:</div>
                          </CellKey>

                          <CellValue>
                            {(() => {
                              if (label.key === "installedTests") {
                                return (
                                  <>
                                    <Tooltip
                                      title={
                                        item?.installedTests?.length > 0
                                          ? item?.installedTests?.length
                                          : "-"
                                      }
                                      placement="bottom-start"
                                    >
                                      <CellValue>
                                        {item?.installedTests?.length > 0
                                          ? item?.installedTests?.length
                                          : "-"}
                                      </CellValue>
                                    </Tooltip>
                                  </>
                                );
                              } else if (
                                label.key === "qualificationDocuments"
                              ) {
                                return (
                                  <>
                                    <Tooltip
                                      title={
                                        item?.qualificationDocuments?.value
                                          ?.length > 0
                                          ? item?.qualificationDocuments?.value
                                              ?.length
                                          : "-"
                                      }
                                      placement="bottom-start"
                                    >
                                      <CellValue>
                                        {item?.qualificationDocuments?.value
                                          ?.length > 0
                                          ? item?.qualificationDocuments?.value
                                              ?.length
                                          : "-"}
                                      </CellValue>
                                    </Tooltip>
                                  </>
                                );
                              } else {
                                return (
                                  <Tooltip
                                    title={valCheck(label.key, item)}
                                    placement="bottom-start"
                                  >
                                    <CellValue>
                                      {valCheck(label.key, item)}
                                    </CellValue>
                                  </Tooltip>
                                );
                              }
                            })()}
                          </CellValue>
                        </CustomCell>
                      );
                    })}
                  </Typography>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        )}
      </Collapse>
    </>
  );
};

export default CustomTable;
