import { find, isBoolean, isEmpty } from "lodash";
import styled from "styled-components";
import { get } from "underscore";
import { DetailsCellStyled } from "./DetailsCellStyled";
import {
  DetailsCellsWrapperStyled,
  ReviewDetailsCellsWrapperStyled
} from "./DetailsCellsWrapperStyled";
import {
  rearangeDisplayDate,
  validateSelection
} from "./../../../utils/helpers/text/index";
import {
  SHOW_SUB_CLUSTER_KEY,
  AUDIT_SUB_EQUIPMENT_CLUSTER_TYPE,
  emptyClusterInformation,
  GXPemptyInstrumentsOptions,
  EQUIPMENT_STATUS_FIELD,
  AUDIT_ACTIONS
} from "../../../constants";
import { useSelector } from "react-redux";
import DATA_MODEL_TABLE from "../../../utils/constants/dataModelTable";
import { OwcTooltip } from "@one/react";

const ChangesCellContainer = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: #000000;
`;

const ClusterCellOld = styled.div`
  min-width: 850px;
  width: 850px;
  text-decoration: line-through;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
`;

const ClusterCellNew = styled.div`
  min-width: 850px;
  width: 850px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
`;

const ChangesCellOld = styled.div`
  max-width: 50%;
  margin-right: 10px;
  text-decoration: line-through;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ChangesCellNew = styled.div`
  max-width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const NoValueCell = styled.span`
  max-width: 50%;
  margin: 0 10px;
`;

const SubEqCellStyled = styled.div`
  color: var(--one-color-gray-800);
  background-color: var(--one-color-gray-50);
  border-radius: 4px;
  padding: 16px 0px 16px 16px;
`;

const WrapperStyled = styled.div`
  width: 100%;
  background-color: var(--one-color-gray-50);
  border-radius: 4px;
  border: thin solid var(--one-color-gray-300);
`;

const AuditTrialProposedTitle = styled.span`
  font-size: 14px;
  line-height: 23px;
  font-weight: 500;
  color: #000000;
  padding: 20px 16px 20px 16px;
`;

const SingleDetailsCell = ({ cellValue }) => {
  if (isBoolean(cellValue?.oldvalue)) {
    cellValue.oldvalue = String(cellValue?.oldvalue);
  }
  if (isBoolean(cellValue?.newvalue)) {
    cellValue.newvalue = String(cellValue?.newvalue);
  }
  return (
    <>
      <ChangesCellContainer>
        {cellValue?.oldvalue && cellValue?.oldvalue !== "null" ? (
          <>
            <ChangesCellOld id={cellValue.oldvalue}>
              {cellValue.oldvalue}
            </ChangesCellOld>
            <OwcTooltip anchor={cellValue.oldvalue}>
              {cellValue.oldvalue}
            </OwcTooltip>
          </>
        ) : (
          <NoValueCell>-</NoValueCell>
        )}
        {cellValue?.newvalue && cellValue?.newvalue !== "null" ? (
          <>
            <ChangesCellNew id={cellValue.newvalue}>
              {cellValue.newvalue}
            </ChangesCellNew>
            <OwcTooltip anchor={cellValue.newvalue}>
              {cellValue.newvalue}
            </OwcTooltip>
          </>
        ) : (
          <NoValueCell>-</NoValueCell>
        )}
      </ChangesCellContainer>
    </>
  );
};

const ReviewSingleDetailsCell = ({ cellValue, tooltipString = "" }) => {
  if (isBoolean(cellValue?.oldvalue)) {
    cellValue.oldvalue = String(cellValue?.oldvalue);
  }
  if (isBoolean(cellValue?.newvalue)) {
    cellValue.newvalue = String(cellValue?.newvalue);
  }
  return (
    <>
      <ChangesCellContainer style={{ flexDirection: "column" }}>
        {cellValue?.value ? (
          <div id={tooltipString}>
            <strong>
              {cellValue?.value === "null" ? "-" : `${cellValue?.value}...`}
            </strong>
            <OwcTooltip anchor={tooltipString}>{tooltipString}</OwcTooltip>
          </div>
        ) : (
          <>
            {cellValue?.newvalue && cellValue?.newvalue !== "null" ? (
              <>
                <ChangesCellNew
                  id={cellValue.newvalue}
                  style={{
                    color: "var(--one-color-accent-quinary-3)",
                    Size: "16px"
                  }}
                >
                  {cellValue.newvalue}
                </ChangesCellNew>
                <OwcTooltip anchor={cellValue.newvalue}>
                  {cellValue.newvalue}
                </OwcTooltip>
              </>
            ) : (
              <NoValueCell>-</NoValueCell>
            )}
            {cellValue?.oldvalue && cellValue?.oldvalue !== "null" ? (
              <>
                <ChangesCellOld
                  id={cellValue.oldvalue}
                  style={{
                    color: "var(--one-color-accent-secondary-4)",
                    Size: "16px"
                  }}
                >
                  {cellValue.oldvalue}
                </ChangesCellOld>
                <OwcTooltip anchor={cellValue.oldvalue}>
                  {cellValue.oldvalue}
                </OwcTooltip>
              </>
            ) : (
              <NoValueCell>-</NoValueCell>
            )}
          </>
        )}
      </ChangesCellContainer>
    </>
  );
};
const MultiLineDetailsCell = ({ cellValue }) => {
  return (
    <>
      {Array.isArray(cellValue)
        ? cellValue.map((value) => {
            return <SingleDetailsCell cellValue={value} />;
          })
        : ""}
    </>
  );
};

const ReviewMultiLineDetailsCell = ({ cellValue, label }) => {
  let tooltipString = "";
  cellValue.forEach((item) => {
    tooltipString = item?.value ? tooltipString + `${item?.value}, ` : "";
  });
  return (
    <>
      {Array.isArray(cellValue) ? (
        <>
          {cellValue?.[0]?.value &&
          (label === DATA_MODEL_TABLE.installedTests.value ||
            label === DATA_MODEL_TABLE.sop.value ||
            label === DATA_MODEL_TABLE.qualificationDocuments.value) ? (
            <>
              <ReviewSingleDetailsCell
                cellValue={cellValue?.[0]}
                tooltipString={tooltipString}
              />
            </>
          ) : (
            <>
              {cellValue.map((value) => {
                return <ReviewSingleDetailsCell cellValue={value} />;
              })}
            </>
          )}
        </>
      ) : (
        ""
      )}
    </>
  );
};

const DetailsCell = ({
  label,
  cellValue,
  isAuditTrial = false,
  Wrapper,
  isReview = false,
  ...props
}) => {
  return (
    <>
      {cellValue && (
        <Wrapper {...props}>
          <div data-testid="details-cell-label">{label}</div>
          <div>
            {isReview ? (
              <>
                {typeof cellValue !== "string" &&
                typeof cellValue !== "boolean" ? (
                  <>
                    {props?.multiLine ? (
                      <>
                        <ReviewMultiLineDetailsCell
                          cellValue={cellValue === "null" ? "" : cellValue}
                          label={label}
                        />
                      </>
                    ) : (
                      <>
                        <ReviewSingleDetailsCell
                          cellValue={cellValue === "null" ? "" : cellValue}
                        />
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <strong>
                      {cellValue === "null" ? "" : cellValue.toString()}
                    </strong>
                  </>
                )}
              </>
            ) : (
              <>
                {isAuditTrial ? (
                  props?.multiLine ? (
                    <>
                      <MultiLineDetailsCell
                        cellValue={cellValue === "null" ? "" : cellValue}
                      />
                    </>
                  ) : (
                    <>
                      <SingleDetailsCell
                        cellValue={cellValue === "null" ? "" : cellValue}
                      />
                    </>
                  )
                ) : (
                  <strong>{cellValue === "null" ? "" : cellValue}</strong>
                )}
              </>
            )}
          </div>
        </Wrapper>
      )}
    </>
  );
};

const SubClusterCell = ({
  items,
  relevantPositionState,
  prefixString = null
}) => {
  let newArrItems = [];
  items.forEach((item) => {
    if (item?.type === AUDIT_SUB_EQUIPMENT_CLUSTER_TYPE.Deleted) {
      newArrItems = [item, ...newArrItems];
    } else {
      newArrItems = [...newArrItems, item];
    }
  });
  return (
    <div
      style={{ maxHeight: "200px", overflow: "auto" }}
      id="ClusterDecommission-ClusterDetailsCells-FrameWithBackground"
    >
      {newArrItems.map((item, index) => {
        let deleteDivCollector = "";
        let addDivCollector = "";
        let newUpdateDiv = "";
        let oldUpdateDiv = "";

        if (item.type === AUDIT_SUB_EQUIPMENT_CLUSTER_TYPE.Deleted) {
          if (relevantPositionState) {
            deleteDivCollector += `Position ${
              item?.positionInCluster !== null &&
              item?.positionInCluster !== "undefined" &&
              item?.positionInCluster !== "null"
                ? item?.positionInCluster
                : " -"
            }: `;
          }
          SHOW_SUB_CLUSTER_KEY.forEach((clusterItem, indexItem) => {
            deleteDivCollector += `${clusterItem?.label}:${
              item?.oldvalue?.[clusterItem?.key] !== null &&
              item?.oldvalue?.[clusterItem?.key] !== "undefined" &&
              item?.oldvalue?.[clusterItem?.key] !== "null"
                ? item?.oldvalue?.[clusterItem?.key]
                : " -"
            }${indexItem < SHOW_SUB_CLUSTER_KEY.length - 1 ? ", " : ""}`;
          });
        } else if (item.type === AUDIT_SUB_EQUIPMENT_CLUSTER_TYPE.Created) {
          if (relevantPositionState && item?.positionInCluster) {
            addDivCollector += `Position ${
              item?.positionInCluster !== null &&
              item?.positionInCluster !== "undefined" &&
              item?.positionInCluster !== "null"
                ? item?.positionInCluster
                : " -"
            }: `;
          }
          SHOW_SUB_CLUSTER_KEY.forEach((clusterItem, indexItem) => {
            addDivCollector += `${clusterItem?.label}:${
              item?.newvalue?.[clusterItem?.key] !== null &&
              item?.newvalue?.[clusterItem?.key] !== "undefined" &&
              item?.newvalue?.[clusterItem?.key] !== "null"
                ? item?.newvalue?.[clusterItem?.key]
                : " -"
            }${indexItem < SHOW_SUB_CLUSTER_KEY.length - 1 ? ", " : ""}`;
          });
        } else if (item.type === AUDIT_SUB_EQUIPMENT_CLUSTER_TYPE.Updated) {
          if (relevantPositionState) {
            newUpdateDiv += `Position ${
              item?.newvalue?.positionInCluster !== null &&
              item?.newvalue?.positionInCluster !== "undefined" &&
              item?.newvalue?.positionInCluster !== "null"
                ? item?.newvalue?.positionInCluster
                : " "
            }: Sub-component- ${
              item?.newvalue?.positionInCluster !== null &&
              item?.newvalue?.positionInCluster !== "undefined" &&
              item?.newvalue?.positionInCluster !== "null"
                ? item?.newvalue?.positionInCluster
                : " "
            }`;
            oldUpdateDiv += `Position ${
              item?.oldvalue?.positionInCluster !== null &&
              item?.oldvalue?.positionInCluster !== "undefined" &&
              item?.oldvalue?.positionInCluster !== "null"
                ? item?.oldvalue?.positionInCluster
                : " "
            }: Sub-component- ${
              item?.newvalue?.positionInCluster !== null &&
              item?.newvalue?.positionInCluster !== "undefined" &&
              item?.newvalue?.positionInCluster !== "null"
                ? item?.newvalue?.positionInCluster
                : " "
            }`;
          }

          SHOW_SUB_CLUSTER_KEY.forEach((clusterItem, indexItem) => {
            newUpdateDiv += `${clusterItem?.label}:${
              item?.newvalue?.[clusterItem?.key] !== null &&
              item?.newvalue?.[clusterItem?.key] !== "undefined" &&
              item?.newvalue?.[clusterItem?.key] !== "null"
                ? item?.newvalue?.[clusterItem?.key]
                : " -"
            }${indexItem < SHOW_SUB_CLUSTER_KEY.length - 1 ? ", " : ""}`;

            oldUpdateDiv += `${clusterItem?.label}:${
              item?.oldvalue?.[clusterItem?.key] !== null &&
              item?.oldvalue?.[clusterItem?.key] !== "undefined" &&
              item?.oldvalue?.[clusterItem?.key] !== "null"
                ? item?.oldvalue?.[clusterItem?.key]
                : " -"
            }${indexItem < SHOW_SUB_CLUSTER_KEY.length - 1 ? ", " : ""}`;
          });
        }
        return (
          <>
            <ClusterCellOld id={deleteDivCollector}>
              {prefixString && deleteDivCollector && `${prefixString} : `}
              {deleteDivCollector}
            </ClusterCellOld>
            <OwcTooltip anchor={deleteDivCollector}>
              {deleteDivCollector}
            </OwcTooltip>

            <ClusterCellNew id={addDivCollector}>
              {prefixString && addDivCollector && `${prefixString} : `}
              {addDivCollector}
            </ClusterCellNew>
            <OwcTooltip anchor={addDivCollector}>{addDivCollector}</OwcTooltip>

            <ClusterCellOld id={oldUpdateDiv}>
              {prefixString && oldUpdateDiv && `${prefixString} : `}
              {oldUpdateDiv}
            </ClusterCellOld>
            <OwcTooltip anchor={oldUpdateDiv}>{oldUpdateDiv}</OwcTooltip>

            <ClusterCellNew id={newUpdateDiv}>
              {prefixString && newUpdateDiv && `${prefixString} : `}
              {newUpdateDiv}
            </ClusterCellNew>
            <OwcTooltip anchor={newUpdateDiv}>{newUpdateDiv}</OwcTooltip>
          </>
        );
      })}
    </div>
  );
};

export const ReviewDetailsCells = ({
  changedItem,
  item,
  infoMeta,
  displayColumns,
  Wrapper = ReviewDetailsCellsWrapperStyled,
  CellWrapper = DetailsCellStyled
}) => {
  let changeFieldConfig = infoMeta.fields;
  const linkedInstanceList = useSelector(
    (state) => state.instruments?.linkedInstanceList
  );
  const getChangedValuesArray = (changedItemConfig, changes) => {
    if (
      changedItemConfig?.field === "dateOfNextMaintanance" ||
      changedItemConfig?.field === "dateOfLastMaintanance" ||
      changedItemConfig?.field === "dateOfNextPeriodicReview"
    ) {
      return {
        oldvalue: changes?.oldvalue
          ? rearangeDisplayDate(changes?.oldvalue)
          : null,
        newvalue: changes?.newvalue
          ? rearangeDisplayDate(changes?.newvalue)
          : null
      };
    }
    if (
      changedItemConfig?.field === "room" ||
      changedItemConfig?.field === "floor" ||
      changedItemConfig?.field === "buildingLocation" ||
      changedItemConfig?.field === "module"
    ) {
      if (changes?.value) {
        return changes?.value || null;
      } else {
        if (
          changes?.oldvalue?.value === changes?.newvalue?.value ||
          (!changes?.oldvalue?.value && !changes?.newvalue?.value)
        ) {
          return null;
        }
        return {
          oldvalue: changes?.oldvalue?.value,
          newvalue: changes?.newvalue?.value
        };
      }
    }
    if (changedItemConfig?.field === "linkedInstance") {
      const oldvalue = find(linkedInstanceList, {
        linkedInstance: changes?.oldvalue
      });
      const newvalue = find(linkedInstanceList, {
        linkedInstance: changes?.newvalue
      });
      return {
        oldvalue: oldvalue?.linkedInstanceDisplay,
        newvalue: newvalue?.linkedInstanceDisplay
      };
    }
    if (changedItemConfig?.field === "sop") {
      const sopChanges = getModifiedChanges(changes, "value");
      return sopChanges;
    }
    if (changedItemConfig?.field === "qualificationDocuments") {
      if (changes?.value) {
        const docChanges = getModifiedChanges(
          changes?.value,
          "name",
          "documentId"
        );
        return docChanges;
      }
    }

    if (changedItemConfig?.field === "qualificationStatus") {
      const oldValue = validateSelection(
        GXPemptyInstrumentsOptions.qualificationStatus,
        { key: changes?.oldvalue }
      );

      const newvalue = validateSelection(
        GXPemptyInstrumentsOptions.qualificationStatus,
        { key: changes?.newvalue }
      );
      return {
        oldvalue: oldValue,
        newvalue: newvalue
      };
    }
    if (changedItemConfig?.field === "installedTests") {
      const testChanges = getModifiedChanges(changes, "version", "name");
      return testChanges;
    }

    if (
      changedItemConfig?.field === "inventoryNumber" ||
      changedItemConfig?.field === "maintenanceIntervalInDays"
    ) {
      return {
        oldvalue: changes?.oldvalue?.toString(),
        newvalue: changes?.newvalue?.toString()
      };
    }

    return changes;
  };

  return (
    <Wrapper
      data-testid="audit-trail-details-cells-wrapper"
      style={{ width: "96%", margin: "auto" }}
    >
      {displayColumns.map((fieldId) => {
        let currentItemConfig = find(changeFieldConfig, {
          field: fieldId
        });
        let changedItemValues = null;
        if (changedItem[fieldId]) {
          changedItemValues = getChangedValuesArray(
            currentItemConfig,
            changedItem[fieldId]
          );
        }
        if (changedItem[fieldId] && changedItemValues) {
          if (fieldId === DATA_MODEL_TABLE.tags.key) {
            return (
              <div
                style={{
                  marginRight: "40px",
                  marginBottom: "20px",
                  width: "16%"
                }}
              >
                <>
                  <div
                    data-testid="details-cell-label"
                    id={DATA_MODEL_TABLE.tags.key}
                  >
                    {DATA_MODEL_TABLE.tags.value}
                  </div>
                  <OwcTooltip anchor={DATA_MODEL_TABLE.tags.key}>
                    {DATA_MODEL_TABLE.tags.value}
                  </OwcTooltip>
                </>
                <div
                  style={{
                    fontSize: "14px",
                    lineHeight: "18px",
                    fontWeight: "500",
                    color: "#000000",
                    width: "16%"
                  }}
                >
                  <TagLogComponent item={changedItem[fieldId]} />
                </div>
              </div>
            );
          }

          return (
            <>
              {currentItemConfig ? (
                <DetailsCell
                  isAuditTrial={true}
                  isReview={true}
                  Wrapper={CellWrapper}
                  label={currentItemConfig?.label}
                  {...(currentItemConfig?.props ?? {})}
                  cellValue={changedItemValues}
                ></DetailsCell>
              ) : (
                ""
              )}
            </>
          );
        } else {
          let body = "-";
          if (currentItemConfig?.field) {
            body = get(item, currentItemConfig?.field) || "-";
            if (Array.isArray(body) && body?.length === 0) {
              body = "-";
            }
            if (
              currentItemConfig?.field ===
                DATA_MODEL_TABLE?.qualificationStatus?.key ||
              currentItemConfig?.field ===
                DATA_MODEL_TABLE?.controlledEquipmentStatus?.key
            ) {
              body =
                find(GXPemptyInstrumentsOptions[currentItemConfig?.field], {
                  key: item[currentItemConfig?.field]
                })?.value || "-";
            }

            if (
              currentItemConfig?.field === DATA_MODEL_TABLE.room.key ||
              currentItemConfig?.field === DATA_MODEL_TABLE.floor.key ||
              currentItemConfig?.field ===
                DATA_MODEL_TABLE.buildingLocation.key ||
              currentItemConfig?.field === "module"
            ) {
              body =
                item[currentItemConfig?.field] &&
                item[currentItemConfig?.field]?.value
                  ? item[currentItemConfig?.field]?.value
                  : "-";
            }

            if (
              currentItemConfig?.field ===
                DATA_MODEL_TABLE.dateOfNextMaintanance.key ||
              currentItemConfig?.field ===
                DATA_MODEL_TABLE.dateOfLastMaintanance.key ||
              currentItemConfig?.field ===
                DATA_MODEL_TABLE.dateOfNextPeriodicReview.key
            ) {
              body = item[currentItemConfig?.field]
                ? rearangeDisplayDate(item[currentItemConfig?.field])
                : "-";
            }
            if (
              currentItemConfig?.field ===
              DATA_MODEL_TABLE?.qualificationDocuments?.key
            ) {
              if (isEmpty(item[currentItemConfig?.field])) {
                body = "-";
              } else {
                body = getModifiedChangeString(
                  item[currentItemConfig?.field]?.value,
                  "name",
                  "documentId"
                );
              }
            }

            if (currentItemConfig?.field === DATA_MODEL_TABLE?.tags?.key) {
              if (isEmpty(item[currentItemConfig?.field])) {
                body = "-";
              } else {
                body = item[currentItemConfig?.field].join(",");
                currentItemConfig.props = {
                  fullRow: false
                };
              }
            }
            if (
              currentItemConfig?.field === DATA_MODEL_TABLE.installedTests.key
            ) {
              if (isEmpty(item[currentItemConfig?.field])) {
                body = "-";
              } else {
                body = getModifiedChangeString(
                  item[currentItemConfig?.field],
                  "version",
                  "name"
                );
              }
            }

            if (
              currentItemConfig?.field ===
                DATA_MODEL_TABLE.inventoryNumber.key ||
              currentItemConfig?.field ===
                DATA_MODEL_TABLE.maintenanceIntervalInDays.key
            ) {
              body = item[currentItemConfig?.field]
                ? item[currentItemConfig?.field].toString()
                : "-";
            }

            if (currentItemConfig?.component) {
              const Component = currentItemConfig.component;

              body = <Component item={item} />;
            }
          }

          return currentItemConfig !== undefined ? (
            <DetailsCell
              Wrapper={CellWrapper}
              isReview={true}
              isAuditTrial={true}
              label={currentItemConfig?.label}
              {...(currentItemConfig?.props ?? {})}
              cellValue={body}
            ></DetailsCell>
          ) : (
            <></>
          );
        }
      })}
    </Wrapper>
  );
};

const DetailsCells = ({
  isAuditTrial = false,
  item,
  infoMeta,
  Wrapper = DetailsCellsWrapperStyled,
  CellWrapper = DetailsCellStyled,
  relevantPositionState,
  auditAction,
  proposedChanges,
  proposedChangesValues
}) => {
  let changedKeys = Object.keys(item);
  let changedStatusKey = Object.keys(proposedChangesValues);
  let changeFieldConfig = infoMeta.fields;
  let displayCond = true;
  const linkedInstanceList = useSelector(
    (state) => state.instruments?.linkedInstanceList
  );
  if (auditAction === "CLUSTER_UPDATE") {
    displayCond = false;
    let allFieldKeys = Object.keys(emptyClusterInformation);
    let arr = [];
    for (let key of changedKeys) {
      arr.push(allFieldKeys.includes(key));
    }
    if (arr.includes(true)) {
      displayCond = true;
    }
  }
  if (auditAction === AUDIT_ACTIONS.APPROVE) {
    let changedStatusKeys = changedKeys;
    changedKeys = changedKeys.filter(
      (item) => item !== DATA_MODEL_TABLE?.status?.key
    );
    changedStatusKey = changedStatusKeys.filter(
      (item) => item === DATA_MODEL_TABLE?.status?.key
    );
  }

  const getChangedApprovedValues = (changedItemConfig, changes) => {
    if (changedItemConfig?.field === DATA_MODEL_TABLE?.status?.key) {
      return {
        oldvalue: EQUIPMENT_STATUS_FIELD[changes?.oldvalue],
        newvalue: EQUIPMENT_STATUS_FIELD[changes?.newvalue]
      };
    }

    return changes;
  };

  const getChangedValuesArray = (changedItemConfig, changes) => {
    if (
      changedItemConfig?.field === "dateOfNextMaintanance" ||
      changedItemConfig?.field === "dateOfLastMaintanance" ||
      changedItemConfig?.field === "dateOfNextPeriodicReview"
    ) {
      return {
        oldvalue: changes?.oldvalue
          ? rearangeDisplayDate(changes?.oldvalue)
          : null,
        newvalue: changes?.newvalue
          ? rearangeDisplayDate(changes?.newvalue)
          : null
      };
    }
    if (
      changedItemConfig?.field === "room" ||
      changedItemConfig?.field === "floor" ||
      changedItemConfig?.field === "buildingLocation" ||
      changedItemConfig?.field === "module"
    ) {
      if (changes?.value) {
        return changes?.value || null;
      } else {
        if (
          changes?.oldvalue?.value === changes?.newvalue?.value ||
          (!changes?.oldvalue?.value && !changes?.newvalue?.value)
        ) {
          return null;
        }
        return {
          oldvalue: changes?.oldvalue?.value,
          newvalue: changes?.newvalue?.value
        };
      }
    }
    if (changedItemConfig?.field === "linkedInstance") {
      const oldvalue = find(linkedInstanceList, {
        linkedInstance: changes?.oldvalue
      });
      const newvalue = find(linkedInstanceList, {
        linkedInstance: changes?.newvalue
      });
      return {
        oldvalue: oldvalue?.linkedInstanceDisplay,
        newvalue: newvalue?.linkedInstanceDisplay
      };
    }
    if (changedItemConfig?.field === "sop") {
      const sopChanges = getModifiedChanges(changes, "value");
      return sopChanges;
    }
    if (
      changedItemConfig?.field === DATA_MODEL_TABLE.qualificationDocuments.key
    ) {
      if (changes?.value) {
        const docChanges = getModifiedChanges(
          changes?.value,
          "name",
          "documentId"
        );
        return docChanges;
      }
    }

    if (changedItemConfig?.field === "qualificationStatus") {
      const oldValue = validateSelection(
        GXPemptyInstrumentsOptions.qualificationStatus,
        { key: changes?.oldvalue }
      );

      const newvalue = validateSelection(
        GXPemptyInstrumentsOptions.qualificationStatus,
        { key: changes?.newvalue }
      );
      return {
        oldvalue: oldValue,
        newvalue: newvalue
      };
    }
    if (changedItemConfig?.field === "installedTests") {
      const testChanges = getModifiedChanges(changes, "version", "name");
      return testChanges;
    }

    if (
      changedItemConfig?.field === "inventoryNumber" ||
      changedItemConfig?.field === "maintenanceIntervalInDays"
    ) {
      return {
        oldvalue: changes?.oldvalue?.toString(),
        newvalue: changes?.newvalue?.toString()
      };
    }
    if (changedItemConfig?.field === DATA_MODEL_TABLE?.status?.key) {
      return {
        oldvalue: EQUIPMENT_STATUS_FIELD[changes?.oldvalue],
        newvalue: EQUIPMENT_STATUS_FIELD[changes?.newvalue]
      };
    }

    return changes;
  };

  return !isAuditTrial ? (
    <Wrapper data-testid="details-cells-wrapper">
      {infoMeta.fields.map((field) => {
        let body = get(item, field.field) || "-";
        if (Array.isArray(body) && body.length === 0) {
          body = "-";
        }
        if (field.component) {
          const Component = field?.component;

          body = <Component item={item} />;
        }

        return (
          <DetailsCell
            Wrapper={CellWrapper}
            label={field?.label}
            {...(field?.props ?? {})}
            cellValue={body}
          ></DetailsCell>
        );
      })}
    </Wrapper>
  ) : (
    <WrapperStyled>
      {displayCond && proposedChanges && (
        <>
          <Wrapper data-testid="audit-trail-details-cells-wrapper">
            {changedStatusKey.map((fieldId) => {
              const changedItemConfig = find(changeFieldConfig, {
                field: fieldId
              });
              const changedItemValues = getChangedValuesArray(
                changedItemConfig,
                proposedChangesValues[fieldId]
              );
              return (
                <>
                  {changedItemConfig &&
                  fieldId === DATA_MODEL_TABLE?.status?.key ? (
                    <DetailsCell
                      isAuditTrial={isAuditTrial}
                      Wrapper={CellWrapper}
                      label={changedItemConfig?.label}
                      {...(changedItemConfig?.props ?? {})}
                      cellValue={changedItemValues}
                    ></DetailsCell>
                  ) : (
                    ""
                  )}
                </>
              );
            })}
          </Wrapper>
          <AuditTrialProposedTitle>Proposed changes</AuditTrialProposedTitle>
        </>
      )}
      {displayCond && auditAction === AUDIT_ACTIONS.APPROVE && (
        <Wrapper data-testid="audit-trail-details-cells-wrapper">
          {changedStatusKey.map((fieldId) => {
            const changedItemConfig = find(changeFieldConfig, {
              field: fieldId
            });
            const changedItemValues = getChangedApprovedValues(
              changedItemConfig,
              item[fieldId]
            );
            return (
              <>
                {changedItemConfig &&
                fieldId === DATA_MODEL_TABLE?.status?.key ? (
                  <DetailsCell
                    isAuditTrial={isAuditTrial}
                    Wrapper={CellWrapper}
                    label={changedItemConfig?.label}
                    {...(changedItemConfig?.props ?? {})}
                    cellValue={changedItemValues}
                  ></DetailsCell>
                ) : (
                  ""
                )}
              </>
            );
          })}
        </Wrapper>
      )}
      {displayCond && (
        <Wrapper data-testid="audit-trail-details-cells-wrapper">
          {changedKeys.map((fieldId) => {
            const changedItemConfig = find(changeFieldConfig, {
              field: fieldId
            });
            const changedItemValues = getChangedValuesArray(
              changedItemConfig,
              item[fieldId]
            );

            if (fieldId === DATA_MODEL_TABLE.tags.key) {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gridColumn: "1 / -1"
                  }}
                >
                  <>
                    <div
                      data-testid="details-cell-label"
                      id={DATA_MODEL_TABLE.tags.key}
                    >
                      {DATA_MODEL_TABLE.tags.value}
                    </div>
                    <OwcTooltip anchor={DATA_MODEL_TABLE.tags.key}>
                      {DATA_MODEL_TABLE.tags.value}
                    </OwcTooltip>
                  </>
                  <div
                    style={{
                      fontSize: "14px",
                      lineHeight: "18px",
                      fontWeight: "500",
                      color: "#000000"
                    }}
                  >
                    <TagLogComponent item={item[fieldId]} />
                  </div>
                </div>
              );
            }

            return (
              <>
                {changedItemConfig && changedItemValues ? (
                  <DetailsCell
                    isAuditTrial={isAuditTrial}
                    Wrapper={CellWrapper}
                    label={changedItemConfig?.label}
                    {...(changedItemConfig?.props ?? {})}
                    cellValue={changedItemValues}
                  ></DetailsCell>
                ) : (
                  ""
                )}
              </>
            );
          })}
        </Wrapper>
      )}
      <SubEqCellStyled>
        {item.hasOwnProperty("subEquipment") &&
          item?.subEquipment.length > 0 && (
            <SubClusterCell
              items={item?.subEquipment}
              relevantPositionState={relevantPositionState}
            />
          )}
        {item.hasOwnProperty("leadingClusterSoftware") &&
          Object.keys(item?.leadingClusterSoftware).length > 0 && (
            <SubClusterCell
              items={[item?.leadingClusterSoftware]}
              relevantPositionState={relevantPositionState}
              prefixString={"Leading cluster software"}
            />
          )}
      </SubEqCellStyled>
    </WrapperStyled>
  );
};
export default DetailsCells;

const concatChanges = (changes, primaryKey, secondaryKey) => {
  if (changes) {
    const primaryValue = changes[primaryKey] ? `${changes[primaryKey]}` : "";
    const secondaryValue = changes[secondaryKey]
      ? `, ${changes[secondaryKey]}`
      : "";
    return `${primaryValue}${secondaryValue}`;
  } else {
    return "";
  }
};

const getModifiedChanges = (changes, primaryKey, secondaryKey) => {
  return changes.map((item) => {
    const itemWithPrimaryKey = item[primaryKey];
    if (itemWithPrimaryKey) {
      if (primaryKey === "version") {
        return {
          oldvalue: `${itemWithPrimaryKey[secondaryKey]}, ${itemWithPrimaryKey?.oldvalue}`,
          newvalue: `${itemWithPrimaryKey[secondaryKey]}, ${itemWithPrimaryKey?.newvalue}`
        };
      } else {
        return {
          oldvalue: `${itemWithPrimaryKey?.oldvalue}, ${itemWithPrimaryKey[secondaryKey]}`,
          newvalue: `${itemWithPrimaryKey?.newvalue}, ${itemWithPrimaryKey[secondaryKey]}`
        };
      }
    }
    if (primaryKey === "version") {
      return {
        oldvalue: concatChanges(item?.oldvalue, secondaryKey, primaryKey),
        newvalue: concatChanges(item?.newvalue, secondaryKey, primaryKey)
      };
    } else {
      return {
        oldvalue: concatChanges(item?.oldvalue, primaryKey, secondaryKey),
        newvalue: concatChanges(item?.newvalue, primaryKey, secondaryKey)
      };
    }
  });
};

const getModifiedChangeString = (data, firstKey, secondKey) => {
  const resData = data.map((item) => {
    return { value: `${item[firstKey]},${item[secondKey]}` };
  });

  return resData;
};
const TagLogComponent = ({ item = [] }) => {
  const html = item.map((x) =>
    x?.type === "Created" ? (
      <span>{x?.newvalue}</span>
    ) : (
      x?.oldvalue &&
      x?.oldvalue !== "null" && (
        <>
          <span style={{ textDecoration: "line-through" }} id={x?.oldvalue}>
            {x?.oldvalue}
          </span>
          <OwcTooltip anchor={x?.oldvalue}>{x?.oldvalue}</OwcTooltip>
        </>
      )
    )
  );
  return <>{html.map((x, index) => (index === 0 ? x : <>, {x}</>))}</>;
};
