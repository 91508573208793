import styled from "styled-components";
import { NormalFont } from "../../../components/shared/NormalFont";
import {
  AUDIT_ACTIONS,
  AUDIT_ACTION_BY,
  AUDIT_TRAIL_LIMIT
} from "../../../constants";
import { AuditItemContentCellsInfoArray } from "./AuditItemCellsConfig";
import AuditItemSingleFrame from "./AuditItemSingleFrame";
import { utcStringToLocaleString } from "../../instruments/helpers";

const HistoryItemHeader = styled.div`
  &:not(:first-child) {
    margin-top: 16px;
  }
  margin-top: 16px;
  margin-bottom: 12px;
`;

const HistoryItemTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  line-height: 23px;
  font-weight: 500;
  color: var(--one-color-gray-800) 3;
`;

const AuditTrialReasonTitle = styled.span`
  font-size: 14px;
  line-height: 23px;
  font-weight: 500;
  color: #000000;
`;

const AuditTrialReasonValue = styled.span`
  font-size: 14px;
  line-height: 23px;
  font-weight: 500;
  color: var(--one-color-gray-800);
`;

const AuditAdminText = styled.p`
  text-align: center;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: var(--one-color-background-brand-1);
`;

const AuditItemContent = ({
  canEdit,
  changeItem,
  dataTestId,
  handleClose,
  hasPermission,
  userLists
}) => {
  return (
    <div data-testid={`${dataTestId}-content`} style={{ position: "relative" }}>
      {changeItem?.map((log, index) => {
        const newData = JSON.parse(log?.newData);
        const relevantPositionState = newData?.trackPositionInCluster;
        let reviewers = [];
        log?.proposedChanges?.reviewers.forEach((item) => {
          reviewers.push(userLists.find((x) => x.userId === item));
        });
        if (index > AUDIT_TRAIL_LIMIT - 1) return "";
        return (
          <>
            <HistoryItemHeader
              style={{
                marginBottom:
                  log?.action !== AUDIT_ACTIONS.MODIFY &&
                  log?.action !== AUDIT_ACTIONS.CLUSTER_CREATE &&
                  log?.action !== AUDIT_ACTIONS.CLUSTER_DELETE &&
                  log?.action !== AUDIT_ACTIONS.ADD_TO_CLUSTER &&
                  log?.action !== AUDIT_ACTIONS.REMOVE_FROM_CLUSTER &&
                  log?.action !== AUDIT_ACTIONS.APPROVE &&
                  log?.action !== AUDIT_ACTIONS.REJECT &&
                  log?.action !== AUDIT_ACTIONS.REVERT
                    ? "48px"
                    : "12px"
              }}
            >
              <HistoryItemTitle
                data-testid={`${dataTestId}-content-header-${index}`}
              >
                <div>
                  {utcStringToLocaleString(log?.modifiedDate, 24)}
                  {log?.modifiedByUserId || log?.modifiedByEmail ? (
                    <NormalFont>
                      {" "}
                      ({AUDIT_ACTION_BY[log.action]}{" "}
                      {log?.modifiedByUserId || log?.modifiedByEmail})
                    </NormalFont>
                  ) : (
                    ""
                  )}
                </div>
              </HistoryItemTitle>
              <div>
                {log?.editReason ? (
                  <>
                    {log?.proposedChanges ? (
                      <div style={{ display: "flex" }}>
                        <div style={{ flex: "40%" }}>
                          <AuditTrialReasonTitle>
                            Reason:{" "}
                          </AuditTrialReasonTitle>
                          <AuditTrialReasonValue>
                            {log?.editReason}
                          </AuditTrialReasonValue>
                        </div>
                        <div style={{ flex: "60%" }}>
                          <AuditTrialReasonTitle>
                            Reviewer(s):{" "}
                          </AuditTrialReasonTitle>
                          <AuditTrialReasonValue>
                            {reviewers
                              .map((x) => (x.name ? x.name : x.email))
                              .join(", ")}
                          </AuditTrialReasonValue>
                        </div>
                      </div>
                    ) : log?.action === AUDIT_ACTIONS.APPROVE ? (
                      ""
                    ) : (
                      <div>
                        <AuditTrialReasonTitle>Reason: </AuditTrialReasonTitle>
                        <AuditTrialReasonValue>
                          {log?.editReason}
                        </AuditTrialReasonValue>
                      </div>
                    )}
                    {log?.action === AUDIT_ACTIONS.APPROVE ? (
                      ""
                    ) : (
                      <div>
                        <AuditTrialReasonTitle>Comment: </AuditTrialReasonTitle>
                        <AuditTrialReasonValue>
                          {log?.editComment !== "" ? log?.editComment : "-"}
                        </AuditTrialReasonValue>
                      </div>
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
            </HistoryItemHeader>
            {log?.changes && (
              <AuditItemSingleFrame
                isAuditTrial={true}
                auditAction={log?.action}
                history={
                  log?.proposedChanges
                    ? JSON.parse(log?.proposedChanges?.changes)
                    : JSON.parse(log?.changes)
                }
                newData={JSON.parse(log?.newData)}
                CellsInfoArray={AuditItemContentCellsInfoArray}
                relevantPositionState={relevantPositionState}
                proposedChanges={log?.proposedChanges ? true : false}
                proposedChangesValues={
                  log?.proposedChanges ? JSON.parse(log?.changes) : []
                }
              />
            )}

            {index === AUDIT_TRAIL_LIMIT - 1 && (
              <AuditAdminText>
                For more records please contact Admin.
              </AuditAdminText>
            )}
          </>
        );
      })}
    </div>
  );
};

export default AuditItemContent;
